@import "app/index.scss";

.order-wrap {
  margin-bottom: rem-calc(30px);
  border: 1px solid lighten(map-get($colors, grey), 42);
  .order-no {
    @include font(false, 13px, 15px, regular);
  }
  .placed-on {
    padding-top: rem-calc(3px);
    @include font(false, 13px, 15px, regular);
  }
  .price {
    @include font(false, 14px, 20px, regular);
    strong {
      @include font(false, 14px, 20px, semi_bold);
    }
  }
  .package {
    @include font(false, 14px, 20px, medium);
  }
  .sold-by {
    @include font(false, 11px, 20px, medium);
    a {
      text-decoration: underline;
    }
    &:hover a {
      text-decoration: none;
    }
  }
  .delivered-on {
    @include font(false, 13px, 20px, false);
  }
}

.timeline-content {
  width: 85%;
  margin: 0 auto;
  padding: rem-calc(10px 30px);
  background-color: map-get($colors, site_bg);
  border: 1px solid lighten(map-get($colors, grey), 35);
  .package-info {
    @include font(primary, 13px, 21px, regular);

    .package-time {
      color: map-get($colors, silver_tree);
    }
    .package-text {
      width: calc(100% - 150px);
    }
  }
}

.order-product-box {
  padding: rem-calc(20px 0 0);
}
.package-wrap {
  padding: rem-calc(20px);
  margin-bottom: rem-calc(20px);
  background-color: #fff;
  border-top: 1px solid lighten(map-get($colors, grey), 35);
}
.product-wrap {
  border-top: 1px solid lighten(map-get($colors, grey), 35);
  padding: rem-calc(8px 0);
  &:last-child {
    padding-bottom: 3px;
  }
  .column {
    padding: rem-calc(0 5px);
  }

  .product {
    width: 45%;
    .v-image {
      border: 1px solid lighten(map-get($colors, grey), 35);
    }
    .product-box {
      width: calc(100% - 90px);
      padding-left: rem-calc(8px);
    }
    h3 {
      @include font(primary, 15px, 20px, regular);
      @include truncate-clamp(3);
    }
    .selected-attIn {
      @include font(primary, 14px, 20px, regular);

      .selected-att {
        font-size: 13px;
        &:not(:last-child) {
          strong {
            position: relative;
            &::after {
              content: ", ";
              font-weight: normal;
            }
          }
        }
      }
    }
  }
  .quantity {
    width: 20%;
    @include font(primary, 15px, 20px, regular);
  }
  .price {
    width: 15%;
    text-align: center;
    @include font(primary, 15px, 20px, regular);
  }
  .review-modal-btn {
    width: 20%;
    text-align: right;

    span {
      cursor: pointer;
      @include font(primary, 15px, 20px, regular);
      &.write {
        text-decoration: underline;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@include media(1639px) {
}

@include media(lg) {
}

@include media(sm) {
  .product-wrap {
    .product {
      width: 100%;
      padding-bottom: rem-calc(10px);

      h3 {
        @include font(primary, 14px, 20px, regular);
      }
    }
    .quantity {
      width: 50%;
    }
    .price {
      width: 50%;
      text-align: right;
    }
  }

  .timeline-content {
    width: 90%;
    padding: rem-calc(10px);
    .package-info {
      flex-wrap: wrap;

      .package-text {
        width: calc(100%);
      }
    }
  }
}
