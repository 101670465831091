@import "app/index.scss";

.login-register-bg {
    padding: rem-calc(120px 0);
    h3 {
        padding-bottom: rem-calc(20px);
        text-align: center;
        @include font(primary, 32px, 40px, medium);
    }
    .login-register-btm {
        padding-top: rem-calc(20px);
        text-align: center;
        @include font(primary, 16px, 26px, false);

        a {
            @include font(brand, 16px, 26px, medium);
        }
    }
}
.signin-signup-wrap {
    max-width: 605px;
    padding: rem-calc(40px);

    .v-card__title {
        padding: rem-calc(0);
        @include font(primary, 28px, 36px, medium);
    }
    .v-card__subtitle {
        padding: rem-calc(0);
        margin: rem-calc(0);
        text-align: center;
        @include font(primary, 16px, 22px, false);
    }
    .otp-message {
        text-align: center;
        @include font(grey, 14px, 20px, light);
    }

    .signin-signup-form {
        padding: rem-calc(23px 0 0);

        .v-input--selection-controls {
            padding: 0;
            margin: 0;
        }
        a {
            text-decoration: underline;
            @include font(grey, 16px, 22px, medium);
            &:hover {
                text-decoration: none;
                color: map-get($colors, brand);
            }
        }
        .signin-signup-btn {
            height: 46px;
            @include font(false, 15px, 22px, false);
        }
        .input-wrap {
            padding-bottom: rem-calc(18px);
        }
    }

    .login-form {
        padding-top: rem-calc(20px);
        .input-wrap {
            padding: rem-calc(0 0 20px);

            &::v-deep .v-input__prepend-inner {
                height: 44px;
                margin-right: rem-calc(15px);
                padding-right: rem-calc(10px);
                border-right: 1px solid #ddd;
                display: flex;
                align-items: center;
            }
        }
        .remember {
            margin-top: rem-calc(-4px);
            padding-bottom: rem-calc(44px);
        }
    }

    .signup-form {
        .input-wrap {
            padding: rem-calc(0 0 20px);

            &::v-deep .v-input__prepend-inner {
                height: 44px;
                margin-right: rem-calc(15px);
                padding-right: rem-calc(10px);
                border-right: 1px solid #ddd;
                display: flex;
                align-items: center;
            }
            .use-as {
                padding-top: rem-calc(5px);
                @include font(false, 12px, 13px, light);
            }
        }
        .agree {
            padding: rem-calc(0 0 20px);
        }
    }

    .or-section {
        padding-top: rem-calc(14px);
        .or-text {
            text-align: center;
            position: relative;
            @include font(grey, 15px, 15px, semi_bold);

            &:before {
                @include sudo(calc(50% - 35px), 1px, left, 0, top, 50%, false);
                background-color: lighten(map-get($colors, grey), 35);
            }
            &:after {
                @include sudo(calc(50% - 35px), 1px, right, 0, top, 50%, false);
                background-color: lighten(map-get($colors, grey), 35);
            }
        }
        .sign-with {
            padding-bottom: rem-calc(24px);
            @include font(false, 14px, 18px, false);
        }

        .sign-with-btns {
            padding-bottom: rem-calc(22px);
            .v-btn {
                height: 48px;
                width: 48%;
                @include font(light, 14px, 22px, false);
                &.facebook {
                    background-color: #3b5999;
                }
                &.gmail {
                    background-color: #e1e2e1;
                    color: map-get($colors, primary);
                    .v-icon {
                        color: #ef2d00;
                    }
                }
            }
        }
    }

    .account-text {
        padding-top: rem-calc(26px);
        @include font(false, 14px, 18px, false);

        a {
            @include font(primary, 14px, 18px, bold);
            text-decoration: underline;
            &:hover {
                text-decoration: none;
                color: map-get($colors, brand);
            }
        }
    }

    .input-wrap {
        .v-input {
            &::v-deep input {
                height: 46px;
                max-height: 46px;
            }
        }
    }
}

/*=====================
    forgot password
======================*/
.forgot-image {
    padding-top: rem-calc(45px);
    margin-bottom: rem-calc(50px);
    border-bottom: 2px solid lighten(map-get($colors, grey), 45);
}
.forgot-text {
    padding-bottom: rem-calc(35px);
    text-align: center;

    h3 {
        padding-bottom: rem-calc(10px);
        @include font(primary, 32px, 40px, medium);
    }
    p {
        padding: rem-calc(0);
        @include font(primary, 15px, 28px, false);
    }
}

/*=========================
    create new password
==========================*/
.new-password-image {
    padding: rem-calc(30px 0 25px);
}

/*=========================
         Responsive
==========================*/

@include media(md) {
    .login-register-bg {
        padding: rem-calc(50px 0);
        h3 {
            padding: rem-calc(0 20px 20px);
            @include font(false, 25px, 30px, false);
        }
        .login-register-btm {
            padding: rem-calc(20px 20px 0);
            br {
                display: none;
            }
        }
    }
    .signin-signup-wrap {
        max-width: 500px;
        padding: rem-calc(20px);

        .v-card__title {
            padding: rem-calc(0 0 10px);
            @include font(primary, 24px, 32px, medium);
        }
    }
}

@include media(sm) {
    .signin-signup-wrap {
        max-width: 90%;
        padding: rem-calc(20px);
        .or-section {
            .sign-with-btns {
                .v-btn {
                    height: 38px;
                }
            }
        }

        .signin-signup-form {
            a {
                @include font(grey, 14px, 22px, medium);
            }
        }
    }
}
