@import "app/index.scss";

.account-wrap {
  width: calc(100% - 375px);
  padding-left: rem-calc(30px);
}
.account-panel {
  padding: rem-calc(30px 15px);
}
.profile-image-wrapper {
  padding: rem-calc(15px 0);
}
.profile-image {
  width: 308px;
  .v-image {
    border: 1px solid lighten(map-get($colors, grey), 37);
  }
}
.summary-wrapper {
  width: 100%;
  padding-bottom: rem-calc(15px);

  h2 {
    padding-bottom: rem-calc(10px);

    margin-bottom: rem-calc(15px);
    @include font(primary, 18px, 24px, medium);
    border-bottom: 1px solid lighten(map-get($colors, grey), 37);
  }
  .summary-wrapper-container {
    padding-bottom: rem-calc(5px);
    margin: 0;
    h3 {
      width: 100px;
      position: relative;
      text-align: right;
      @include font(primary, 14px, 28px, regular);
    }
    .summary-wrapper-in {
      padding-bottom: rem-calc(10px);
      margin-bottom: rem-calc(10px);
      width: calc(100% - 100px);
      border-bottom: 1px solid lighten(map-get($colors, grey), 40);

      .summary-box {
        padding: rem-calc(3px 8px);
        margin: rem-calc(0 0 3px 3px);
        border: 1px solid #ddd;
        color: #000;

        &::v-deep span {
          @include font(false, 13px, 20px, light);
        }
        &:hover {
          box-shadow: 0 2px 16px #d2d7db;
        }
        &.active {
          color: map-get($colors, primary);
          border-color: map-get($colors, brand);
          background-color: lighten(map-get($colors, brand), 46);
        }
      }
    }
  }
}
.product-summery-wrap {
  width: 100%;
  &::after {
    content: "";
    flex: auto;
  }
  .summery-box {
    width: calc(1 / 5 * 100% - (1 - 1 / 5) * 10px);
    padding: rem-calc(13px);
    margin: 10px 10px 0 0;
    &:nth-child(5n) {
      margin-right: 0;
    }

    h4 {
      padding-bottom: rem-calc(4px);
      @include font(primary, 13px, 18px, regular);
    }
    h2 {
      @include font(primary, 20px, 24px, semi_bold);
    }
    .total-status {
      @include font(grey, 12px, 18px, false);

      strong {
        @include font(false, 12px, 18px, semi_bold);
      }
    }
    &:hover {
      box-shadow: 0 2px 16px #d2d7db;
    }
  }
}
.profile-information {
  .profile-information-box {
    padding-bottom: rem-calc(40px);

    h2 {
      padding-top: rem-calc(10px);
      margin-bottom: rem-calc(10px);
      @include font(primary, 22px, 25px, medium);
      border-top: 1px solid lighten(map-get($colors, grey), 30);

      a {
        @include font(brand, 15px, 25px, regular);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }

    ul {
      padding-bottom: rem-calc(10px);
      li {
        padding: rem-calc(5px 0);
        display: flex;

        @include font(primary, 15px, 22px, medium);
        span {
          &:first-child {
            width: 190px;
            flex-shrink: 0;
            position: relative;
            &:after {
              content: ":";
              position: absolute;
              right: 4px;
              top: 0;
            }
          }
        }

        .profile-info {
          color: map-get($colors, grey);
          font-weight: map-get($font-weight, regular);

          .verified {
            @include font(false, 13px, 22px, regular);
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
        .light-gallery {
          a {
            padding: 5px 5px 5px 0;
            width: 70px;
            height: 70px;
            display: block;
            position: relative;

            .v-icon {
              z-index: 1;
              @include absolute(false, false, right, 0, top, 0);
            }

            img {
              width: 100%;
              height: 100%;
              padding: 5px;
              border: 1px solid #ddd;
            }
          }
        }
      }
    }
    .change-password {
      text-decoration: underline;
      @include font(brand, 15px, 22px, false);

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@include media(1639px) {
  .account-wrap {
    width: calc(100% - 275px);
    padding-left: rem-calc(30px);
  }
  .profile-image {
    width: 250px;
  }
}
@include media(lg) {
  .account-wrap {
    width: 100%;
    padding-left: 0;
  }
}
@include media(767px) {
  .profile-image {
    width: 170px;
  }
}

@include media(sm) {
  .profile-image {
    width: 400px;
    margin: 0 auto;
  }
  .profile-information {
    width: 100%;
    padding: rem-calc(30px 0 0);
  }

  .summary-wrapper {
    .summary-wrapper-container {
      padding-bottom: rem-calc(15px);
      h3 {
        width: 100%;
        text-align: left;
        @include font(false, 15px, 22px, medium);
      }
      .summary-wrapper-in {
        width: 100%;
        .summary-box {
          span {
            @include font(false, 14px, 16px, regular);
          }
        }
      }
    }
  }
}

@include media(479px) {
  .profile-information {
    .profile-information-box {
      ul {
        li {
          flex-wrap: wrap;
          span {
            &:first-child {
              width: 100%;
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
