@import "app/index.scss";
.success-fail {
    padding: rem-calc(30px 40px);
    width: 580px;

    h3 {
        padding: rem-calc(30px 0 25px);
        @include font(false, 30px, 40px, medium);
    }
    ul {
        li {
            padding: rem-calc(10px 0);
            border-bottom: 1px solid #ddd;

            &.amount {
                span {
                    @include font(false, 16px, 20px, semi_bold);
                }
            }
        }
    }
    .payment-title {
        @include font(grey, 14px, 20px, regular);
    }
    .payment-info {
        @include font(primary, 14px, 20px, medium);
    }
}

@include media(sm) {
    .success-fail {
        width: 100%;
    }
}

@include media(479px) {
    .success-fail {
        padding: rem-calc(20);
        h3 {
            padding: rem-calc(20px 0 25px);
        }
        .payment-info {
            max-width: 300px;
            padding-left: rem-calc(5px);
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
