@import "app/index.scss";

.cart-summary {
  border: 1px solid rgba(0, 0, 0, 0.12);
  h2 {
    padding: rem-calc(8px 20px);
    margin-bottom: 5px;
    cursor: pointer;
    @include font(false, 16px, 22px, medium);
    background-color: #f5f5f5;
  }
  
  .ship-info {
    padding: rem-calc(7px 20px 20px);
    &.close-shipping-adderss{display: none}
    &.closed{display: none}
    .shiping-title {
      @include font(primary, 16px, 25px, semi_bold);

      .change-address {
        .change-address-text {
          padding: rem-calc(5px);
          text-decoration: underline;
          background-color: #ededed;
          @include font(primary, 13px, 18px, semi_bold);
          &:hover {
            text-decoration: none;
            color: map-get($colors, brand);
          }
        }
      }
    }
    .address {
      address {
        padding: rem-calc(0px 0 10px);
        font-style: normal;
        span {
          padding: rem-calc(0 0 3px);
          display: block;
          @include font(primary, 14px, 15px, regular);
        }
        strong {
          font-weight: map-get($font-weight, regular);
        }
        .collect-point {
          padding: rem-calc(10px 0 4px);
          @include font(light, 15px, 20px, regular);
          i {
            padding: rem-calc(4px 8px);
            background-color: #4caf50;
            @include border-radius(4px);
            border-bottom-right-radius: 0;
          }
        }
        .change-pickup{
          @include font(brand, 16px, 20px, false);
          small{
            text-decoration: underline;
            &:hover{ text-decoration: none;}
          }
        }
      }
    }
    .add-address {
      padding: rem-calc(5px 10px);
      @include font(primary, 14px, 20px, regular);
      background-color: lighten(map-get($colors, grey), 42);
      &:hover {
        background-color: lighten(map-get($colors, grey), 30);
      }
    }
  }
  .cart-summary-box {
    padding: rem-calc(10px 20px 15px);
    .coupon-code {
      padding: 0;
      width: 55%;
    }
    .coupon-code-ntn {
      width: 40%;
      .v-btn {
        width: 100%;
        height: 43px;
      }
    }
  }
  .cart-info {
    height: 25px;
    align-items: center;
    display: flex;
   // border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    strong {
      width: 50%;
      @include font(primary, 14px, 22px, medium);
    }
    span {
      width: 50%;
      text-align: right;
      @include font(primary, 13px, 22px, medium);

      &.grand-total {
        @include font(false, 18px, 22px, semi_bold);
      }
    }
  }
  .delivery-option {
    padding: rem-calc(20px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    .v-radio {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      .option-item {
        span {
          @include font(grey, 15px, 24px, false);
        }
        strong {
          @include font(primary, 15px, 24px, medium);
        }
      }
    }
  }
}
.v-list-item__title a {
  color: map-get($colors, primary);
}
.continue-payment {
  height: 50px !important;
  padding: rem-calc(0 30px) !important;
  @include font(light, 16px, 22px, false);
  background-color: map-get($colors, brand) !important;
  &:hover {
    color: map-get($colors, light);
    background-color: map-get($colors, primary) !important;
  }
}
.pick-up {
  cursor: pointer;
  padding: rem-calc(5px 15px);
  border-radius: 5px;
  background-color: lighten(map-get($colors, grey), 45);
  span {
    @include font(primary, 15px, 20px, false);
  }
  a {
    @include font(brand, 15px, 22px, false);
  }
  &:hover {
    background-color: lighten(map-get($colors, grey), 40);
  }
}
@include media(720px) {
  .continue-btn-holder{
    width: 100%;
    padding: 5px 10px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }
}
@include media(479px) {
  .btn-holder {
    flex-wrap: wrap;
  }

  .cart-summary {
    .cart-summary-box {
      padding: rem-calc(10px);
      .coupon-code {
        width: 100%;
        padding-bottom: rem-calc(15px);
      }
      .coupon-code-ntn {
        width: 100%;
      }
    }
  }
}
