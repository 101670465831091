@import "app/index.scss";

.account-wrap {
  width: calc(100% - 375px);
  padding-left: rem-calc(30px);
}
.account-panel {
  padding: rem-calc(30px 15px);
}
.account-panel-top {
  padding-bottom: rem-calc(15px);
  h2 {
    padding-right: rem-calc(10px);
    @include font(primary, 25px, 35px, medium);

    span {
      @include font(grey, 15px, 22px, regular);
    }
  }
}
.filter-bar {
  padding-bottom: rem-calc(15px);
  .input-wrap {
    width: 22%;
    padding: rem-calc(0 15px 10px 0);
  }
}
.brand-box {
  display: block;
  border: 1px solid lighten(map-get($colors, grey), 37);

  .brand-banner {
    img {
      width: 100%;
    }
  }
  .brand-infos {
    h4 {
      @include font(primary, 17px, 22px, medium);
    }
    img {
      border: 1px solid lighten(map-get($colors, grey), 37);
    }
  }
  &:hover {
    box-shadow: 0 3px 5px lighten(map-get($colors, grey), 37);
  }
  .b-hover {
    &:hover {
      background-color: map-get($colors, brand) !important;
    }
  }
  .own-brand {
    @include font(silver_tree, 12px, 20px, medium);
  }
  .featured {
    span {
      display: inline-block;
      @include font(primary, 14px, 20px, medium);
    }
  }
}

@include media(1639px) {
  .account-wrap {
    width: calc(100% - 275px);
    padding-left: rem-calc(30px);
  }
}

@include media(lg) {
  .account-wrap {
    width: 100%;
    padding-left: 0;
  }
  .filter-bar {
    .input-wrap {
      width: 33.33%;
    }
    .v-btn {
      width: 200px;
    }
  }
}

@include media(md) {
  .filter-bar {
    .input-wrap {
      width: 50%;
    }
  }
}
@include media(sm) {
  .account-panel {
    padding: rem-calc(15px 10px);
  }
}

@include media(479px) {
  .filter-bar {
    .input-wrap {
      padding-right: 0;
      width: 100%;
    }
  }
}
