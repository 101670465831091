@import "app/index.scss";

.tab-panel {
    padding: rem-calc(60px 0);
    .tab-left {
        width: 71.7%;
    }
    .tab-right {
        width: 28.3%;
        padding-left: rem-calc(75px);

        .tab-right-box {
            padding: rem-calc(0 0 37px);
            h2 {
                padding: rem-calc(0 0 20px);
                @include font(primary, 26px, 34px, medium);
            }
            .faq-btn {
                width: 100%;
                height: 55px;
                @include font(primary, 17px, 24px, medium);
            }
        }
        .faq-contact {
            padding: rem-calc(28px 0 22px);
            background-color: lighten(map-get($colors, grey), 45);
            .faq-contact-title {
                padding: rem-calc(0 45px 22px);
                margin-bottom: rem-calc(22px);
                border-bottom: 1px solid lighten(map-get($colors, grey), 37);

                h3 {
                    padding-left: rem-calc(22px);
                    @include font(primary, 20px, 36px, medium);
                }
            }

            span {
                padding: rem-calc(0 10px 13px 45px);
                display: block;
                @include font(primary, 15px, 22px, false);
            }
        }
    }
    &::v-deep .v-tabs-bar {
        width: 330px;
        margin-right: 75px;
    }
    .v-tab {
        justify-content: start;
        color: map-get($colors, primary) !important;
        @include font(false, 15px, 22px, regular);
        &.v-tab--active {
            color: map-get($colors, brand) !important;
        }
    }
    .v-tab:after {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -1px;
        background-color: #ddd;
    }
    &::v-deep .v-tabs-slider {
        display: none;
    }
    &::v-deep .v-expansion-panel::before {
        box-shadow: none;
    }
    .v-expansion-panel-header {
        padding: rem-calc(24px 0);
        @include font(primary, 19px, 22px, medium);
    }

    .v-expansion-panel-content {
        p {
            @include font(primary, 15px, 28px, regular);
        }
    }
    .v-expansion-panel-content__wrap {
        padding: rem-calc(30px 15px 28px);
        border-top: 1px solid #ddd;
    }
}

@include media(1639px) {
    .tab-panel {
        .tab-left {
            width: 73%;
        }
        .tab-right {
            width: 27%;
            padding-left: rem-calc(30px);

            .tab-right-box {
                padding: rem-calc(0 0 30px);
                h2 {
                    padding: rem-calc(0 0 20px);
                    @include font(primary, 20px, 26px, medium);
                }
                .faq-btn {
                    height: 45px;
                }
            }
            .faq-contact {
                padding: rem-calc(25px 0);
                .faq-contact-title {
                    padding: rem-calc(0 15px 15px);

                    h3 {
                        padding-left: rem-calc(15px);
                    }
                }

                span {
                    padding: rem-calc(0 10px 10px 15px);
                }
            }
        }
        &::v-deep .v-tabs-bar {
            width: 250px;
            margin-right: 30px;
        }
    }
}

@include media(lg) {
    .tab-panel {
        .tab-left {
            width: 66%;
        }
        .tab-right {
            width: 34%;
            padding-left: rem-calc(30px);

            .tab-right-box {
                padding: rem-calc(0 0 30px);
                h2 {
                    padding: rem-calc(0 0 20px);
                    @include font(primary, 20px, 26px, medium);
                }
                .faq-btn {
                    height: 45px;
                }
            }
            .faq-contact {
                padding: rem-calc(25px 0);
                .faq-contact-title {
                    padding: rem-calc(0 15px 15px);

                    h3 {
                        padding-left: rem-calc(15px);
                    }
                }

                span {
                    padding: rem-calc(0 10px 10px 15px);
                }
            }
        }
        &::v-deep .v-tabs-bar {
            width: 100%;
            margin-right: 0;
        }

        .v-tab {
            @include font(false, 20px, 26px, medium);
        }
    }
}

@include media(md) {
    .tab-panel {
        .tab-left {
            width: 100%;
        }
        .tab-right {
            width: 100%;
            padding: rem-calc(20px 0 0);
            margin-top: rem-calc(40px);
            border-top: 2px solid map-get($colors, primary);
        }
        .v-expansion-panel-header {
            padding: rem-calc(20px 0);
            @include font(false, 16px, 20px, false);
        }
    }
}
