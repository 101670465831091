@import "app/index.scss";
.bank-wrap {
    padding: rem-calc(10px 15px);
    margin-top: rem-calc(30px);
    box-shadow: 2px 0 25px rgba(198, 174, 173, 0.22);
    border: 1px solid lighten(map-get($colors, grey), 30);

    h3 {
        padding-bottom: rem-calc(7px);
        @include font(primary, 20px, 28px, regular);
    }
}

.bank-info-wrap {
    border: 1px solid lighten(map-get($colors, grey), 40);

    .bank-top {
        background-color: lighten(map-get($colors, brand), 45);
        .bank-top-box {
            padding: rem-calc(10px 15px);

            span {
                @include font(primary, 14px, 20px, regular);
            }
            h4,
            strong {
                display: block;
                @include font(primary, 15px, 20px, bold);
            }
        }
    }

    .bank-bottom {
        padding: rem-calc(10px 15px);
        .bank-info {
            padding-right: rem-calc(10px);
            ul {
                &:not(:last-child){
                    padding-bottom: rem-calc(15px);
                }
                li {
                    @include font(primary, 14px, 20px, regular);
                }
            }
        }
        .upload-section {
            &::v-deep .multi-image-uploader {
                h3 {
                    padding: rem-calc(0 0 5px);
                    @include font(primary, 14px, 20px, semi_bold);
                }
                .image-container {
                    height: 170px;
                    border: 1px dashed #206ec5;
                }
            }
        }
    }
}

@include media(767px) {
    .bank-info-wrap {
        .bank-bottom {
            .bank-info {
                width: 100%;
                padding: rem-calc(0 0 15px);
            }
        }
    }
}
@include media(sm) {
    

    .bank-info-wrap {
        .bank-top {
            background-color: lighten(map-get($colors, brand), 45);
            .bank-top-box {
                width: 100%;
                padding: rem-calc(10px 15px);
                border-bottom: 1px solid #ddd;
            }
        }
    }
}