@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import "@/sass/app/index.scss";
.link-btn {
  padding: rem-calc(0 12px);
  display: inline-block;
  text-decoration: underline;
  transition: all 300ms;
  @include font(false, 16px, 14px, bold);
}
