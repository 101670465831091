@import "app/index.scss";

.card-list-wrap {
  padding: rem-calc(30px);
  margin: rem-calc(0 0 40px);

  h1 {
    padding: 0;
    @include font(primary, 35px, 40px, medium);
  }
}
.bill-to {
  height: 35px;
}

.watch-list-related-product {
  padding: rem-calc(0 30px);
}
.card-list-table {
  padding: rem-calc(20px 0 0);

  .proceed {
    padding: rem-calc(25px 0 0);
    text-align: right;
    .confirm-order {
      height: 43px;
      width: 250px;
      @include font(false, 18px, 26px, semi_bold);
    }
  }
  .cart-product-wrap {
    padding: rem-calc(10px);
    margin-bottom: rem-calc(15px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
    border: 1px solid lighten(map-get($colors, grey), 35);

    .product-img {
      width: 150px;
      display: flex;
      align-items: center;

      img {
        width: calc(100% - 36px);
        border: 1px solid lighten(map-get($colors, grey), 35);
      }
    }
    .product-info {
      width: calc(100% - 150px);

      .unit-price {
        @include font(brand, 15px, 20px, semi_bold);
      }
      .selected-attIn {
        .selected-att {
          font-size: 13px;
          &:not(:last-child) {
            strong {
              position: relative;
              &::after {
                content: ", ";
                font-weight: normal;
              }
            }
          }
        }
      }
    }

    h4 {
      padding-bottom: rem-calc(5px);
      @include font(false, 15px, 20px, regular);
    }
  }

  table {
    width: 100%;
    padding: 0;
    margin-bottom: rem-calc(13px);
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-collapse: collapse;

    th {
      height: 50px;
      padding: rem-calc(0 20px);
      text-align: left;
      @include font(primary, 16px, 22px, semi_bold);
      background-color: lighten(map-get($colors, grey), 45);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    td {
      padding: rem-calc(20px);
      text-align: left;
      @include font(false, 13px, 20px, false);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      .cart-product-title {
        max-width: 450px;
        padding-left: rem-calc(40px);
        h4 {
          padding-bottom: rem-calc(5px);
          @include font(primary, 15px, 22px, regular);
        }
        .selected-attIn {
          .selected-att {
            &:not(:last-child) {
              strong {
                position: relative;
                &::after {
                  content: ", ";
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
      .unit-price {
        @include font(brand, 17px, 22px, medium);
      }
    }
  }
  .more-product {
    @include font(light, 16px, 22px, false);
    background-color: map-get($colors, primary) !important;
    &:hover {
      color: map-get($colors, light);
      background-color: map-get($colors, primary) !important;
    }
  }
}
.stock-out-wrap {
  h3 {
    padding-bottom: rem-calc(5px);
    @include font(primary, 20px, 30px, regular);
  }
  table {
    &.stock-out {
      th {
        height: 40px;
        padding: rem-calc(0 5px);
        background-color: lighten(map-get($colors, brand), 45);
      }
      td {
        padding: rem-calc(5px);
      }
    }
  }
}
@include media(lg) {
  .card-list-wrap {
    padding: rem-calc(15px);

    h1 {
      padding: 0;
      @include font(primary, 25px, 30px, medium);
    }
  }
  .card-list-table {
    padding: rem-calc(10px 0 0);
  }
  .watch-list-related-product {
    padding: rem-calc(0 15px);
  }
}
@include media(md) {
  .card-list-table {
    .proceed {
      padding: rem-calc(10px 0);
      border-bottom: 1px solid #ddd;
    }
  }
  .stock-out-wrap {
    overflow-x: scroll;
    overflow-y: hidden;
    table {
      width: 800px;
    }
  }
}

@include media(479px) {
  .card-list-table {
    .cart-product-wrap {
      padding: rem-calc(5px);

      .product-img {
        width: 100px;
      }
      .product-info {
        width: calc(100% - 100px);

        .unit-price {
          @include font(false, 14px, 18px, false);
        }
        .qty-holder {
          width: 100%;
          margin-bottom: rem-calc(5px);
        }
      }

      h4 {
        padding-bottom: rem-calc(5px);
        @include font(false, 14px, 18px, regular);
      }
    }
  }
}

.discount-amount {
  color: #06c9a5;
}
