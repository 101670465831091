@import "app/index.scss";

.payment-list-wrap {
  padding: rem-calc(30px);
  margin: rem-calc(0 0 40px);

  .v-btn {
    &.confirm-order {
      height: 50px;
      width: 300px;
      @include font(false, 18px, 26px, semi_bold);
    }
  }
  .order-note {
    .input-label {
      @include font(primary, 18px, 26px, semi_bold);
      small {
        @include font(grey, 12px, 16px, regular);
      }
    }
  }
}
.payment-top {
  padding: rem-calc(20px 30px);
  margin: rem-calc(0 0 28px);
  background-color: lighten(map-get($colors, grey), 45);

  h3 {
    padding: rem-calc(0 0 5px);
    @include font(primary, 20px, 26px, semi_bold);
  }
  span {
    display: block;
    @include font(false, 15px, 22px, false);
  }
}

.payment-list {
  .not-available {
    padding: rem-calc(15px 20px);
    margin-top: rem-calc(20px);
    border: 1px solid lighten(map-get($colors, grey), 30);

    h4 {
      @include font(primary, 18px, 26px, semi_bold);
    }
    .v-radio {
      opacity: 0.5;
    }
  }

  .v-radio {
    height: 80px;
    padding: rem-calc(0 40px);
    margin: rem-calc(10px 0);
    background-color: lighten(map-get($colors, grey), 45);
    border: 1px solid lighten(map-get($colors, grey), 45);
    &.v-item--active {
      border-color: map-get($colors, brand);
    }

    .payment-title {
      span {
        padding-right: rem-calc(20px);
        @include font(primary, 16px, 26px, semi_bold);
      }
    }
  }
}

@include media(lg) {
  .payment-list-wrap {
    padding: rem-calc(15px);
    .v-btn {
      &.confirm-order {
        height: 50px;
        width: 250px;
      }
    }
  }
}
@include media(720px) {
  .payment-list-wrap {
    .v-btn.confirm-order {
      height: 50px;
      width: 100%;
  }
  }
}
@include media(md) {
  .site-content-wrap {
    padding: rem-calc(15px 0 0);
  }
  .payment-list {
    .v-radio {
      height: 80px;
      padding: rem-calc(0 15px);

      .payment-title {
        img {
          max-width: 100%;
        }
      }
    }
  }
}
