@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import "@/sass/app/index.scss";

.account-sidebar {
  width: 375px;

  h5 {
    font-size: 15px;
    &.separator {
      border-top: 20px solid #f1f3f6;
    }
  }

  .user-box {
    padding: rem-calc(18px 17px);
    position: relative;
    border: 1px solid map-get($colors, brand);
    .v-avatar {
      border: 1px solid lighten(map-get($colors, grey), 25);
      a {
        width: 100%;
        height: 100%;
        display: block;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    span {
      @include font(primary, 15px, 22px, false);
    }
    .verified {
      display: block;
    }
    h4 {
      @include font(false, 16px, 22px, semi_bold);
      @include truncate-clamp(1);
    }
    .notification {
      overflow: visible;
      @include absolute(false, false, right, 10px, top, 10px);
      .notification-count {
        @include font(light, 11px, 16px, regular);
        @include absolute(false, false, right, -5px, top, -5px);
      }
    }
  }
  .v-list-item__title {
    font-size: 15px !important;
  }
}

.drawer-toggle {
  left: 255px;
  top: 24px;
  z-index: 9;
  position: fixed;
  background: #fff;
}

@include media(1639px) {
  .account-sidebar {
    width: 275px;
  }
}

@include media(lg) {
  .account-sidebar {
    width: 300px;
    .user-box {
      padding: rem-calc(30px 15px 10px);
      border: none;
    }
  }
}
