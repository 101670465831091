@import "app/index.scss";

.account-wrap {
  width: calc(100% - 375px);
  padding-left: rem-calc(30px);
}
.account-panel {
  padding: rem-calc(30px 15px);
}

.campaing-btn {
  width: 100%;
  margin-bottom: rem-calc(19px);
  border-bottom: 1px solid lighten(map-get($colors, grey), 37);

  a {
    padding: rem-calc(0 20px 10px);
    display: inline-block;
    position: relative;
    @include font(primary, 14px, 18px, medium);
    &:hover {
      color: map-get($colors, brand);
    }
    &:hover:before {
      @include sudo(100%, 1px, left, 0, bottom, -1px, brand);
    }
    &.active {
      &::before {
        @include sudo(100%, 1px, left, 0, bottom, -1px, brand);
      }
    }
  }
}
.all-campaing {
  border: 1px solid lighten(map-get($colors, grey), 37);
}
.campaing-item {
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid lighten(map-get($colors, grey), 37);
  }
  &.campaing-item-header {
    height: 40px;
    background-color: lighten(map-get($colors, grey), 45);
  }
  & > div {
    padding: rem-calc(5px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include font(primary, 12px, 18px, medium);
  }
  & > div:not(:last-child) {
    border-right: 1px solid lighten(map-get($colors, grey), 37);
  }
  .banner {
    width: 19.5%;
    img {
      width: 100%;
    }
  }
  .name {
    width: 18.6%;
    h3 {
      @include font(primary, 13px, 18px, medium);
    }
  }
  .sellers {
    width: 6%;
    justify-content: center;
  }
  .close-in {
    width: 18.2%;
    .close-in-box {
      text-align: center;
    }

    .time {
      padding: rem-calc(4px 7px);
      display: inline-block;
      border: 1px solid lighten(map-get($colors, grey), 37);
      background-color: lighten(map-get($colors, grey), 45);
      @include border-radius(20px);

      span {
        padding: rem-calc(0 5px);
        display: inline-block;
        position: relative;
        &::before {
          @include sudo(2px, 12px, right, 0, top, 3px, silver_tree);
        }
        &:last-child::before {
          display: none;
        }
      }
    }
  }
  .live {
    width: 22.6%;
  }
  .status {
    width: 6.2%;
    justify-content: center;
  }
  .actions {
    width: 8.7%;
    justify-content: center;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@include media(1639px) {
  .account-wrap {
    width: calc(100% - 275px);
    padding-left: rem-calc(30px);
  }
}
@include media(lg) {
  .account-wrap {
    width: 100%;
    padding-left: 0;
  }
}
@include media(767px) {
}

@include media(sm) {
}

@include media(479px) {
}
