@import "app/index.scss";

.account-wrap {
  width: calc(100% - 375px);
  padding-left: rem-calc(30px);
}
.account-panel {
  padding: rem-calc(30px 15px);
}

.campaing-btn {
  width: 100%;
  margin-bottom: rem-calc(19px);
  border-bottom: 1px solid lighten(map-get($colors, grey), 37);

  a {
    padding: rem-calc(0 20px 10px);
    display: inline-block;
    position: relative;
    @include font(primary, 14px, 18px, medium);
    &:hover {
      color: map-get($colors, brand);
    }
    &:hover:before {
      @include sudo(100%, 1px, left, 0, bottom, -1px, brand);
    }
    &.active {
      &::before {
        @include sudo(100%, 1px, left, 0, bottom, -1px, brand);
      }
    }
  }
}
.campaing-details {
  border: 1px solid lighten(map-get($colors, grey), 37);
  & > div {
    padding: rem-calc(15px);
  }
  .image-box {
    width: 31.4%;
    border-right: 1px solid lighten(map-get($colors, grey), 37);
    img {
      width: 100%;
      display: block;
    }
  }
  .campaing-mid {
    width: 40%;
    border-right: 1px solid lighten(map-get($colors, grey), 37);
    h3 {
      @include font(primary, 18px, 24px, medium);
      padding-bottom: rem-calc(10px);
    }
    p {
      padding-bottom: rem-calc(7px);
      @include font(primary, 13px, 18px, false);
    }
    ul {
      li {
        padding-bottom: rem-calc(3px);
        @include font(primary, 13px, 18px, false);
      }
    }
  }
  .campaing-right {
    width: 28.5%;
    h3 {
      @include font(primary, 18px, 24px, medium);
      padding-bottom: rem-calc(10px);
    }
    span {
      display: block;
      @include font(primary, 13px, 18px, false);
      &.join {
        padding: rem-calc(10px 0);
        @include font(brand, 16px, 18px, medium);
      }
      &.sellers {
        padding: rem-calc(10px 0);
        @include font(grey, 15px, 18px, false);
      }
    }
    h4 {
      padding: rem-calc(20px 0 5px);
      @include font(primary, 18px, 24px, medium);
    }
  }
}

@include media(1639px) {
  .account-wrap {
    width: calc(100% - 275px);
    padding-left: rem-calc(30px);
  }
}
@include media(lg) {
  .account-wrap {
    width: 100%;
    padding-left: 0;
  }
}
@include media(767px) {
}

@include media(sm) {
}

@include media(479px) {
}
