@import "app/index.scss";

.account-wrap {
  width: calc(100% - 375px);
  padding-left: rem-calc(30px);
}
.account-panel {
  padding: rem-calc(30px 15px);
}

.campaing {
  border: 1px solid lighten(map-get($colors, grey), 37);
  .campaing-title {
    padding: rem-calc(10px 15px);
    border-bottom: 1px solid lighten(map-get($colors, grey), 37);

    h2 {
      padding: rem-calc(0 15px 5px 0);
      @include font(primary, 18px, 24px, medium);
    }

    .view-all {
      display: block;
      padding: rem-calc(0 15px);
      @include border-radius(30px);
      border: 1px solid map-get($colors, brand);
      @include font(primary, 14px, 28px, false);
      &:hover {
        color: map-get($colors, brand);
      }
    }
  }
  .campaing-container {
    padding: rem-calc(10px 15px);
  }
  .campaing-box {
    height: 100%;
    padding-bottom: 53px;
    position: relative;
    border: 1px solid lighten(map-get($colors, grey), 37);
  }
  .remain-time {
    padding: rem-calc(8px 15px 10px);
    display: flex;
    justify-content: space-evenly;
    strong {
      padding: rem-calc(0 15px);
    }
    span {
      display: block;
      text-align: center;
      @include font(primary, 20px, 28px, regular);
    }
    small {
      display: block;
      @include font(grey, 13px, 20px, medium);
      text-align: center;
      text-transform: uppercase;
      border-top: 1px solid lighten(map-get($colors, grey), 37);
    }
  }
  .campaing-banner {
    padding: 0;
    margin: 0;
    position: relative;
    img {
      width: 100%;
      display: block;
    }
    .type {
      padding: rem-calc(6px 13px);
      text-transform: uppercase;
      background-color: map-get($colors, brand);
      @include font(primary, 12px, 16px, bold);
      @include absolute(false, false, left, -8px, top, 8px);
    }
  }
  .campaing-content {
    padding: rem-calc(12px 15px 0);
  }
  .btn-container {
    padding: rem-calc(0 15px 13px);
    @include absolute(100%, false, left, 0, bottom, 0);
  }
  .start-and-end {
    @include font(grey, 14px, 16px, false);
  }
  .title {
    margin-bottom: rem-calc(6px);
    @include truncate-clamp(2);
    @include font(primary, 16px, 26px, medium);
  }
  .content-item {
    display: block;
    @include font(primary, 14px, 20px, medium);
  }
  .total {
    padding: rem-calc(12px 0);
    span {
      padding-right: rem-calc(7px);
      display: inline-block;
      @include font(grey, 14px, 20px, medium);
    }
  }
}

@include media(1639px) {
  .account-wrap {
    width: calc(100% - 275px);
    padding-left: rem-calc(30px);
  }
  .campaing {
    .remain-time {
      strong {
        padding: rem-calc(0 8px);
      }
      span {
        @include font(false, 17px, 22px, false);
      }
      small {
        @include font(false, 11px, 20px, false);
      }
    }
  }
}
@include media(lg) {
  .account-wrap {
    width: 100%;
    padding-left: 0;
  }
}
@include media(767px) {
}

@include media(sm) {
  .campaing {
    .campaing-container {
      padding: rem-calc(10px 7px);
    }
  }
}

@include media(479px) {
}
