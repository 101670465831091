@import "app/index.scss";

.account-wrap {
  width: calc(100% - 375px);
  padding-left: rem-calc(30px);
}

.account-panel {
  padding: rem-calc(0);
}

.account-panel-top {
  padding-bottom: rem-calc(15px);
  h2 {
    @include font(primary, 25px, 35px, medium);
    padding-bottom: rem-calc(10px);

    span {
      @include font(grey, 15px, 22px, regular);
    }
  }
}

.order-details-info {
  padding: rem-calc(15px 20px 30px);
  margin-bottom: rem-calc(30px);
  background-color: #fff;
  border-top: 1px solid lighten(map-get($colors, grey), 35);
  .order-wrap {
    .price {
      @include font(false, 18px, 22px, regular);
      strong {
        @include font(false, 18px, 22px, semi_bold);
      }
    }
  }
}

.order-wrap {
  margin-bottom: rem-calc(30px);
  border: 1px solid lighten(map-get($colors, grey), 42);
  .order-no {
    @include font(false, 13px, 15px, regular);
  }
  .placed-on {
    @include font(false, 13px, 15px, regular);
  }
  .price {
    @include font(false, 14px, 20px, regular);
    strong {
      @include font(false, 14px, 20px, semi_bold);
    }
  }
  .package {
    @include font(false, 14px, 20px, medium);
  }
  .sold-by {
    @include font(false, 11px, 20px, medium);
    a {
      text-decoration: underline;
    }
    &:hover a {
      text-decoration: none;
    }
  }
  .delivered-on {
    @include font(false, 13px, 20px, false);
  }
}

// .tab-timeline {
//     ul {
//         &:not(:last-child) {
//             padding-bottom: rem-calc(15px);
//         }
//         li {
//             flex: 1;
//             position: relative;
//             .bar {
//                 position: relative;
//                 display: block;
//                 text-align: center;
//                 .tab-icon {
//                     width: 18px;
//                     height: 18px;
//                     border-radius: 50%;
//                     display: block;
//                     margin: 0 auto;
//                     position: relative;
//                     z-index: 1;
//                     background-color: lighten(map-get($colors, grey), 40);
//                 }
//                 .tab-info {
//                     padding: rem-calc(10px 0);
//                     @include font(primary, 13px, 20px, false);
//                 }

//                 &::before,
//                 &::after {
//                     @include sudo(100%, 5px, left, -50%, top, 7px, false);
//                     background-color: lighten(map-get($colors, grey), 40);
//                 }
//             }
//             .arrow {
//                 display: none;
//                 background-color: map-get($colors, site_bg);
//                 border-top: 1px solid lighten(map-get($colors, grey), 35);
//                 border-left: 1px solid lighten(map-get($colors, grey), 35);
//                 transform: translateX(-50%) rotate(45deg);
//                 @include absolute(30px, 30px, left, 50%, top, 69px);
//             }
//             &:first-child {
//                 .bar {
//                     &::after {
//                         display: none;
//                     }
//                     &::before {
//                         left: 50%;
//                         width: 45px;
//                     }
//                 }
//             }
//             &:last-child {
//                 .bar {
//                     &::after,
//                     &::before {
//                         width: 100%;
//                     }
//                 }
//             }
//             &.active {
//                 .tab-icon {
//                     background: map-get($colors, brand);
//                 }
//                 .bar {
//                     &::before {
//                         z-index: 2;
//                         background-color: map-get($colors, brand);
//                     }
//                 }
//             }
//             &.open {
//                 .arrow {
//                     display: block;
//                 }
//             }
//         }
//     }
// }

.timeline-content {
  width: 85%;
  margin: 0 auto;
  padding: rem-calc(10px 30px);
  background-color: map-get($colors, site_bg);
  border: 1px solid lighten(map-get($colors, grey), 35);
  .package-info {
    @include font(primary, 13px, 21px, regular);

    .package-time {
      color: map-get($colors, silver_tree);
    }
    .package-text {
      width: calc(100% - 150px);
    }
  }
}

@include media(1639px) {
  .account-wrap {
    width: calc(100% - 275px);
    padding-left: rem-calc(30px);
  }
}

@include media(lg) {
  .account-wrap {
    width: 100%;
    padding-left: 0;
  }
}
@include media(md) {
}
@include media(sm) {
  .account-panel {
    padding: rem-calc(15px 0);
  }
  .order-details-info {
    padding: rem-calc(15px 10px);
  }
  .timeline-content {
    width: auto;
    padding: rem-calc(10px);

    .package-info {
      flex-wrap: wrap;
      .package-text,
      .package-time {
        width: 100%;
        display: block;
      }
    }
  }
  .package-wrap {
    padding: rem-calc(10px);
  }
}
