@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import "@/sass/app/index.scss";

.v-breadcrumbs {
  padding: 0;

  li {
    @include font(grey, 15px, 20px, false);
  }
}
