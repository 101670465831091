@import "app/index.scss";

.blog-panel {
    padding: rem-calc(95px 0);

    .blog-top {
        padding-bottom: rem-calc(25px);
        span {
            display: inline-block;
            color: lighten(map-get($colors, grey), 15);
            @include font(false, 14px, 24px, false);
            .v-icon.v-icon {
                margin-right: rem-calc(3px);
                font-size: 23px;
                color: lighten(map-get($colors, grey), 20);
            }
        }
    }

    .blog-item {
        width: 64%;
    }
    .blog-sidebar {
        width: 32%;
    }
    .blog-item-content {
        @include transition(all, 300ms);
        &:not(:last-child) {
            margin-bottom: rem-calc(112px);
        }
        &:hover {
            transform: translateY(-8px);
            .v-image {
                transform: scale(1.07);
            }
        }
    }
    .blog-left {
        max-width: 39%;

        h3 {
            padding-bottom: rem-calc(30px);
            @include font(primary, 22px, 35px, medium);
        }
        p {
            padding-bottom: rem-calc(36px);
            @include font(false, 15px, 26px, false);
        }
        .read-more {
            width: 136px;
            height: 45px;

            &:hover {
                background: map-get($colors, brand) !important;
            }
        }
    }
    .feature-image {
        max-width: 54.8%;
        overflow: hidden;

        .v-image {
            @include transition(all, 400ms);
        }
    }
}
.blog-pagination {
    padding: rem-calc(0 0 90px);
}

/*Blog post details*/
.blog-post-details {
    padding: rem-calc(40px 50px 0);

    .blog-top {
        padding-bottom: rem-calc(25px);
        span {
            padding-right: rem-calc(40px);
        }
    }

    h2 {
        padding-bottom: rem-calc(40px);
        @include font(primary, 35px, 40px, medium);
    }
    h5 {
        @include font(primary, 15px, 26px, bold);
    }
    p {
        padding-bottom: rem-calc(30px);
        @include font(false, 15px, 26px, false);
    }

    .share-blog {
        padding-bottom: rem-calc(14px);
        border-bottom: 1px solid lighten(map-get($colors, grey), 35);

        h5 {
            @include font(false, 17px, 22px, regular);
        }

        .share-icons {
            width: 150px;

            .v-icon {
                color: lighten(map-get($colors, grey), 25);
                &:hover {
                    color: map-get($colors, brand);
                }
            }
        }
    }
}

@include media(1639px) {
    .blog-panel {
        .blog-top {
            padding-bottom: rem-calc(15px);
        }
        .blog-item {
            width: 68%;
        }
        .blog-sidebar {
            width: 28%;
        }
        .blog-item-content {
            &:not(:last-child) {
                padding-bottom: rem-calc(70px);
            }
        }

        .blog-left {
            max-width: 48%;
            h3 {
                padding-bottom: rem-calc(20px);
                @include font(false, 22px, 28px, false);
            }
            p {
                padding-bottom: rem-calc(20px);
            }
        }
        .feature-image {
            max-width: 50%;
        }
    }
}

@include media(lg) {
    .blog-panel {
        padding: rem-calc(50px 0);
        .blog-item {
            width: 60%;
        }
        .blog-sidebar {
            width: 35%;
        }

        .blog-left {
            max-width: 100%;
            order: 2;
        }
        .feature-image {
            max-width: 100%;
            padding-bottom: rem-calc(30px);
            order: 1;
        }
    }
    .blog-pagination {
        padding: rem-calc(0 0 50px);
    }

    .blog-post-details {
        padding: rem-calc(20px 15px 0);

        .blog-top {
            padding-bottom: rem-calc(20px);
            span {
                padding-right: rem-calc(20px);
            }
        }

        h2 {
            padding-bottom: rem-calc(20px);
            @include font(false, 24px, 30px, false);
        }
    }
}

@include media(md) {
    .blog-panel {
        .blog-item {
            width: 100%;
        }
        .blog-sidebar {
            width: 100%;
            padding-top: rem-calc(50px);
        }
    }
}
