@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import "@/sass/app/index.scss";

.inner-banner {
  padding: 0;

  h1 {
    @include font(primary, 40px, 50px, medium);

    span {
      padding-top: rem-calc(5px);
      display: block;
      @include font(false, 16px, 22px, regular);
    }
  }
}
@include media(md) {
  .inner-banner {
    h1 {
      @include font(primary, 30px, 40px, medium);
    }
  }
}
