@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import "@/sass/app/index.scss";

/*============Shop Banner===========*/
.shop-banner {
  padding: 0;
  margin: 0;
  img {
    max-width: 100%;
  }
}

.shop-info {
  background-color: #fff;
  border: 1px solid #e4e1dc;
  h3 {
    padding-bottom: rem-calc(4px);
    @include font(primary, 24px, 28px, medium);
  }
  span {
    white-space: nowrap;
    max-width: 350px;
    @include truncate-clamp();
  }
}
/*============End Shop Banner===========*/
