@import "app/index.scss";

.create-ad-top {
  padding: rem-calc(25px 30px);
  h2 {
    padding-bottom: rem-calc(8px);
    @include font(primary, 35px, 40px, semi_bold);
  }
  .allowance {
    padding-bottom: rem-calc(4px);
    display: block;
    @include font(primary, 20px, 30px, false);

    strong {
      @include font(primary, 24px, 30px, semi_bold);
    }
  }
  .note {
    display: flex;
    align-items: center;
    @include font(primary, 14px, 20px, false);
    span {
      padding-left: 3px;
      display: inline-block;
      @include font(brand, 20px, 26px, semi_bold);
    }
  }
  .buy-points {
    display: inline-block;
    padding: rem-calc(5px 10px);
    margin-left: rem-calc(10px);
    @include font(light, 14px, 20px, false);
    background-color: map-get($colors, silver_tree);

    &:hover {
      color: map-get($colors, light);
      background-color: map-get($colors, brand);
    }
  }
  .available-point {
    text-align: right;
  }
}
.create-ad {
  padding: rem-calc(55px 60px);

  .v-input {
    input {
      height: 43px;
      max-height: 43px;
      padding: 0;
      @include font(primary, 15px, 43px, false);
    }
    fieldset {
      border: 1px solid #dbdbdb;
      border-radius: 0;
    }
  }
  .publish-ad {
    width: 200px;
    height: 50px !important;
    @include font(false, 18px, 22px, bold);
  }
  .v-treeview-node__prepend {
    display: none;
  }
  .v-treeview-node__label {
    cursor: pointer;
  }
}
.add-title {
  margin-bottom: rem-calc(30px);
  @include font(primary, 30px, 40px, medium);
  background-color: lighten(map-get($colors, grey), 45);
}

.pick-category {
  padding: rem-calc(15px 0 40px);
  span {
    @include font(grey, 15px, 20px, false);
  }
  h3 {
    @include font(primary, 21px, 24px, regular);
  }
  ul {
    padding-left: rem-calc(10px);
    margin: 0;
    li {
      @include font(brand, 15px, 24px, false);
      .v-icon {
        font-size: 20px;
      }
      &:last-child {
        .v-icon {
          display: none;
        }
      }
    }
  }
  .edit-category {
    margin-left: rem-calc(15px);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.cat-select-wrap {
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  @include scroll-y(6px);
}
.v-input--selection-controls {
  padding: 0;
  margin: 0;
}
.conditions {
  padding-bottom: rem-calc(23px);

  h4 {
    margin-right: rem-calc(25px);
    @include font(primary, 16px, 22px, semi_bold);
  }
  .v-label {
    color: map-get($colors, primary);
  }
}

.multi-image-uploader {
  padding: rem-calc(15px);
  margin: rem-calc(20px 0 35px);
  border: 1px dashed lighten(map-get($colors, grey), 30);

  &::v-deep h3 {
    padding: rem-calc(0 0 5px);
    @include font(primary, 15px, 22px, medium);
  }
  .drag-text,
  .browse-text {
    @include font(primary, 12px, 14px, medium);
  }
  &::v-deep div[edit-upload="images-uploader-edit-1"] {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .preview-image {
      border-radius: 0;
      height: 110px;
    }
    .image-overlay {
      border-radius: 0;
      z-index: 1;
    }
    .image-container {
      height: 145px;
      width: 165px;
    }
    .image-list-container {
      padding-left: rem-calc(20px);
      margin: 0;
      max-width: 100%;
      width: calc(100% - 165px);
      min-height: 50px;

      .image-list-item {
        width: 100px;
        height: 90px;
        margin: rem-calc(5px);
        .centered {
          width: 99%;
          height: 99%;
          display: flex;
          .show-img {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
          }
        }
      }
      .image-highlight {
        border: 1px solid map-get($colors, brand);
      }
    }
  }
}
.auction-check {
  // #07abe0
  &::v-deep .v-radio {
    margin: 0 !important;
    padding: rem-calc(15px);
    &.general {
      background-color: #edf9f7;
      border: 1px solid #a4e9dc;
    }
    &.bargain {
      background-color: #edf7ff;
      border: 1px solid #c4e4fd;
    }
    &.sale-by-nilam {
      border: 1px solid lighten(map-get($colors, brand), 25);
      background-color: lighten(map-get($colors, brand), 48);
    }
    &.general.v-item--active {
      background-color: #e4fbf7;
      border-color: #8ae3d4;
      box-shadow: 0px 2px 8px #d1d1d1;
      .v-icon {
        color: #01b190 !important;
      }
      .v-label {
        color: #01b190;
      }
    }
    &.sale-by-nilam.v-item--active {
      border-color: #ffd699;
      background-color: #fdf5e8;
      box-shadow: 0px 2px 8px #d1d1d1;
    }
    &.bargain.v-item--active {
      background-color: #dff1ff;
      border-color: #a7d8ff;
      box-shadow: 0px 2px 8px #d1d1d1;
      .v-icon {
        color: #02b9f5 !important;
      }
      .v-label {
        color: #02b9f5;
      }
    }

    .v-label {
      @include font(primary, 18px, 22px, false);
    }
    &.v-item--active {
      .v-label {
        font-weight: map-get($font-weight, medium);
      }
    }
    &.v-item--active.sale-by-nilam {
      .v-label {
        color: map-get($colors, brand);
      }
    }
  }
}
.renilam {
  &::v-deep .v-label {
    color: darken(map-get($colors, primary), 10);
  }
}

.product-masurment {
  padding: rem-calc(20px 15);
  border: 1px solid #dfdfdf;
  background-color: #f5f5f5;

  .input-label {
    padding-top: rem-calc(9px);
    padding-right: rem-calc(10px);
  }

  .tooltip-wrap {
    padding-right: rem-calc(34px);
    position: relative;

    .tooltip-holder {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
.nilam-sale-panel {
  padding: rem-calc(20px 15);
  border: 1px solid #ffcc80;
  background-color: #fffbf5;
}
.general-sale-panel {
  padding: rem-calc(20px 15);
  background-color: #edf9f7;
  border: 1px solid #a4e9dc;
}
.bargain-sale-panel {
  padding: rem-calc(20px 15);
  background-color: #edf7ff;
  border: 1px solid #c4e4fd;
}

.auction-time {
  padding: rem-calc(20px 15);
  border: 1px solid lighten(map-get($colors, brand), 35);
  background-color: lighten(map-get($colors, brand), 48);
  h3 {
    padding-bottom: rem-calc(5px);
    @include font(brand, 20px, 24px, medium);
    border-bottom: 1px solid lighten(map-get($colors, grey), 40);
  }
  .v-sheet {
    padding: rem-calc(10px 0);
    @include font(primary, 15px, 22px, false);
  }
  .vue-daterange-picker {
    display: block;
  }
}

.personal-information {
  padding: rem-calc(40px 0 0);

  h3 {
    padding: rem-calc(0 0 5px);
    margin-bottom: rem-calc(16px);
    @include font(brand, 20px, 22px, medium);
    border-bottom: 1px solid lighten(map-get($colors, grey), 36);
  }
}

//success message popub
.success-popup {
  border-radius: 0;
  .popup-wrap {
    position: relative;
    text-align: center;
    padding: rem-calc(140px 83px 114px);

    h3 {
      padding: rem-calc(43px 0 34px);
      @include font(silver_tree, 30px, 40px, regular);
    }
    .published {
      padding: rem-calc(0 0 95px);
      @include font(primary, 18px, 28px, false);
      a {
        text-decoration: underline;
        font-weight: map-get($font-weight, semi_bold);
      }
    }
    .v-btn {
      height: rem-calc(43px);

      @include font(false, 18px, 28px, semi_bold);
    }
    .popup-hand {
      @include absolute(false, false, left, 0, bottom, 0);
    }
  }
}
.back {
  text-decoration: underline;
  @include font(false, 18px, 22px, false);
  &:hover {
    text-decoration: none;
  }
}

@include media(lg) {
  .create-ad {
    padding: rem-calc(20px);
  }
}
@include media(md) {
  .create-ad-top {
    .available-point {
      text-align: left;
    }
  }
}
@include media(699px) {
  .breadcrumb-holder {
    margin-bottom: rem-calc(15px);
  }
  .pick-category {
    padding-bottom: rem-calc(15px);
  }

  .create-ad-top {
    padding: rem-calc(15px);
    h2 {
      padding-bottom: rem-calc(12px);
      @include font(false, 25px, 30px, false);
    }
  }

  .success-popup {
    .popup-wrap {
      padding: rem-calc(40px 20px 150px);
      h3 {
        padding: 24px 0 20px;
        @include font(false, 24px, 30px, false);
      }
      .published {
        padding: rem-calc(0 0 35px);
      }

      .popup-hand {
        width: 38%;
      }
    }
  }
}
@include media(sm) {
  .multi-image-uploader {
    &::v-deep div[edit-upload="images-uploader-edit-1"] {
      .image-list-container {
        .image-list-item {
          width: 55px;
          height: 55px;
        }
      }
    }
  }
}
@include media(479px) {
  .create-ad {
    padding: rem-calc(10px);
  }
  .conditions {
    flex-wrap: wrap;
  }
  .success-popup {
    .popup-wrap {
      padding: rem-calc(40px 20px 130px);
      h3 {
        padding: 24px 0 20px;
        @include font(false, 24px, 30px, false);
      }
      .published {
        padding: rem-calc(0 0 35px);
      }

      .popup-hand {
        width: 38%;
      }
    }
  }
  .multi-image-uploader {
    &::v-deep h3 {
      text-align: center;
    }
    &::v-deep div[edit-upload="images-uploader-edit-1"] {
      justify-content: center;
      .image-list-container {
        width: 100%;
        justify-content: center;
        padding: 5px 0 0;
        .image-list-item {
          width: 55px;
          height: 55px;
        }
      }
    }
    &::v-deep small {
      display: block;
      text-align: center;
    }
  }
}
