@import "app/index.scss";

.account-wrap {
  width: calc(100% - 375px);
  padding-left: rem-calc(30px);
}
.account-panel {
  padding: rem-calc(30px 15px);
}
.join-campaign-wrap {
  h2 {
    @include font(primary, 25px, 30px, medium);
  }
}
.breadcrumb-container {
  padding-bottom: rem-calc(50px);
}
.add-product-btn {
  padding: rem-calc(13px 0);
  margin: rem-calc(30px 0 25px);
  @include border-radius(5px);
  box-shadow: 0 2px 8px lighten(map-get($colors, grey), 35);
  @include font(silver_tree, 14px, 22px, medium);
  border: 1px dashed map-get($colors, silver_tree);
  background-color: lighten(map-get($colors, silver_tree), 51);
}
.selected-product {
  padding-bottom: rem-calc(12px);
  span {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid map-get($colors, brand);
    @include font(brand, 14px, 22px, medium);
  }
}
.input-wrap {
  width: 18%;
  padding-right: rem-calc(20px);
}

@include media(1639px) {
  .account-wrap {
    width: calc(100% - 275px);
    padding-left: rem-calc(30px);
  }
}
@include media(lg) {
  .account-wrap {
    width: 100%;
    padding-left: 0;
  }
}
@include media(767px) {
}

@include media(sm) {
}

@include media(479px) {
}
