@import "app/index.scss";

.about-panel {
    padding: rem-calc(70px 0 65px);
    margin: 0;

    .about-box {
        padding: 0;
        margin: 0;

        h3 {
            padding: rem-calc(0 0 15px);
            @include font(brand, 22px, 26px, medium);
        }
        &::v-deep p {
            padding: rem-calc(0 0 26px) !important;
            font-size: 15px !important;
            line-height: 26px !important;
        }
        &.about-box-bg {
            background-color: lighten(map-get($colors, grey), 44);
        }

        &::v-deep img{
            width: auto !important;
            max-width: 100% !important;
        }
    }
}

@include media(md) {
    .about-panel {
        padding: rem-calc(30px 0);
    }
}
