@import "app/index.scss";

.account-wrap {
    width: calc(100% - 375px);
    padding-left: rem-calc(30px);
}
.account-panel {
    padding: rem-calc(30px 15px);
}
.account-panel-top {
    padding-bottom: rem-calc(15px);
    h2 {
        padding-right: rem-calc(10px);
        @include font(primary, 25px, 35px, medium);

        span {
            @include font(grey, 15px, 22px, regular);
        }
    }
}
.filter-bar {
    padding-bottom: rem-calc(15px);
    .input-wrap {
        width: 22%;
        padding: rem-calc(0 15px 10px 0);
    }
}
.account-panel-table {
    table {
        width: 100%;
        padding: 0;
        margin-bottom: rem-calc(13px);
        border: 1px solid #ccc;
        border-collapse: collapse;

        th {
            height: 50px;
            padding: rem-calc(10px);
            text-align: left;
            @include font(primary, 16px, 19px, semi_bold);
            background-color: lighten(map-get($colors, grey), 45);
            border-bottom: 1px solid lighten(map-get($colors, grey), 35);
        }

        td {
            padding: rem-calc(10px);
            text-align: left;
            border-bottom: 1px solid lighten(map-get($colors, grey), 30);
            @include font(false, 13px, 20px, false);

            .my-product-title {
                max-width: 270px;
                padding-left: rem-calc(8px);
            }

            .product {
                @include truncate-clamp(2);
                margin-bottom: rem-calc(7px);
                @include font(primary, 13px, 14px, semi_bold);
            }

            .last-price {
                @include font(false, 15px, 20px, medium);
            }

            .bargain-product-img {
                &::v-deep .v-image {
                    border: 1px solid lighten(map-get($colors, grey), 30);
                }
            }
        }
    }

    .account-pagination {
        padding-top: rem-calc(20px);
    }
}

.bargain-item {
    padding: rem-calc(10px 0);
    margin-bottom: rem-calc(15px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
    border: 1px solid lighten(map-get($colors, grey), 35);

    .column {
        padding: rem-calc(0 10px);
    }

    .bargain-product-img {
        .v-image {
            border: 1px solid lighten(map-get($colors, grey), 35);
        }
    }
    .product {
        padding-left: rem-calc(10px);
        @include font(false, 15px, 20px, regular);
        @include truncate-clamp(2);
    }
    .price {
        @include font(false, 15px, 16px, regular);
    }
    .last-price {
        @include font(false, 14px, 16px, semi_bold);
    }
    .status {
        @include font(false, 15px, 16px, regular);
    }
    .brgn-1 {
        width: 35%;
    }
    .brgn-2 {
        width: 19%;
    }
    .brgn-3 {
        width: 22%;
    }
}

@include media(1639px) {
    .account-wrap {
        width: calc(100% - 275px);
        padding-left: rem-calc(30px);
    }
}

@include media(lg) {
    .account-wrap {
        width: 100%;
        padding-left: 0;
    }
    .filter-bar {
        .input-wrap {
            width: 33.33%;
        }
        .v-btn {
            width: 200px;
        }
    }
}

@include media(md) {
    .filter-bar {
        .input-wrap {
            width: 50%;
        }
    }
}
@include media(sm) {
    .account-panel {
        padding: rem-calc(15px 10px);
    }
}

@include media(699px) {
    .bargain-item {
        flex-wrap: wrap;
        .btn-box {
            width: 150px;
            margin-left: auto;
        }
        .brgn-1 {
            width: 40%;
        }
        .brgn-2 {
            width: auto;
            display: flex;
        }
        .brgn-3 {
            width: auto;
            justify-content: flex-end;
            display: flex;
        }
        .brgn-4,
        .brgn-5 {
            width: 50%;
        }
    }
}

@include media(550px) {
    .bargain-item {
        .brgn-1 {
            width: 100%;
            padding-bottom: rem-calc(8px);
        }
        .brgn-3,
        .brgn-2 {
            padding-bottom: rem-calc(8px);
        }
    }
}

@include media(479px) {
    .filter-bar {
        .input-wrap {
            padding-right: 0;
            width: 100%;
        }
    }
}
