@import "app/index.scss";
.tab-timeline {
  ul {
    &:not(:last-child) {
      padding-bottom: rem-calc(15px);
    }
    li {
      flex: 1;
      position: relative;
      .bar {
        position: relative;
        display: block;
        text-align: center;
        .tab-icon {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          display: block;
          margin: 0 auto;
          position: relative;
          z-index: 1;
          background-color: lighten(map-get($colors, grey), 40);
        }
        .tab-info {
          padding: rem-calc(10px 0);
          @include font(primary, 13px, 20px, false);
        }

        &::before,
        &::after {
          @include sudo(100%, 5px, left, -50%, top, 7px, false);
          background-color: lighten(map-get($colors, grey), 40);
        }
      }
      .arrow {
        display: none;
        background-color: map-get($colors, site_bg);
        border-top: 1px solid lighten(map-get($colors, grey), 35);
        border-left: 1px solid lighten(map-get($colors, grey), 35);
        transform: translateX(-50%) rotate(45deg);
        @include absolute(30px, 30px, left, 50%, top, 69px);
      }
      &:first-child {
        .bar {
          &::after {
            display: none;
          }
          &::before {
            left: 50%;
            width: 45px;
          }
        }
      }
      &:last-child {
        .bar {
          &::after,
          &::before {
            width: 100%;
          }
        }
      }
      &.active {
        .tab-icon {
          background: map-get($colors, brand);
        }
        .bar {
          &::before {
            z-index: 2;
            background-color: map-get($colors, brand);
          }
        }
      }
      &.open {
        .arrow {
          display: block;
        }
      }
    }
  }
}
