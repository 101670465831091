@import "app/index.scss";

.product-sidebar {
    width: 273px;
    padding: rem-calc(0 15px);
}
.product-sidebar-box {
    padding: rem-calc(20px 0 16px);
    border-bottom: 1px solid lighten(map-get($colors, grey), 40);

    .product-sidebar-in {
        margin-top: rem-calc(10px);
        min-height: 90px;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        @include scroll-y(4px);
    }
    &:first-child {
        padding-top: rem-calc(15px);
    }
    .input-wrap {
        &::v-deep .v-input {
            .v-input__slot {
                padding-left: 5px;
            }
            input {
                height: 37px;
                max-height: 37px;
            }
            .v-icon {
                font-size: 20px;
            }
        }
    }
    .v-card__title {
        padding: rem-calc(0 0 4px);
        @include font(primary, 18px, 24px, medium);
    }
    .v-input--selection-controls {
        margin: rem-calc(0);
    }
    &::v-deep .v-label {
        @include font(primary, 14px, 14px, false);
    }
    .v-icon {
        color: map-get($colors, gery);
    }
    .v-expansion-panel-content__wrap {
        padding: 0;
    }
    .v-expansion-panel-header {
        min-height: 0;
    }
    .v-list-item {
        padding: rem-calc(6px 5px);
        min-height: 0;
        @include font(primary, 14px, 20px, false);
    }
    .min-order {
        padding: 0;
        margin: 0;

        .v-text-field__details:empty {
            display: none;
        }

        .v-input {
            max-width: 160px;

            input {
                height: 30px;
                padding: 0;
                @include font(primary, 14px, 28px, false);
            }
            fieldset {
                border: 1px solid #e5e5e5;
                border-radius: 0;
            }
        }
        .v-btn {
            padding: 0;
            min-width: 37px;
            height: 30px;
            background-color: #f3f3f3;
            border-color: #e5e5e5;
            @include font(primary, 15px, 37px, false);
        }
    }
    &::v-deep .v-treeview {
        @include font(primary, 14px, 28px, false);

        .v-treeview-node__root {
            min-height: 34px;
            padding: 0;
            .v-treeview-node__level {
                width: 10px;
            }
        }
        .v-icon {
            width: auto;
            font-size: 18px;
        }
    }
    .clear-all {
        cursor: pointer;
        @include transition(all, 200ms);
        @include font(primary, 12px, 37px, false);
        .v-icon {
            color: map-get($colors, primary);
        }
        &:hover {
            color: map-get($colors, brand);
            .v-icon {
                color: map-get($colors, brand);
            }
        }
    }
}

.category-link,
.category-link:hover {
    font-size: 14px;
    line-height: 14px;
    color: #353a44 !important;
}
.v-list--dense .v-list-item {
    min-height: 0 !important;
}

// .product-sidebar-category {
//     .v-expansion-panel-content {
//         margin-left: rem-calc(-5px);
//         margin-right: rem-calc(-5px);
//     }

//     .mdi-checkbox-blank-circle {
//         margin-right: rem-calc(5px);
//         @include font(primary, 6px, 20px, false);
//     }
// }

@include media(1639px) {
    .product-sidebar {
        width: 230px;
        padding: rem-calc(0 15px);
    }    
}

