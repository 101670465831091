@import "app/index.scss";
.v-window-item {
  position: relative;
  z-index: 1;
}
.review-wrap {
  padding: rem-calc(20px 15px);
  position: relative;
  border: 1px solid #ddd;
}
.textarea-wrap {
  padding: rem-calc(10px);
  border: 1px solid #ddd;
}

.review-product {
  padding-bottom: rem-calc(20px);
  .v-image {
    border: 1px solid lighten(map-get($colors, grey), 38);
  }
  h3 {
    @include truncate-clamp(2);
    @include font(false, 15px, 20px, regular);
  }
}
.review-content {
  padding-top: rem-calc(20px);
  .writing-info {
    @include font(grey, 14px, 22px, false);

    .v-icon {
      font-size: 18px;
    }
  }
  .review-box {
    padding: rem-calc(10px 0 13px);
    &::v-deep .v-label {
      @include font(grey, 14px, 20px, false);
    }
    &::v-deep .v-textarea textarea {
      min-height: 150px;
      @include font(primary, 14px, 18px, false);
    }
    .v-btn {
      width: 120px;
      @include font(false, 16px, 22px, medium);
    }
  }
}

.modal-carousel {
  &::v-deep .v-window__prev,
  &::v-deep .v-window__next {
    background: transparent;
    .prev,
    .next {
      width: 30px;
      height: 30px;
      cursor: pointer;
      display: flex;
      @include border-radius(50%);
      border: 1px solid lighten(map-get($colors, brand), 20);

      .v-icon {
        margin: auto;
        color: lighten(map-get($colors, brand), 20);
      }
      &:hover {
        border-color: lighten(map-get($colors, brand), 0);
        .v-icon {
          color: lighten(map-get($colors, brand), 0);
        }
      }
    }
  }
}

.multi-image-uploader {
  padding: rem-calc(15px);
  margin: rem-calc(20px 0 35px);
  border: 1px dashed lighten(map-get($colors, grey), 30);

  &::v-deep h3 {
    padding: rem-calc(0 0 5px);
    @include font(primary, 15px, 22px, medium);
  }
  .drag-text,
  .browse-text {
    @include font(primary, 12px, 14px, medium);
  }
  &::v-deep .multi-image-uploader-main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .preview-image {
      border-radius: 0;
      height: 110px;
    }
    .image-overlay {
      border-radius: 0;
      z-index: 1;
    }
    .image-container {
      height: 145px;
      width: 165px;
    }
    .image-list-container {
      padding-left: rem-calc(20px);
      margin: 0;
      max-width: 100%;
      width: calc(100% - 165px);
      min-height: 50px;

      .image-list-item {
        width: 70px;
        height: 60px;
        margin: rem-calc(5px);
        .centered {
          width: 99%;
          height: 99%;
          display: flex;
          .show-img {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
          }
        }
      }
      .image-highlight {
        border: 1px solid map-get($colors, brand);
      }
    }
  }
}

@include media(sm) {
  .multi-image-uploader {
    &::v-deep h3 {
      text-align: center;
    }
    &::v-deep .multi-image-uploader-main {
      justify-content: center;
      .image-list-container {
        width: 100%;
        justify-content: center;
        padding: 5px 0 0;
        .image-list-item {
          width: 55px;
          height: 55px;
        }
      }
    }
    &::v-deep small {
      display: block;
      text-align: center;
    }
  }
}
