@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import "@/sass/app/index.scss";

.bid-form {
  .place-bid-field {
    width: 49%;

    .input-wrap {
      padding: 0;
    }
  }
  .v-btn.place-bid-btn {
    width: 49%;
    height: 43px;
    @include font(light, 16px, 20px, medium);
  }
}

@include media(479px) {
  .bid-form {
    .place-bid-field {
      width: 100%;
    }
    .v-btn.place-bid-btn {
      width: 100%;
      margin-top: rem-calc(10px);
    }
  }
}
