@import "app/index.scss";
@import "../assets/magiczoomplus/magiczoomplus.css";

.product-details-wrap {
  padding: rem-calc(26px 0);

  &::v-deep .product-details-breadcrumb {
    padding: rem-calc(0 30px) !important;
  }
}

.product-details-top {
  padding: rem-calc(15px 30px 0 30px);
}

.product-details-images {
  max-width: 482px;
  .product-image {
    margin-bottom: rem-calc(10px);
    position: relative;
    border: 1px solid lighten(map-get($colors, grey), 35);
    img {
      max-width: 100%;
    }
    .v-btn {
      @include absolute(35px, 35px, right, 10px, top, 10px);
      z-index: 2;
      &::v-deep .v-icon {
        font-size: 18px;
        line-height: 18px;
      }
    }
  }
  .product-thumb {
    .thumb-holder {
      width: 80px;
      margin-right: rem-calc(5px);
      border: 1px solid lighten(map-get($colors, grey), 30);
      img {
        width: 100%;
      }
    }
    &::v-deep .v-slide-group__next,
    &::v-deep .v-slide-group__prev {
      min-width: 26px;
    }
  }
}

.product-details-info {
  max-width: 410px;

  .delivery-option {
    padding: rem-calc(16px 30px);

    .v-card__title {
      color: darken(map-get($colors, grey), 30);
      @include font(false, 15px, 22px, medium);
    }
    .v-card__text {
      color: map-get($colors, grey);
      @include font(false, 15px, 21px, false);

      .star {
        font-size: 14px;
      }
    }
  }
}
.v-tooltip__content {
  line-height: 15px;
  .tooltip-info {
    max-width:250px;
    display: block;
    @include font(false, 12px, 16px, light);
  }
}

.please-contact {
  @include font(false, 16px, 22px, false);
  a {
    color: map-get($colors, brand);
    font-weight: map-get($font-weight, bold);

    &:hover {
      text-decoration: underline;
    }
  }
}
.product-dtls-related-product {
  padding: rem-calc(0 30px);
}

@include media(1639px) {
  .product-details-images {
    max-width: 37%;
  }

  .product-details-info {
    max-width: 20%;

    .delivery-option {
      padding: rem-calc(5px 15px);

      .v-card__text {
        @include font(false, 14px, 20px, false);

        .star {
          font-size: 14px;
        }
      }
    }
  }
}

@include media(lg) {
  .product-details-wrap {
    &::v-deep .product-details-breadcrumb {
      padding: rem-calc(0 15px) !important;
    }
  }

  .product-details-images {
    width: calc(50% - 15px);
    max-width: 100%;
    margin-left: rem-calc(15px);
  }

  .product-details-info {
    max-width: 100%;
    margin: rem-calc(30px 15px 0);

    .delivery-option {
      padding: rem-calc(15px);
    }
  }
  .product-details-mid {
    width: 100%;
  }
  .please-contact {
    padding: rem-calc(0 15px) !important;
  }
  .product-details-top {
    padding: rem-calc(15px 0 0);
  }
}

@include media(699px) {
  .product-details-images {
    width: 100%;
    padding: rem-calc(0 15px);
    margin-left: 0;
  }
}
