@import "app/index.scss";

.account-wrap {
    width: calc(100% - 375px);
    padding-left: rem-calc(30px);
}
.account-panel {
    padding: rem-calc(30px 15px);
}
.product-summery-wrap {
    &::after {
        content: "";
        flex: auto;
    }
    .summery-box {
        width: calc(1 / 5 * 100% - (1 - 1 / 5) * 10px);
        padding: rem-calc(13px);
        margin: 10px 10px 0 0;
        &:nth-child(5n) {
            margin-right: 0;
        }

        h4 {
            padding-bottom: rem-calc(4px);
            @include font(primary, 13px, 18px, regular);
        }
        h2 {
            @include font(primary, 20px, 24px, semi_bold);
        }
        .total-status {
            @include font(grey, 12px, 18px, false);

            strong {
                @include font(false, 12px, 18px, semi_bold);
            }
        }
        &:hover {
            box-shadow: 0 2px 16px #d2d7db;
        }
    }
}
.account-panel-table {
    .account-pagination {
        padding-top: rem-calc(20px);
    }
}
/*===============my products==================*/
.top-filter {
    padding-bottom: rem-calc(10px);
    .input-wrap {
        width: 18.5%;
        padding-right: rem-calc(15px);
    }
}
.product-top {
    padding: rem-calc(12px 20px);
    background-color: map-get($colors, site_bg);
    border-bottom: thin solid rgba(0, 0, 0, 0.12);

    .total-products {
        @include font(grey, 16px, 26px, regular);

        strong {
            @include font(primary, 16px, 26px, medium);
        }
    }
}
.product-item {
    padding: rem-calc(18px);
    position: relative;

    &:after {
        @include sudo(1px, 80%, right, 39%, top, 50%, false);
        transform: translateY(-50%);
        background-color: lighten(map-get($colors, grey), 35);
    }

    &:not(:last-child) {
        border-bottom: 1px solid lighten(map-get($colors, grey), 35);
    }
}
.product-image {
    width: 26.4%;
    .v-image {
        border: 1px solid lighten(map-get($colors, grey), 35);
    }
}
.product-mid {
    width: 34.9%;
    padding-left: rem-calc(33px);
    .category {
        padding-bottom: rem-calc(4px);
        display: block;
        @include font(grey, 15px, 20px, light);
    }
    h2 {
        margin-bottom: rem-calc(8px);
        @include font(primary, 18px, 25px, medium);
        @include truncate-clamp(2);
        a {
            color: map-get($colors, primary);
        }
    }
    .condition-type {
        padding-bottom: rem-calc(15px);

        span {
            @include font(grey, 15px, 20px, false);
            strong {
                @include font(primary, 15px, 20px, medium);
            }
            &:not(:last-child) {
                padding-right: 45px;
            }
        }
    }
    h3 {
        padding-bottom: rem-calc(2px);
        @include font(brand, 30px, 30px, medium);
    }
    .start-bid {
        @include font(grey, 16px, 20px, light);
    }
    .posted-on {
        padding-top: rem-calc(14px);
        @include font(grey, 15px, 20px, regular);
        span {
            color: map-get($colors, primary);
        }
    }
}
.product-right {
    width: 38.7%;
    padding-left: rem-calc(53px);

    h2 {
        padding: rem-calc(20px 0 8px);
        @include truncate-clamp(2);
        @include font(primary, 18px, 25px, semi_bold);
    }
    .bublished-status {
        padding-bottom: rem-calc(6px);
        @include font(brand, 15px, 22px, medium);
    }
    .short-desc {
        padding-bottom: rem-calc(13px);
        @include truncate-clamp(2);
        @include font(grey, 15px, 23px, regular);
    }
    .brand-light {
        color: map-get($colors, brand);
        background-color: lighten(map-get($colors, brand), 37) !important;

        .v-icon {
            color: map-get($colors, brand);
        }
    }
}
.sell {
    padding: rem-calc(70px 20px);

    h2 {
        padding-bottom: rem-calc(4px);
        @include font(brand, 25px, 35px, medium);
    }
    h3 {
        padding-bottom: rem-calc(22px);
        @include font(primary, 22px, 27px, medium);
    }

    .post-promotion {
        width: 193px;
        height: 40px;
        display: inline-block;
        text-align: center;
        @include border-radius(6px);
        @include font(light, 18px, 40px, false);
        border: 1px solid darken(map-get($colors, primary), 10);
        background: rgb(43, 48, 55);
        background: linear-gradient(
            0deg,
            rgba(43, 48, 55, 1) 0%,
            rgba(28, 30, 34, 1) 100%
        );
        .v-icon {
            color: #fff;
        }

        &:hover .v-icon {
            color: map-get($colors, brand);
        }
    }
}

@include media(1639px) {
    .account-wrap {
        width: calc(100% - 275px);
        padding-left: rem-calc(30px);
    }
    .product-image {
        width: 25%;
    }
    .product-mid {
        width: 36%;
        padding-left: rem-calc(15px);
        .category {
            @include font(false, 14px, 18px, false);
        }

        h2 {
            @include font(false, 17px, 24px, false);
        }

        .condition-type {
            padding-bottom: rem-calc(8px);
        }

        h3 {
            @include font(brand, 22px, 30px, medium);
        }

        .posted-on {
            padding-top: rem-calc(8px);
            @include font(false, 14px, 18px, false);
        }
    }
    .product-right {
        width: 39%;
        padding-left: rem-calc(25px);
    }
}

@include media(lg) {
    .account-wrap {
        width: 100%;
        padding-left: 0;
    }
    .top-filter {
        .input-wrap {
            width: 33.33%;
        }
    }
}

@include media(md) {
    .top-filter {
        .input-wrap {
            width: 50%;
        }
    }
    .product-summery-wrap {
        .summery-box {
            width: calc(1 / 3 * 100% - (1 - 1 / 3) * 10px);
            padding: rem-calc(13px);
            margin: 10px 10px 0 0;
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
    .product-mid {
        width: 36%;
        padding-left: rem-calc(15px);
        .category {
            @include font(false, 13px, 18px, false);
        }

        h2 {
            @include font(false, 15px, 20px, false);
        }

        h3 {
            @include font(brand, 20px, 30px, medium);
        }

        .posted-on {
            padding-top: rem-calc(8px);
            @include font(false, 13px, 18px, false);
        }
    }
    .product-right {
        padding-left: rem-calc(15px);
        h2 {
            padding: rem-calc(10px 0 5px);
            @include font(primary, 12px, 18px, semi_bold);
        }
    }
}

@include media(sm) {
    .account-panel {
        padding: rem-calc(0px 10px 10px);
    }
    .product-summery-wrap {
        .summery-box {
            width: calc(1 / 2 * 100% - (1 - 1 / 2) * 10px);
            padding: rem-calc(13px);
            margin: 10px 10px 0 0;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
    .my-product {
        border: none !important;
    }
    .product-item {
        padding: rem-calc(10px);
        margin-bottom: rem-calc(15px);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
        border: 1px solid lighten(map-get($colors, grey), 35);
        &:after {
            display: none;
        }
    }
    .product-image {
        width: 40%;
    }
    .product-mid {
        width: 60%;
        padding-left: rem-calc(10px);
    }
    .product-right {
        width: 100%;
        padding: 10px 0 0 calc(40% + 10px);
    }
}

@include media(479px) {
    .top-filter {
        .input-wrap {
            padding-right: 0;
            width: 100%;
        }
    }
}

@include media(399px) {
    .product-right {
        padding-left: 0;
    }
    .product-summery-wrap {
        .summery-box {
            padding: rem-calc(5px);
        }
    }
}

.discount-tag{
    color: #06C9A5;
  }