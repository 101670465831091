@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import "@/sass/app/index.scss";

.product-details-mid {
  margin: rem-calc(85px 0 0);
  .product-detail-title {
    margin-bottom: rem-calc(15px);
    background-color: lighten(map-get($colors, grey), 40);

    a {
      padding: rem-calc(15px 30px);
      display: inline-block;
      position: relative;
      @include font(primary, 16px, 24px, false);

      &.active:before {
        @include sudo(100%, 3px, left, 0, bottom, 0, brand);
      }
      &:hover:before {
        @include sudo(100%, 3px, left, 0, bottom, 0, brand);
      }
    }
  }
  .product-Summary {
    padding: rem-calc(20px 30px);
    @include font(false, 15px, 25px, false);
    border-bottom: 20px solid lighten(map-get($colors, grey), 40);

    h3 {
      padding-bottom: rem-calc(15px);
      @include font(false, 22px, 25px, medium);
    }
  }
  .product-attributes {
    padding: rem-calc(20px 30px);
    @include font(false, 15px, 25px, false);
    border-bottom: 20px solid lighten(map-get($colors, grey), 40);

    h3 {
      padding-bottom: rem-calc(15px);
      @include font(false, 22px, 25px, medium);
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        padding: rem-calc(12px 0);
        margin: 0;
        display: flex;
        border-top: 1px solid #ddd;
        .attribute-category {
          position: relative;
          width: 200px;
          @include font(false, 15px, 22px, false);
          &:after {
            content: ":";
            position: absolute;
            right: 20px;
            top: 0;
          }
        }
        .attribute-values {
          span {
            @include font(false, 14px, 19px, medium);
            display: block;
            i {
              font-style: normal;
            }
          }
        }
      }
    }
  }
  .product-description {
    padding: rem-calc(20px 30px);
    @include font(false, 15px, 25px, false);
    border-bottom: 20px solid lighten(map-get($colors, grey), 40);

    &::v-deep h3 {
      padding-bottom: rem-calc(15px);
      @include font(false, 22px, 25px, medium);
    }
    &::v-deep ul {
      li {
        list-style: disc;
      }
    }
  }

  .v-card__subtitle {
    padding: rem-calc(0 0 13px);
    @include font(false, 15px, 24px, medium);
  }
  .items {
    padding: 0;
    margin: 0;
    column-count: 2;
    column-gap: 40px;

    li {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: rem-calc(10px);
      @include font(grey, 14px, 22px, false);

      .item-content {
        width: calc(100% - 160px);
        color: map-get($colors, primary);
      }
    }
  }
  &::v-deep img {
    max-width: 100%;
  }
}

@include media(1639px) {
  .product-details-mid {
    margin: rem-calc(60px 0 0);
  }
}
@include media(lg) {
  .product-details-mid {
    .product-Summary {
      padding: rem-calc(20px 15px);
    }
    .product-description {
      padding: rem-calc(20px 15px);
    }

    .items {
      column-count: auto;
      column-gap: normal;
    }
  }
}
@include media(479px) {
  .product-details-mid {
    .items {
      li {
        .item-content {
          width: 100%;
        }
      }
    }
  }
}
