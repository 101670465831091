@import "app/index.scss";

.product-details-summery {
  max-width: 638px;
  padding: rem-calc(0 50px);

  .product-details-box {
    padding: rem-calc(0 35px 13px 0);
    margin-bottom: rem-calc(12px);
    position: relative;
    border-bottom: 1px solid lighten(map-get($colors, grey), 30);
    .v-btn {
      @include absolute(false, false, top, 0, right, 0);
      .v-icon {
        font-size: 18px;
        color: lighten(map-get($colors, grey), 28);
      }
    }
    .category {
      padding-bottom: rem-calc(6px);
      display: block;
      @include font(grey, 15px, 22px, light);
    }
    h1 {
      @include font(primary, 22px, 30px, medium);
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      padding-bottom: rem-calc(6px);
      @include font(grey, 15px, 20px, false);

      span {
        @include font(primary, 17px, 20px, semi_bold);

        &.current-Bid {
          @include font(brand, 22px, 22px, semi_bold);
        }
      }

      .all-bidders {
        text-decoration: underline;

        &:hover {
          color: map-get($colors, brand);
          text-decoration: none;
        }
      }
      &.availablity {
        position: relative;

        .sold-out {
          margin-top: -13px;
          @include absolute(120px, false, top, 50%, right, 0);
          transform: translateY(-50%);
        }
      }
    }
  }

  .time-holder {
    padding: rem-calc(14px);
    margin-bottom: rem-calc(14px);
    @include font(primary, 15px, 15px, false);
    background-color: lighten(map-get($colors, grey), 42);
    .time-left {
      width: 75px;
      flex-shrink: 0;
      .time-left-in {
        padding-top: rem-calc(5px);
      }
    }
    span {
      padding-right: 10px;
    }
    &::v-deep b {
      display: inline-block;
      @include font(false, 25px, 25px, semi_bold);
    }
  }

  .place-bid-wrap {
    padding: rem-calc(15px 20px);
    background-color: lighten(map-get($colors, grey), 42);

    .bid-label {
      padding-bottom: rem-calc(5px);
      @include font(grey, 15px, 20px, false);
    }

    .automatic-bid {
      padding-top: rem-calc(5px);
      a {
        margin-right: rem-calc(20px);
        display: inline-block;
        @include font(grey, 15px, 20px, false);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
          color: map-get($colors, brand);
        }
      }
      .v-icon {
        font-size: 20px;
      }
    }
  }

  .more-info {
    padding: rem-calc(5px 0 15px);
    a {
      text-decoration: underline;
      @include font(brand, 15px, 20px, false);

      &:hover {
        text-decoration: none;
      }
    }
  }

  &::v-deep .share-product {
    padding: rem-calc(0 30px);
    margin-top: rem-calc(40px);
    background-color: #f7f8f8;
    span {
      padding-right: rem-calc(10px);
    }
    .share-product-box {
      width: 100%;

      a {
        padding: rem-calc(0 8px);
        display: inline-block;
        border: none;
        i {
          @include font(false, 25px, 40px, false);
        }
      }
    }
  }
}

.autobid-popup {
  border-radius: 0;
  .autobid-wrap {
    padding: rem-calc(40px);
    position: relative;

    h3 {
      padding: rem-calc(0 0 16px);
      @include font(primary, 30px, 30px, medium);
    }
    .autobid-text {
      padding: rem-calc(0 0 22px);

      p {
        @include font(primary, 15px, 24px, false);
      }
    }
    .input-wrap {
      padding-bottom: rem-calc(20px);
    }

    .v-btn.place-bid-btn {
      height: rem-calc(40px);
      @include font(false, 15px, 30px, regular);
    }
    .v-btn.modal-close {
      height: rem-calc(40px);
      margin-right: rem-calc(5px);
      @include font(false, 15px, 30px, regular);
    }
  }
}

@include media(1639px) {
  .product-details-summery {
    max-width: 43%;
    padding: rem-calc(0 20px);

    .place-bid-wrap {
      padding: rem-calc(10px 15px);
    }
    .time-holder {
      padding: rem-calc(10px);
      @include font(primary, 14px, 14px, false);

      b {
        @include font(false, 20px, 20px, semi_bold);
      }
    }
  }
}

@include media(lg) {
  .product-details-summery {
    max-width: 50%;
    padding: rem-calc(0 15px 0 20px);

    .product-details-box {
      h1 {
        @include font(primary, 18px, 26px, medium);
      }
    }

    .time-holder {
      padding: rem-calc(10px);
      @include font(primary, 14px, 14px, false);

      b {
        @include font(false, 16px, 20px, semi_bold);
      }
    }
    .share-product {
      margin-top: rem-calc(7px);
    }
  }
}

@include media(md) {
  .product-details-summery {
    max-width: 50%;

    .product-details-box {
      padding-bottom: rem-calc(5px);
      margin-bottom: rem-calc(7px);
      h1 {
        @include font(primary, 16px, 22px, medium);
      }
    }
    ul {
      li {
        padding-bottom: rem-calc(5px);
        @include font(grey, 14px, 18px, false);

        span {
          @include font(primary, 15px, 18px, semi_bold);

          &.current-Bid {
            @include font(brand, 18px, 18px, semi_bold);
          }
        }
        &.availablity {
          .sold-out {
            margin-top: -10px;
            width: 100px;
          }
        }
      }
    }

    .time-holder {
      padding: rem-calc(5px 8px);
      strong {
        @include font(false, 16px, 16px, semi_bold);
      }
    }
  }
}

@include media(699px) {
  .product-details-summery {
    width: 100%;
    max-width: 100%;
    padding: rem-calc(30px 15px 0);

    .product-details-box {
      padding-bottom: rem-calc(7px);
      margin-bottom: rem-calc(10px);

      h1 {
        @include font(primary, 18px, 22px, medium);
      }
    }
    ul {
      li {
        padding-bottom: rem-calc(5px);
        @include font(grey, 15px, 20px, false);

        span {
          @include font(primary, 15px, 20px, semi_bold);

          &.current-Bid {
            @include font(brand, 20px, 28px, semi_bold);
          }
        }
      }
    }
  }
}

@include media(479px) {
  .product-details-summery {
    &::v-deep .share-product {
      padding: rem-calc(5px 15px);

      .share-product-box {
        a {
          padding: rem-calc(0 5px);
        }
      }
    }
    ul {
      li {
        &.availablity {
          .sold-out {
            margin-top: 0;
            width: 100px;
            position: static;
            transform: none;
            display: block;
          }
        }
      }
    }
  }
  .autobid-popup {
    .autobid-wrap {
      padding: rem-calc(20px 10px);

      h3 {
        padding: rem-calc(0 0 10px);
        @include font(primary, 22px, 24px, medium);
      }

      .v-btn.place-bid-btn {
        @include font(false, 14px, 20px, false);
      }
      .v-btn.modal-close {
        @include font(false, 14px, 20px, false);
      }
    }
  }
}
