@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import "@/sass/product.scss";
.sort-field-box {
  strong {
    @include font(primary, 15px, 25px, medium);
  }
  .sort-field {
    max-width: 180px;
    margin-left: rem-calc(8px);

    &.show {
      max-width: 100px;
    }

    &::v-deep .v-input {
      fieldset {
        border: 1px solid lighten(map-get($colors, grey), 35);
        border-radius: 0;
      }
      .v-input__slot {
        padding-right: 0 !important;
      }
      .v-select__selection,
      .v-label {
        @include font(primary, 14px, 18px, false);
      }
    }
  }
}
