@import "app/index.scss";
.fan-wrap {
  max-width: 600px;
  overflow: hidden;
  border-radius: 13px;
  position: relative;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.34);  
  background-color:#F1F3F7;

  .fan-top{
   position: relative;
    img{
       max-width: 100%;
    }
  }
  .fal-logo{
    img{width: 185px}
  }

}
.fan-contain{
    z-index: 1;
    padding: 30px 30px;
    color: #FFF;
    @include absolute(100%,100%,left,0,top,0);
    span{
       font-size: 17px;
       margin-bottom: 6px;
        background-color: #C077AA;
    }
}
.slogan{
    color: #002626;
    @include absolute(100%,false,left,0,bottom,15px);
    @include font(false, 45px, 50px, medium,);
    text-align: center;
}
.fan-container{
    padding: 0 35px;
    .input-wrap {
        padding-bottom: rem-calc(18px);
        &::v-deep .v-input {
          input {
            height: 60px;
            max-height: 60px;
            padding: 0;
            @include font(primary, 15px, 43px, false);
          }
        }
        &::v-deep .v-input__prepend-inner {
            height: 60px;
            margin-right: rem-calc(15px);
            padding-right: rem-calc(10px);
            border-right: 1px solid #ddd;
            display: flex;
            align-items: center;
        }
        
    }
}
.ss-logo{
    padding: 0 0 30px;
    img{width: 44%}
}
.information{
    margin: 0 0 20px;
    text-align: center;
    position: relative;
    &.gender-section{margin-top: 20px}
    &:after{
        @include sudo(100%, 2px, left, 0, top,50%, brand)
    }
    span{
        padding: 0 20px;
        color: #797979;
        @include font(false, 28px, 40px, false,);
        background-color: #F1F3F7;
        position: relative;
        z-index: 2;
    }
}
.male-female{
    padding-top:25px;
}
.gender-box{
    padding:12px 10px;
    @include border-radius(3px);
    background-color: #FFF;
    

    &.v-radio{
        margin: 0 15px !important;
    }
    &.v-item--active{
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);  
        img{
            &.gender-active{
                display: block !important;
            }
        }
    }
    &::v-deep .v-input--selection-controls__input{
        display: none !important;
    }
    img{
        width: 35px;
        &.gender-active{
            display: none;
            @include absolute(30px ,false,right,-23px,top,-25px);
        }
    }
    span{
        padding-left: 10px;
        color: #000;
        @include font(false, 16px, 22px, false,);
    }
}

.btn-container{
    padding: 40px 0 25px;
    .fan-btn{
        width: 100% !important;
        height: auto !important;
        padding:18px 10px !important;
        border-radius: 22px;
        border: 2px solid #FFF;
        @include font(false, 26px, 40px, false,);
        background: linear-gradient(90deg, #FF690F 0%, #FF993F 103.54%);
    }
}

@include media(1600px) {
    .watch-list-table {
        table {
            th,
            td {
                padding: rem-calc(10px);
            }
        }
    }
}


@include media(md) {

    .fan-wrap{
        margin-top: -50px;
    }
    
}



@include media(sm) {
    .fan-container {
        padding: 0 15px;
    }
   
    .slogan{
        bottom: 0;
        @include font(false, 30px, 36px, medium);
    }
    .btn-container{
        padding: 30px 0 25px;
        .fan-btn{
            padding:10px 10px !important;
        }
    }
}

@include media(479px) {
    .fan-container {
        padding: 0 10px;
        .fan-wrap {
            border-radius: 14px;
        }
        .input-wrap {
            &::v-deep .v-input {
              input {
                height: 43px;
                max-height: 43px;
              }
            }
            &::v-deep .v-input__prepend-inner {height: 43px}
        }
    }
    
    .information{
        margin: 0 0 5px;
        span{
            padding: 0 4px;
            @include font(false, 15px, 40px, false,);
        }
    }
    .male-female {
        padding-top: 0;
    }
    .slogan{
        @include font(false, 24px, 26px, medium);
    }
    .fan-wrap .fal-logo img {
        width: 125px;
    }
    .fan-contain span {
        font-size: 11px;
    }
    .fan-contain{
        padding: 15px 10px;
    }
    .ss-logo img {
        width: 40%;
    }
    .btn-container{
        .fan-btn{
            @include font(false, 16px, 30px, false);
        }
    }
    .slogan {
        bottom: 5px;
    }
    .ss-logo{
        padding: 0 0 10px;
    }
    .fan-container{
        .input-wrap {
            padding-bottom: rem-calc(8);
        }
    }
    .gender-box.v-radio{
        margin: 0 10px !important;
    }
    .information.gender-section {
        margin-top: 10px;
    }
     
}
