@import "app/index.scss";

.account-wrap {
    width: calc(100% - 375px);
    padding-left: rem-calc(30px);
}
.account-panel {
    padding: rem-calc(30px 15px);
}
.summery {
    padding: rem-calc(30px 35px);
    background-color: map-get($colors, site_bg);
    h2 {
        padding-bottom: rem-calc(10px);
        @include font(primary, 35px, 43px, medium);
    }
    p {
        @include font(false, 15px, 25px, false);
    }
}
.allowance {
    padding-top: rem-calc(28px);
}
.allowance-left {
    width: 68%;
    padding-top: rem-calc(28px);
    h3 {
        padding-bottom: rem-calc(7px);
        @include font(primary, 20px, 28px, regular);
        span {
            @include font(brand, 28px, 28px, semi_bold);
        }
    }
    .allowance-info {
        padding: rem-calc(13px 0);
        border-bottom: 1px solid lighten(map-get($colors, grey), 35);

        .allowance-text {
            @include font(grey, 17px, 22px, false);
        }
        .allowance-value {
            @include font(primary, 20px, 22px, medium);
            small {
                font-size: 14px;
                font-weight: map-get($font-weight, regular);
            }
        }
        a {
            text-decoration: underline;
        }
    }
    .points-container {
        padding: rem-calc(10px 15px);
        margin-top: rem-calc(30px);
        box-shadow: 2px 0 25px rgba(198, 174, 173, 0.22);
        border: 1px solid lighten(map-get($colors, grey), 40);
    }
    .points-wrap {
        .point-box {
            width: 32%;
            padding: rem-calc(15px);
            margin: rem-calc(10px 0);
            text-align: center;
            background-color: lighten(map-get($colors, grey), 47);
            border: 1px solid lighten(map-get($colors, grey), 20);
            &.v-item--active {
                border-color: map-get($colors, brand);
                background: map-get($colors, light);
                box-shadow: 0 0 18px #c7c7c7;
            }

            .point-quantity {
                display: block;
                @include font(grey, 16px, 22px, false);
                b {
                    @include font(primary, 17px, 16px, extra_bold);
                }
            }
            .quantity-price {
                @include font(brand, 18px, 22px, extra_bold);
            }
            .v-input--selection-controls__input {
                display: none;
            }
            .custom-quantity {
                padding-bottom: rem-calc(5px);
                .point-input {
                    width: 75px;
                    padding-right: rem-calc(5px);
                }
            }
        }
    }
    .membership {
        padding: rem-calc(40px 0 33px);

        .v-btn {
            width: 275px;
            height: 50px;
            @include font(primary, 18px, 22px, semi_bold);
        }
    }
    p {
        @include font(false, 15px, 24px, false);
        a {
            @include font(primary, 15px, 24px, medium);
            text-decoration: underline;
        }
    }
}
.allowance-right {
    padding-right: rem-calc(20px);
    width: 32%;
}
.rules {
    padding: rem-calc(20px);
    h3 {
        padding-bottom: rem-calc(13px);
        @include font(primary, 20px, 26px, semi_bold);
    }
    ul {
        padding: 0;
        margin: 0;

        li {
            @include font(false, 17px, 30px, false);
        }
    }
}
@include media(1639px) {
    .account-wrap {
        width: calc(100% - 275px);
        padding-left: rem-calc(30px);
    }
}

@include media(lg) {
    .account-wrap {
        width: 100%;
        padding-left: 0;
    }
}

@include media(md) {
    .allowance-left {
        width: 100%;
    }
}

@include media(sm) {
    .account-panel {
        padding: rem-calc(15px 10px);
    }
    .summery {
        padding: rem-calc(10px);
        h2 {
            padding-bottom: rem-calc(10px);
            @include font(false, 24px, 28px, false);
        }
    }
    .allowance {
        padding-top: 0;
    }
    .rules {
        padding: rem-calc(0);
    }

    .allowance-left {
        .points-wrap {
            .point-box {
                width: 47.5%;
            }
        }
    }
}

@include media(479px) {
    .allowance-left {
        .points-wrap {
            .point-box {
                width: 100%;
            }
        }
        .membership {
            .v-btn {
                width: 100%;
            }
        }
    }
}
