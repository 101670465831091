@import "app/index.scss";

.contact-panel {
    padding: rem-calc(70px 0);
    margin: 0;

    .contact-top {
        padding-bottom: rem-calc(55px);
        h3 {
            padding-bottom: rem-calc(27px);
            @include font(primary, 22px, 28px, medium);
        }
        p {
            @include font(primary, 15px, 26px, false);
        }
    }
    .contact-left {
        padding: 0;
        margin: 0;

        .contact-left-box {
            padding-bottom: rem-calc(50px);
            margin: 0;
            address {
                font-style: normal;
                @include font(primary, 17px, 32px, medium);
            }
            h4 {
                @include font(brand, 17px, 32px, medium);
            }
            a {
                @include font(primary, 17px, 32px, medium);
            }
        }
    }
    .contact-right {
        padding: rem-calc(25px);
        box-shadow: 2px 0 25px rgba(198, 174, 173, 0.22);
        h2 {
            padding-bottom: rem-calc(25px);
            margin: 0;
            @include font(false, 28px, 36px, semi_bold);
        }
        .input-wrap {
            padding-bottom: rem-calc(25px);
        }
        .v-btn {
            &.contact-btn {
                width: 197px;
                height: 50px;
                @include font(false, 20px, 26px, medium);
            }
        }
    }
}

@include media(md) {
    .contact-panel {
        padding: rem-calc(50px 5px);

        .contact-top {
            padding-bottom: rem-calc(30px);
        }
        .contact-left {
            .contact-left-box {
                padding-bottom: rem-calc(30px);
            }
        }
        .contact-right {
            .v-btn {
                &.contact-btn {
                    height: 50px;
                }
            }
        }
    }
}
@include media(sm) {
    .contact-panel {
        padding: rem-calc(30px 5px);
        .contact-left {
            .contact-left-box {
                address {
                    br {
                        display: none;
                    }
                }
            }
        }
    }
}
