@import "app/index.scss";

.account-wrap {
    width: calc(100% - 375px);
    padding-left: rem-calc(30px);
}
.account-panel {
    padding: rem-calc(30px 15px);
}
.account-personal-info {
    h3 {
        padding-bottom: rem-calc(6px);
        margin-bottom: rem-calc(15px);
        @include font(primary, 20px, 26px, medium);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        .change-password {
            @include font(brand, 15px, 22px, regular);
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
.profile-info-form {
    .input-wrap {
        padding-bottom: rem-calc(20px);
    }
}
.account-panel {
    .profile-info-form {
        .save-info-btn {
            width: 200px;
            height: 50px;
            @include font(false, 18px, 26px, semi_bold);
        }
    }
}

.multi-image-uploader {
    padding: rem-calc(15px);
    margin: rem-calc(20px 0 35px);
    border: 1px dashed lighten(map-get($colors, grey), 30);

    &::v-deep h3 {
        padding: rem-calc(0 0 5px);
        @include font(primary, 15px, 22px, medium);
    }
    .drag-text,
    .browse-text {
        @include font(primary, 12px, 14px, medium);
    }
    &::v-deep div[edit-upload="images-uploader-edit-2"] {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .preview-image {
            border-radius: 0;
            height: 110px;
        }
        .image-overlay {
            border-radius: 0;
            z-index: 1;
        }
        .image-container {
            height: 145px;
            width: 165px;
        }
        .image-list-container {
            padding-left: rem-calc(20px);
            margin: 0;
            max-width: 100%;
            width: calc(100% - 165px);
            min-height: 50px;

            .image-list-item {
                width: 100px;
                height: 90px;
                margin: rem-calc(5px);
                .centered {
                    width: 99%;
                    height: 99%;
                    display: flex;
                    .show-img {
                        max-width: 100%;
                        max-height: 100%;
                        margin: auto;
                    }
                }
            }
            .image-highlight {
                border: 1px solid map-get($colors, brand);
            }
        }
    }
}

.filter-btn {
    position: fixed;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
}
.drawer-toggle {
    left: 255px;
    top: 24px;
    z-index: 9;
    position: fixed;
    background: #fff;
}

@include media(1639px) {
    .account-wrap {
        width: calc(100% - 275px);
        padding-left: rem-calc(30px);
    }
}

@include media(lg) {
    .account-wrap {
        width: 100%;
        padding-left: 0;
    }
}

@include media(sm) {
    .multi-image-uploader {
        &::v-deep div[edit-upload="images-uploader-edit-2"] {
            .image-list-container {
                .image-list-item {
                    width: 55px;
                    height: 55px;
                }
            }
        }
    }
}

@include media(479px) {
    .multi-image-uploader {
        &::v-deep h3 {
            text-align: center;
        }
        &::v-deep div[edit-upload="images-uploader-edit-2"] {
            justify-content: center;
            .image-list-container {
                width: 100%;
                justify-content: center;
                padding: 5px 0 0;
                .image-list-item {
                    width: 55px;
                    height: 55px;
                }
            }
        }
        &::v-deep small {
            display: block;
            text-align: center;
        }
    }
}
