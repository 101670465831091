@import "app/index.scss";

.product_list_items {
  width: 100% !important;
  flex: 100% !important;
  max-width: 100% !important;
  .product-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .product-image {
      position: relative;
      width: 24%;
      border-radius: 10px 0 0 10px !important;
    }
    .product-list-mid {
      width: 46%;
      padding: 0 42px;
      position: relative;
      .product-sub-title {
        padding: 0;
      }
      .product-title {
        line-height: 25px;
        padding-bottom: 22px;
      }
      .time-and-bid-wrap {
        max-width: 295px;
      }
      .product-location {
        margin-top: 12px;
      }
    }
    .product-bottom {
      width: 30%;
      padding: 0;
      text-align: center;
      .product-top-labels {
        padding: 0 0 30px;
        position: static;

        .v-btn:not(:last-child) {
          margin-right: rem-calc(33px);
        }
      }
      .product-price-box {
        padding: 0;
        .product-price {
          padding-right: 0;
        }
      }
    }

    &::after {
      @include sudo(1px, 90%, right, 30%, top, 50%, false);
      background-color: #dfdfdf;
      transform: translateY(-50%);
    }
  }
}

.share-product-wrap {
  position: relative;

  .share-product {
    width: 30px;
    border-radius: 0 !important;
  }

  &.share-product-grid {
    width: 30px;
    height: 20px;
    cursor: pointer;
    text-align: center;
    position: relative;

    .mdi-share {
      font-size: 18px;
      line-height: 20px;
      margin-top: -6px;
    }
    &::after {
      @include sudo(0, 0, left, 100%, top, 50%, false);
      transform: translateY(-50%);
      border-top: 4px solid transparent;
      border-left: 8px solid #fff;
      border-bottom: 4px solid transparent;
    }
  }

  .share-productIn {
    max-width: none;
    padding-left: 10px;
    display: none;
    z-index: 3;
    @include absolute(false, false, left, 100%, top, 0);
  }

  &:hover .share-productIn {
    display: block;
  }
}

@include media(1639px) {
  .product_list_items {
    .product-card {
      .product-image {
        width: 30%;
      }
      .product-list-mid {
        width: 40%;
        padding: 0 20px;
        position: relative;
        .product-sub-title {
          padding: 0;
        }
        .product-title {
          padding-bottom: 15px;
          @include truncate-clamp(2);
        }
      }
    }
  }
}

@include media(md) {
  .product_list_items {
    .product-card {
      .product-image {
        width: 30%;
      }
      .product-list-mid {
        width: 45%;
        padding: 0 15px;
        .product-title {
          padding-bottom: rem-calc(10px);
          line-height: 20px;
        }
      }
      .product-bottom {
        width: 25%;
        .product-top-labels {
          padding: rem-calc(20px 0);
          .v-btn:not(:last-child) {
            margin-right: rem-calc(15px);
          }
        }
      }
      &::after {
        right: 25%;
      }
    }
  }
}

@include media(699px) {
}
