@import "app/index.scss";

.review-ratings-wrap {
  padding: 0;
  margin: 0;
  .writing-info {
    padding: rem-calc(22px 0 14px);
    @include font(grey, 14px, 22px, false);

    .v-icon {
      font-size: 18px;
    }
  }

  h2 {
    padding: rem-calc(10px 30px);
    @include font(primary, 17px, 26px, medium);
    background-color: lighten(map-get($colors, grey), 40);
  }

  .rating-area {
    width: 100%;
    padding-top: rem-calc(70px);

    .avarage-rating {
      width: 380px;
      position: relative;
      strong {
        padding-right: rem-calc(8px);
        @include font(primary, 35px, 60px, medium);
      }
      span {
        @include font(grey, 12px, 12px, false);
      }
    }
    .rating-items-box {
      width: 40%;
    }
    .rating-items {
      .rating-items-left {
        width: 150px;
      }
      .rating-items-right {
        span {
          width: 35px;
          @include font(grey, 12px, 12px, false);
        }
      }
    }
  }
}

.review-ratings-box {
  padding: rem-calc(0 30px);
}

.review-commant-wrap {
  padding: rem-calc(25px 0 0);
}
.review-commant-item {
  &:not(:last-child) {
    padding-bottom: rem-calc(25px);
    margin-bottom: rem-calc(25px);
    border-bottom: 1px solid lighten(map-get($colors, grey), 43);
  }
}
.review-commant-left {
  width: 230px;
  .v-avatar {
    margin-bottom: rem-calc(10px);
  }

  span {
    display: block;
    @include font(false, 14px, 18px, false);
  }
}
.review-commant-right {
  width: calc(100% - 234px);
  p {
    @include font(grey, 14px, 22px, false);
  }
  .review-image {
    padding: rem-calc(25px 0 10px);

    .review-image-box {
      width: 80px;
      height: 80px;
      margin: rem-calc(0 7px 7px 0);
      position: relative;
      border: 1px solid #ddd;
      img {
        max-width: 100%;
        max-height: 100%;
        @include center(false, false);
      }
    }
  }
  .thumbs {
    padding: rem-calc(8px 0 0);

    .v-btn {
      width: 28px;
      min-width: 28px;
      height: 26px;
      &:not(:last-child) {
        margin-right: rem-calc(12px);
      }
      .v-icon {
        font-size: 18px;
      }
    }
  }
}

@include media(lg) {
  .review-ratings-wrap {
    h2 {
      padding: rem-calc(10px 15px);
    }
    .rating-area {
      .avarage-rating {
        width: 270px;
      }
      .rating-items-box {
        width: 60%;
      }

      .rating-items {
        .rating-items-left {
          width: 170px;
        }
      }
    }
    .review-ratings-box {
      padding: rem-calc(0 15px);
    }
  }
}

@include media(md) {
  .review-ratings-wrap {
    .rating-area {
      width: 100%;
      max-width: 100%;
      padding-bottom: rem-calc(50px);
      order: 1;
      .avarage-rating {
        width: 230px;
      }
      .rating-items-box {
        width: 57%;
      }
      .rating-items {
        .rating-items-left {
          width: 105px;
        }
      }
    }
  }

  .review-commant-left {
    width: 160px;
  }
  .review-commant-right {
    width: calc(100% - 164px);
  }
}

@include media(sm) {
  .review-ratings-wrap {
    .rating-area {
      padding: rem-calc(0);
      .rating-items-box {
        width: 100%;
        padding: rem-calc(40px 0 0);
      }

      .rating-items {
        .rating-items-left {
          width: 120px;
        }
      }
    }
  }
}

@include media(479px) {
  .review-ratings-wrap {
    .review-area {
      .review-box {
        .v-btn {
          margin-top: rem-calc(10px);
          width: 100%;
        }
      }
    }
  }
  .review-commant-left {
    padding-bottom: rem-calc(12px);
  }
  .review-commant-left,
  .review-commant-right {
    width: 100%;
  }
}
