@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import "@/sass/app/index.scss";
.address-list {
  &::v-deep table {
    border: 1px solid lighten(map-get($colors, grey), 35);

    tr {
      th {
        font-size: 14px !important;
      }
      td {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        @include font(primary, 15px, 22px, false);

        .v-label {
          @include font(primary, 15px, 22px, false);
        }
      }
    }
  }
}
