@import "app/index.scss";
.proposal-modal {
    z-index: 11;
    background-color: #fff;
    @include absolute(1040px, false, right, 0, bottom, 0);
    height: 75%;
    border: 1px solid #e8e8e8;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
    position: fixed;
    overflow: hidden;

    .title-bar {
        padding: rem-calc(7px 10px);
        background-color: #525456;
        position: relative;

        h6 {
            padding: rem-calc(0 25px);
            text-align: center;
            @include font(light, 14px, 20px, regular);
        }

        .close {
            @include absolute(false, false, right, 5px, top, 50%);
            transform: translateY(-50%);
            cursor: pointer;
        }
        .hideChat {
            @include absolute(false, false, left, 5px, top, 50%);
            transform: translateY(-50%);
            cursor: pointer;
            display: none;
        }
    }

    .proposal-layout {
        height: calc(100% - 38px);
    }
    .loader {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(241, 243, 246, 0.9);

        .v-progress-linear {
            width: 200px;
        }
    }
}

.proposal-sidebar {
    max-width: 285px;
    height: 100%;
    flex-shrink: 0;
    transition: all 400ms;
    position: relative;
    border-right: 1px solid lighten(map-get($colors, grey), 35);

    .proposal-title {
        height: 39px;
        padding: rem-calc(0 15px);
        @include font(primary, 16px, 24px, medium);
        border-bottom: 1px solid lighten(map-get($colors, grey), 35);
    }
    .buyer-wrap {
        max-height: calc(100% - 39px);
        overflow-y: auto;
        overflow-x: hidden;
        // @include scroll-y(6px);
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid lighten(map-get($colors, grey), 35);
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: lighten(map-get($colors, grey), 30);
        }
        &::-webkit-scrollbar-thumb:hover {
            background: map-get($colors, brand);
        }

        ul {
            li {
                padding: rem-calc(8px 10px);
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid lighten(map-get($colors, grey), 35);

                .buyer-name-in {
                    h3 {
                        @include truncate-clamp(1);
                        @include font(false, 15px, 15px, regular);
                    }
                    .message {
                        display: block;
                        @include truncate-clamp(1);
                        @include font(primary, 14px, 14px, false);
                    }
                    .date {
                        @include font(grey, 12px, 12px, false);
                    }
                    .accepted {
                        padding-left: rem-calc(5px);
                        display: inline-block;
                        @include font(grey, 9px, 12px, false);
                        color: map-get($colors, brand);
                    }
                    .progress {
                        padding-left: rem-calc(5px);
                        display: inline-block;
                        @include font(grey, 9px, 12px, false);
                        color: map-get($colors, grey);
                    }
                    .ordered {
                        padding-left: rem-calc(5px);
                        display: inline-block;
                        @include font(grey, 9px, 12px, false);
                        color: map-get($colors, silver_tree);
                    }
                    .canceled {
                        padding-left: rem-calc(5px);
                        display: inline-block;
                        @include font(grey, 9px, 12px, false);
                        color: #ff5722;
                    }
                }

                &.active,
                &:hover {
                    background-color: lighten(
                        map-get($colors, silver_tree),
                        49
                    );
                    .buyer-name-in {
                        h3 {
                            color: map-get($colors, silver_tree);
                        }
                    }
                }
            }
        }
    }
}

.proposal-content {
    transition: all 400ms;
    position: relative;
    .proposal-content-in {
        height: calc(100% - 106px);
        padding: rem-calc(8px 0 100px);
    }
    .proposal-top {
        height: auto;

        @include font(primary, 16px, 24px, medium);
        border-bottom: 1px solid lighten(map-get($colors, grey), 35);

        .buyer-info {
            padding: rem-calc(5px 65px 5px 15px);
            position: relative;
            border-bottom: 1px solid lighten(map-get($colors, grey), 35);
            h3 {
                @include truncate-clamp(1);
                @include font(silver_tree, 16px, 16px, medium);
            }
            span {
                @include font(grey, 12px, 12px, false);
                display: block;
            }
            .cancel {
                padding: rem-calc(3px 10px 3px 5px);
                @include font(primary, 12px, 12px, false);
                @include absolute(false, false, right, 15px, top, 50%);
                transform: translateY(-50%);
                border: 1px solid lighten(map-get($colors, grey), 35);
                border-radius: 20px;
                background-color: lighten(map-get($colors, grey), 45);
                @include transition(all, 300ms);

                &:hover {
                    background-color: lighten(map-get($colors, grey), 30);
                }
            }
        }
    }
}

.chat-wrap {
    height: 100%;
    padding: rem-calc(15px 20px 0);
    overflow-y: auto;
    overflow-x: hidden;
    // @include scroll-y(6px);
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid lighten(map-get($colors, grey), 35);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: lighten(map-get($colors, grey), 30);
    }
    &::-webkit-scrollbar-thumb:hover {
        background: map-get($colors, brand);
    }

    .chat-item {
        padding: rem-calc(0 0 15px);
        .chat-info {
            padding-bottom: rem-calc(5px);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @include font(grey, 13px, 14px, false);
            .time-wrap {
                display: flex;
                padding-right: rem-calc(5px);
                .time {
                    padding-right: rem-calc(5px);
                }
            }
        }

        .chat-text {
            display: flex;
            justify-content: flex-end;

            .chat-box {
                padding: rem-calc(15px);
                background-color: #e1f7f0;
                @include border-radius(5px);
                text-align: right;
                @include font(false, 15px, 24px, false);
                span {
                    color: #5eb799;
                }
            }
        }
        &.left {
            .chat-info {
                justify-content: flex-start;
                span {
                    padding-left: rem-calc(5px);
                }
                .time-wrap {
                    order: 2;
                    .time {
                        order: 2;
                    }
                }
            }
            .chat-text {
                justify-content: flex-start;

                .chat-box {
                    background-color: #f4f4f4;
                    text-align: left;
                    p {
                        span {
                            padding: rem-calc(2px 5px);
                            color: #fff;
                            display: inline-block;
                            background-color: #43b48e;
                            @include border-radius(5px);
                        }
                    }
                    .btn-wrap {
                        padding-top: rem-calc(20px);
                        .accept.v-btn {
                            margin-right: rem-calc(12px);
                            color: map-get($colors, light);
                            background-color: #43b48e;
                        }
                        .send-proposal.v-btn {
                            color: map-get($colors, light);
                            background-color: #363b44;
                            position: relative;
                            &:after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-left: 10px solid transparent;
                                border-right: 10px solid transparent;
                                border-top: 10px solid #363b44;
                                position: absolute;
                                left: 50%;
                                bottom: -9px;
                                box-shadow: none;
                                transform: translateX(-50%);
                                transition: all 300ms;
                            }
                            &:hover:after {
                                border-top: 10px solid #464a53;
                            }
                        }
                    }
                }
            }
        }
    }

    .proposal-form {
        h5 {
            padding-bottom: rem-calc(5px);
            @include font(primary, 15px, 20px, regular);
        }
        .proposal-form-in {
            width: 100%;
            padding: rem-calc(8px 15px);
            background-color: #d9dbe1;
            border: 1px solid #d2d2d5;
            box-shadow: 0 2px 5px #d2d2d5;

            @include absolute(false, false, right, 0, bottom, 0);

            .form-box {
                position: relative;
                border-radius: 7px 0 0 7px;
                overflow: hidden;

                .input-wrap {
                    width: calc(100% - 170px);
                    padding: 0;
                }

                .submit-proposal {
                    background-color: map-get($colors, brand);
                    @include absolute(170px, 43px, right, 0, top, 0);
                    @include font(light, 16px, 24px, false);
                    @include border-radius(0 5px 5px 0);
                }
            }
        }
    }
}

.proposal-product-wrap {
    padding: rem-calc(0 15px);
    box-shadow: 0 1px 4px #ddd;
    a {
        &:hover {
            h2 {
                color: map-get($colors, brand);
            }
        }
    }
}
.proposal-product {
    .product-image {
        width: 55px;
        padding-top: rem-calc(5px);
        margin-right: rem-calc(15px);
        flex-shrink: 0;

        img {
            width: 100%;
            border: 1px solid lighten(map-get($colors, grey), 35);
        }
    }
    .product-desc {
        h2 {
            @include font(primary, 13px, 14px, regular);
            @include truncate-clamp(1);
        }
        .price {
            padding-top: rem-calc(5px);
            @include font(brand, 14px, 14px, semi_bold);
        }
        .quantity {
            .quantity-title {
                padding-right: rem-calc(5px);
                @include font(grey, 13px, 14px, regular);
            }
            .quantity-holder {
                width: 90px;
            }

            &::v-deep .quantity-holder {
                .v-input__slot {
                    padding: 0;
                }
                input {
                    max-height: 20px;
                    @include font(primary, 14px, 20px, regular);
                }
                .v-icon {
                    font-size: 18px;
                }
            }
        }
        .product-desc-in {
            padding: rem-calc(6px 0);
        }
    }
}
.total-holder {
    width: 150px;
    flex-shrink: 0;
    padding-left: rem-calc(10px);
    border-left: 1px solid lighten(map-get($colors, grey), 35);

    .total-qty {
        padding: rem-calc(3px 0);
        display: block;
        @include font(grey, 13px, 14px, regular);
        b {
            @include font(primary, 14px, 14px, semi_bold);
        }
    }
    .total-price {
        @extend .total-qty;
    }
}

.empty-chat {
    min-width: 255px;
    text-align: center;
    transform: translate(-50%, -50%);
    @include font(grey, 18px, 26px, semi_bold);
    @include absolute(false, false, left, 50%, top, 50%);
}

@include media(1399px) {
    .proposal-modal {
        height: 88%;
        width: 70%;

        .title-bar {
            h6 {
                @include font(false, 14px, 20px, regular);
            }
        }
    }
}

@include media(lg) {
    .proposal-modal {
        height: 85%;
    }
}

@include media(1023px) {
    .proposal-sidebar {
        max-width: 100%;
        left: 0;
        border-right: 0;
    }
    .proposal-content {
        width: 100%;
        position: absolute;
        background-color: #fff;
        right: -100%;
        bottom: 0;
        height: calc(100% - 34px);
        visibility: hidden;
    }
    .show-mobile-chat {
        .proposal-sidebar {
            left: -200px;
        }
        .proposal-content {
            right: 0;
            visibility: visible;
        }
    }
    .proposal-modal {
        .title-bar {
            .hideChat {
                display: block;
            }
        }
    }
}

@include media(md) {
    .proposal-modal {
        .title-bar {
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            h6 {
                @include font(false, 12px, 14px, regular);
            }
        }
        .proposal-content {
            height: calc(100% - 45px);
        }
    }
}

@include media(sm) {
    .proposal-modal {
        width: 100%;
    }
}

@include media(479px) {
    .proposal-modal {
        height: 100%;
    }
    .proposal-product {
        .product-desc {
            flex-wrap: wrap;
            .product-desc-in {
                padding-bottom: 0;
            }
            .total-holder {
                width: 100%;
                padding: rem-calc(0 0 3px);
                border: none;
                .total-qty {
                    padding-right: 10px;
                    display: inline-block;
                }
            }
        }
    }

    .chat-box {
        .btn-wrap {
            .accept.v-btn {
                margin: 0 0 10px !important;
                width: 100%;
            }
            .send-proposal.v-btn {
                width: 100%;
            }
        }
    }

    .proposal-content {
        .proposal-content-in {
            height: calc(100% - 130px);
        }
        .chat-wrap {
            .proposal-form {
                .proposal-form-in {
                    width: 100%;
                    padding: rem-calc(7px 10px);
                    .form-box {
                        .input-wrap {
                            width: calc(100% - 115px);
                        }
                        .submit-proposal {
                            width: 115px;
                            @include font(false, 14px, 14px, false);
                        }
                    }
                }
            }
        }
    }
}
