@import "app/index.scss";

.product-details-summery {
  max-width: 638px;
  padding: rem-calc(0 50px);

  .product-details-box {
    padding-bottom: rem-calc(9px);
    position: relative;
    .v-btn {
      @include absolute(false, false, top, 0, right, 0);
      .v-icon {
        font-size: 18px;
        color: lighten(map-get($colors, grey), 28);
      }
    }
    .category {
      padding-bottom: rem-calc(6px);
      display: block;
      @include font(grey, 15px, 22px, light);
    }
    h1 {
      @include font(primary, 22px, 30px, medium);
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      padding-bottom: rem-calc(9px);
      @include font(grey, 15px, 20px, false);

      span {
        @include font(primary, 17px, 20px, false);
      }
    }
  }
  .rating {
    padding: rem-calc(0 0 9px);
    @include font(primary, 14px, 20px, false);
  }
  .availablity {
    position: relative;
    @include font(grey, 15px, 15px, false);

    .pieces {
      padding-left: rem-calc(10px);
      display: inline-block;
      color: #74788d;
    }

    b {
      padding-left: rem-calc(13px);
      display: inline-block;
      color: map-get($colors, primary);
      font-weight: map-get($font-weight, regular);
    }
    .sold-out {
      margin-top: -50px;
      @include absolute(120px, false, top, 50%, right, 0);
      transform: translateY(-50%);
    }
  }
  .more-info {
    padding-top: rem-calc(15px);
    a {
      text-decoration: underline;
      @include font(brand, 15px, 20px, false);

      &:hover {
        text-decoration: none;
      }
    }
  }
  .price {
    padding: rem-calc(34px 0 40px);
    line-height: rem-calc(35px);
    display: flex;
    align-items: flex-end;
    .price-payable {
      @include font(brand, 32px, 35px, semi_bold);
    }
    .price-orginal {
      padding-left: rem-calc(14px);
      display: inline-block;
      @include font(grey, 15px, 30px, false);
    }
    .price-offer {
      padding-left: rem-calc(4px);
      display: inline-block;
      @include font(primary, 15px, 30px, false);
    }
  }
  .quantity-title {
    width: 100px;
    @include font(grey, 15px, 20px, false);
  }
  .emi-holder {
    padding: 7px 10px;
    margin-top: 25px;
    border: 1px dashed #ccc;
    &::v-deep .v-input {
      .v-icon,
      .v-label {
        color: #000;
        font-weight: 600;
      }
    }
  }
  .btn-holder {
    padding-top: rem-calc(50px);
    .btn-box {
      margin: 3px;
      &::v-deep .v-btn {
        height: 45px;

        @include font(false, 16px, 20px, false);
      }
    }
  }
  &::v-deep .share-product {
    padding: rem-calc(0 30px);
    margin-top: rem-calc(40px);
    background-color: #f7f8f8;
    span {
      padding-right: rem-calc(10px);
    }
    .share-product-box {
      width: 100%;

      a {
        padding: rem-calc(0 8px);
        display: inline-block;
        border: none;
        i {
          @include font(false, 25px, 40px, false);
        }
      }
    }
  }
}

@include media(1639px) {
  .product-details-summery {
    max-width: 43%;
    padding: rem-calc(0 20px);
  }
}
@include media(lg) {
  .product-details-summery {
    width: 50%;
    max-width: 100%;
    padding: rem-calc(0 15px 0 20px);
    .product-details-box {
      h1 {
        @include font(primary, 18px, 26px, medium);
      }
    }
    .btn-holder {
      padding-top: rem-calc(20px);
    }
    .price {
      padding: rem-calc(30px 0);
    }
    &::v-deep .share-product {
      padding: rem-calc(0 10px);
      margin-top: rem-calc(7px);
      .share-product-box {
        a {
          padding: rem-calc(0 5px);
        }
      }
    }
  }
}
@include media(md) {
  .product-details-summery {
    .btn-holder {
      .btn-box {
        width: 48%;
        &.bargain {
          width: 100%;
        }
      }
    }

    .availablity {
      .sold-out {
        width: 90px;
      }
    }
  }
}
@include media(699px) {
  .product-details-summery {
    width: 100%;
    padding: rem-calc(40px 15px 0);

    .btn-holder {
      width: 100%;
      padding: 5px 10px;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 7;
      background-color: #fff;
      box-shadow: 0 0 5px rgba(0,0,0,0.2);
      .btn-box {
        width: 32%;
        &.bargain {
          width: 32%;
        }
      }
    }
  }
}
@include media(479px) {
  .product-details-summery {
    .availablity {
      .sold-out {
        width: 90px;
        margin: 0;
        position: static;
        transform: none;
        display: block;
      }
    }
  }
}

.discount-tag {
  @include font(silver_tree, 17px, 22px, semi_bold);
}
