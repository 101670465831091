@import "app/index.scss";
.campaign-tbl {
  border: 1px solid lighten(map-get($colors, grey), 37);
}
.campaign-tbl-item {
  &:not(:last-child) {
    border-bottom: 1px solid lighten(map-get($colors, grey), 37);
  }
  > div {
    min-height: 50px;
    padding: 5px;
    // flex-grow: 0;
    display: flex;
    align-items: center;
    @include font(primary, 13px, 22px, false);

    &:not(:last-child) {
      border-right: 1px solid lighten(map-get($colors, grey), 37);
    }
  }
  &.tbl-header {
    div {
      @include font(primary, 13px, 22px, bold);
    }
  }
  .selected {
    width: 6.6%;
  }
  .sku {
    width: 9.5%;
  }
  .image {
    width: 6.5%;
    img {
      width: 57px;
      border: 1px solid lighten(map-get($colors, grey), 37);
    }
  }
  .name {
    width: 12.5%;
    h5 {
      @include font(primary, 13px, 16px, regular);
    }
  }
  .cat {
    width: 9.5%;
  }
  .price {
    width: 9.5%;
  }
  .cam-price {
    width: 10.5%;
  }
  .c-stock {
    width: 9.5%;
  }
  .r-stock {
    width: 9.5%;
  }
  .actions {
    width: 9.5%;
    a {
      padding: 0 5px;
      &:hover {
        .v-icon.v-icon {
          color: map-get($colors, brand);
        }
      }
    }
  }
}

.table-btn {
  border: 1px solid lighten(map-get($colors, grey), 37);
}
