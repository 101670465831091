@import "app/index.scss";

.account-wrap {
    width: calc(100% - 375px);
    padding-left: rem-calc(30px);
}
.account-panel {
    padding: rem-calc(30px 15px);
}
.notification-top {
    padding-bottom: rem-calc(15px);
    h2 {
        @include font(primary, 25px, 35px, medium);

        span {
            @include font(grey, 15px, 22px, regular);
        }
    }
}
.notifi-check {
    &::v-deep .v-input--selection-controls__input {
        margin: 0 !important;
    }
}
.notification-wrap {
    padding: 0;
    margin: 0;

    li {
        padding-bottom: rem-calc(10px);
        .notification-in {
            padding: rem-calc(10px 15px);
            cursor: pointer;
            background-color: lighten(map-get($colors, grey), 45);
            border: 1px solid lighten(map-get($colors, grey), 40);
            box-shadow: 0 0 3px #ddd;
            &.read{background-color: darken(map-get($colors, light), 1);}
        }

        h3 {
            @include font(false, 15px, 22px, semi_bold);
        }
        p {
            @include font(false, 14px, 22px, false);
        }
    }
}

@include media(1639px) {
    .account-wrap {
        width: calc(100% - 275px);
        padding-left: rem-calc(30px);
    }
}
@include media(lg) {
    .account-wrap {
        width: 100%;
        padding-left: 0;
    }
}
@include media(767px) {
}
@include media(sm) {
}
