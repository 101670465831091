@import "app/index.scss";

.account-wrap {
  width: calc(100% - 375px);
  padding-left: rem-calc(30px);
}
.account-panel {
  padding: rem-calc(30px 15px);
}
.account-panel-top {
  padding-bottom: rem-calc(15px);
  h2 {
    padding-right: rem-calc(10px);
    @include font(primary, 25px, 35px, medium);

    span {
      @include font(grey, 15px, 22px, regular);
    }
  }
}
.filter-bar {
  padding-bottom: rem-calc(15px);
  .input-wrap {
    width: 22%;
    padding: rem-calc(0 15px 10px 0);
  }
}
.account-panel-table {
  table {
    width: 100%;
    padding: 0;
    margin-bottom: rem-calc(13px);
    border: 1px solid #ccc;
    border-collapse: collapse;

    th {
      height: 50px;
      padding: rem-calc(10px);
      text-align: left;
      @include font(primary, 16px, 19px, semi_bold);
      background-color: lighten(map-get($colors, grey), 45);
      border-bottom: 1px solid lighten(map-get($colors, grey), 35);

      .order-price {
        width: 160px;
      }

      .order-number {
        display: block;
        @include font(primary, 13px, 19px, semi_bold);
      }
      .order-date {
        width: 300px;
        display: block;
        @include font(grey, 13px, 19px, regular);
      }
      .status {
        width: 140px;
        display: block;
      }
      .winning-auction {
        width: 155px;
        display: block;
      }
      .order-item-price {
        width: 145px;
        display: block;
      }
    }

    td {
      padding: rem-calc(10px);
      text-align: left;
      border-bottom: 1px solid lighten(map-get($colors, grey), 30);
      @include font(false, 13px, 20px, false);

      .my-product-title {
        max-width: 270px;
        padding-left: rem-calc(8px);
      }

      .product {
        @include truncate-clamp(2);
        margin-bottom: rem-calc(7px);
        @include font(primary, 13px, 14px, semi_bold);
      }

      .payment-method {
        @include font(primary, 13px, 20px, semi_bold);

        strong {
          font-weight: map-get($font-weight, medium);
        }
      }
      .address {
        padding: rem-calc(3px 0 0);
        font-style: normal;
        @include font(primary, 13px, 20px, regular);

        h4 {
          padding: rem-calc(0 0 3px);
          display: block;
          font-weight: map-get($font-weight, semi_bold);
        }
        span {
          display: block;
        }
      }

      .details {
        padding-top: rem-calc(3px);
        display: inline-block;
        text-decoration: underline;
        @include font(brand, 14px, 20px, false);
      }

      .type {
        height: 20px;
      }
      .qty {
        margin-right: rem-calc(25px);
      }
      .order-mony {
        @include font(brand, 15px, 20px, medium);
      }
      .last-price {
        @include font(false, 15px, 20px, medium);
      }

      &.border-right {
        border-right: 1px solid lighten(map-get($colors, grey), 30);
      }
      &::v-deep .link-btn {
        width: 112px;
        padding: 0;
        @include font(brand, 15px, 20px, medium);
      }
      .bargain-product-img {
        &::v-deep .v-image {
          border: 1px solid lighten(map-get($colors, grey), 30);
        }
      }
    }
  }

  .account-pagination {
    padding-top: rem-calc(20px);
  }
}

.order-product-wrap {
  padding: rem-calc(10px 0);
  margin-bottom: rem-calc(15px);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
  border: 1px solid lighten(map-get($colors, grey), 35);
  .order-date {
    @include font(grey, 13px, 18px, light);
  }

  .column {
    padding: rem-calc(2px 10px);

    .font-15 {
      @include font(false, 15px, 18px, false);
    }
  }
  .order-price {
    &::v-deep b {
      color: map-get($colors, brand);
    }
  }
  .column-6 {
    width: 100%;
  }

  /*-----design for my-auction page mobile*/
  .top-section {
    width: 100%;
    .au-top-first {
      width: 45%;
    }
    .au-top-second {
      width: 35%;
    }
    .au-top-third {
      width: 20%;
    }
  }
  .btm-section {
    width: 100%;
    .au-top-first {
      width: 45%;
    }
    .au-top-second {
      width: 35%;
    }
    .au-top-third {
      width: 20%;
    }
  }
  .btm-section {
    width: 100%;
  }

  .product-img {
    width: 80px;
    border: 1px solid lighten(map-get($colors, grey), 30);
  }
  .product-info {
    h4 {
      margin-bottom: rem-calc(5px);
      @include font(primary, 16px, 20px, regular);
      @include truncate-clamp(2);
    }
    .qty {
      @include font(grey, 13px, 16px, regular);
    }
  }
  &::v-deep .link-btn {
    padding: 0;
    margin-top: rem-calc(10px);
    @include font(brand, 14px, 16px, semi_bold);
  }
  .miss {
    @include font(grey, 13px, 15px, false);
  }
}
.bargain-item {
  padding: rem-calc(10px 0);
  margin-bottom: rem-calc(15px);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
  border: 1px solid lighten(map-get($colors, grey), 35);

  .bargain-product-img {
    .v-image {
      border: 1px solid lighten(map-get($colors, grey), 35);
    }
  }
}
@include media(1639px) {
  .account-wrap {
    width: calc(100% - 275px);
    padding-left: rem-calc(30px);
  }
}

@include media(lg) {
  .account-wrap {
    width: 100%;
    padding-left: 0;
  }
  .filter-bar {
    .input-wrap {
      width: 33.33%;
    }
    .v-btn {
      width: 200px;
    }
  }
}

@include media(md) {
  .filter-bar {
    .input-wrap {
      width: 50%;
    }
  }
}
@include media(sm) {
  .account-panel {
    padding: rem-calc(15px 10px);
  }

  .order-product-wrap {
    .top-section {
      .au-top-first {
        width: 100%;
        padding-bottom: rem-calc(10px);
      }
      .au-top-third,
      .au-top-second {
        width: 50%;
      }
    }
    .btm-section {
      padding-top: rem-calc(10px);
      .au-top-first {
        width: 50%;
      }
      .au-top-second {
        width: 50%;
      }
    }
  }
}

@include media(479px) {
  .filter-bar {
    .input-wrap {
      padding-right: 0;
      width: 100%;
    }
  }
}
