/*


   Magic Zoom Plus v5.3.7 
   Copyright 2021 Magic Toolbox
   Buy a license: https://www.magictoolbox.com/magiczoomplus/
   License agreement: https://www.magictoolbox.com/license/


*/

/**
 * Core styles
 */
.MagicZoom,
.mz-figure,
.mz-lens,
.mz-zoom-window,
.mz-loading,
.mz-hint,
.mz-expand .mz-expand-stage,
.mz-expand .mz-expand-stage .mz-image-stage,
.mz-expand .mz-expand-stage .mz-image-stage .mz-caption,
.mz-thumb img,
.mz-expand-thumbnails,
.mz-expand-controls,
.mz-button {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.MagicZoom,
.mz-figure {
    display: inline-block;
    outline: 0 !important;
    font-size: 0 !important;
    line-height: 100% !important;
    direction: ltr !important;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    vertical-align: middle;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}
.MagicZoom {
    -webkit-transform: translate3d(0,0,0);
    -ms-touch-action: pan-y;
        touch-action: pan-y;
}
figure.mz-figure {
    overflow: hidden;
    margin: 0 !important;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}
.MagicZoom img,
.mz-figure img {
    border: 0 !important;
    margin: 0 !important;
    outline: 0 !important;
    padding: 0 !important;
}
.MagicZoom > img,
.mz-figure > img {
    width: 100%;
    height: auto;
}
.ie8-magic .MagicZoom > img,
.ie8-magic .mz-figure > img {
    width: 100%;
    max-width: none !important;
}
.mz-figure.mz-no-zoom.mz-no-expand,
.mz-expand .mz-figure.mz-no-zoom {
    cursor: default !important;
}
.mz-figure.mz-active,
.mz-expand {
    -ms-touch-action: none;
        touch-action: none;
}

.mz-lens,
.mz-zoom-window {
    position: absolute !important;
    overflow: hidden !important;
    pointer-events: none !important;
}
.mz-lens img,
.mz-zoom-window img {
    position: absolute !important;
    max-width: none !important;
    max-height: none !important;
}
.mz-lens img {
    -webkit-transition: none !important;
            transition: none !important;
}

.mz-zoom-window {
    z-index: 2000000000;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
}
.mz-zoom-window.mz-magnifier {
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
    -webkit-mask-clip: content;
    cursor: none;
}
.mz-figure.mz-active .mz-zoom-window.mz-magnifier {
    pointer-events: auto !important;
    cursor: inherit !important;
}
.mz-zoom-window.mz-magnifier.mz-expanded {
    z-index: 2147483647;
}
.mz-zoom-window img  {
    width: auto;
    height: auto;
    left: -1000%;
    right: -1000%;
    top: -1000%;
    bottom: -1000%;
    margin: auto !important;
    -webkit-transition: none !important;
            transition: none !important;
    -webkit-transform-origin: 50% 50% !important;
        -ms-transform-origin: 50% 50% !important;
            transform-origin: 50% 50% !important;
}
.mz-zoom-window.mz-preview img {
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    height: auto !important;
    -webkit-transform: none !important;
            transform: none !important;
}
.lt-ie9-magic .mz-zoom-window img  {
    margin: 0 !important;
}

.mz-expand-controls {
    z-index: 2147483647;
}

.mz-fade {
    -webkit-transition: opacity .25s;
            transition: opacity .25s;
}
.mz-hidden {
    opacity: 0;
}
.mz-visible {
    opacity: 1;
}

.mobile-magic .mz-expand .mz-expand-bg {
    -webkit-transform-style: flat !important;
            transform-style: flat !important;
}

.mobile-magic .mz-expand-controls.mz-fade {
    transition: none !important;
}

.mobile-magic .mz-expand > .mz-zoom-window {
    top: 0 !important;
    left: 0 !important;
}


@media not screen and (max-device-width: 767px) {
    .mz-expanded-view-open,
    .mz-expanded-view-open body {
        overflow: hidden !important;
    }
    .mz-expanded-view-open body { height: auto !important; }
}

@media screen and (max-device-width: 767px) {
    :root.mz-expanded-view-open:not(.ios-magic):not([data-magic-ua=safari]),
    :root.mz-expanded-view-open:not(.ios-magic):not([data-magic-ua=safari]) body {
        overflow: hidden !important;
    }
    :root.mz-expanded-view-open:not(.ios-magic):not([data-magic-ua=safari]) body { height: auto !important; }
}


/**
 * Main figure
 */
.mz-figure.mz-active {
    background: #fff;
}
.mz-figure.mz-no-zoom,
.mz-figure.mz-click-zoom,
.mz-figure.mz-active {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}
.mz-figure.mz-active.mz-no-expand {
    cursor: crosshair;
}
.mz-figure.mz-active.mz-click-zoom.mz-no-expand {
    cursor: crosshair;
    cursor: -webkit-zoom-out;
    cursor: -moz-zoom-out;
    cursor: zoom-out;
}
.mz-figure.mz-active.mz-magnifier-zoom.mz-hover-zoom.mz-no-expand {
    cursor: none;
}
.mz-figure.mz-magnifier-zoom.mz-active {
    overflow: visible !important;
}

.mz-figure.mz-active > img {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'saturate\' values=\'0\'/><feComponentTransfer><feFuncA type=\'linear\' slope=\'0.6\'/></feComponentTransfer></filter></svg>#grayscale");
    -webkit-filter: grayscale(80%) opacity(60%);
    filter: grayscale(80%) opacity(60%);
}
.no-cssfilters-magic .mz-figure.mz-active > img {
    opacity: 0.6;
}
.lt-ie10-magic .mz-figure.mz-active > img {
    opacity: 1;
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1,opacity=0.6);
}
.mz-expand .mz-figure.mz-active.mz-inner-zoom,
:not(.mz-expand) .mz-figure.mz-active.mz-inner-zoom > img {
    opacity: 0;
}

.lt-ie10-magic .mz-expand .mz-figure.mz-active.mz-inner-zoom,
.lt-ie10-magic .mz-figure.mz-active.mz-inner-zoom > img {
    filter: alpha(opacity=0);
}
.ie9-magic .mz-expand .mz-figure.mz-active.mz-inner-zoom {
    background: transparent;
}

/**
 * Zoom lens
 */
.mz-lens {
    border: 1px solid #aaa;
    border-color: rgba(170,170,170,0.7);
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    cursor: none;
    z-index: 10;
    opacity: 0;
}
.mz-figure.mz-active .mz-lens {
    opacity: 1;
    -webkit-transition: opacity .3s cubic-bezier(0.25, 0.5, 0.5, 0.9) .1s;
    transition: opacity .3s cubic-bezier(0.25, 0.5, 0.5, 0.9) .1s;
}
.mz-figure.mz-active.mz-magnifier-zoom .mz-lens {
    border-radius: 50%;
    opacity: 0;
    cursor: none;
}

/**
 * Zoom window
 */
.mz-zoom-window {
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, .2);
}
.mz-zoom-window.mz-magnifier {
    border-radius: 100%;
    border: 0;
    background: rgba(255,255,255,.3);
}
.mz-zoom-window.mz-magnifier:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;bottom: 0;
    left: 0; right: 0;
    border-radius: 100%;
    border: 1px solid rgba(170,170,170,0.7);
    box-shadow: inset 0 0 20px 1px rgba(0,0,0,.3);
    background: transparent;
    z-index: 1;
}
.mz-zoom-window.mz-magnifier.mz-square,
.mz-zoom-window.mz-magnifier.mz-square:before {
    border-radius: 0 !important;
}
.lt-ie9-magic .mz-zoom-window {
    border: 1px solid #e5e5e5;
}
.mz-zoom-window.mz-inner {
    border: none;
    box-shadow: none;
}
.mz-zoom-window.mz-inner:not(.mz-expanded) {
    z-index: 80;
}

/* Caption in zoom window */
.mz-zoom-window .mz-caption {
    background: #777;
    color: #fff;
    font-size: 10pt;
    opacity: 0.8;
    position: absolute;
    top: 0;
    z-index: 150;
    padding: 3px;
    width: 100%;
    line-height: normal !important;
    text-align: center !important;
}
.lt-ie9-magic .mz-zoom-window .mz-caption {
    filter: alpha(opacity=80);
}
.mz-zoom-window.caption-bottom .mz-caption {
    top: auto;
    bottom: 0;
}
.mz-zoom-window.mz-expanded > .mz-caption {
    display: none;
}

/* Zoom window animations */
.mz-zoom-window.mz-deactivating,
.mz-zoom-window.mz-activating {
    -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9), -webkit-transform 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
            transition: opacity 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9), transform 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}
.mz-zoom-window.mz-deactivating {
    -webkit-transition-duration: .25s, .25s, .25s;
            transition-duration: .25s, .25s, .25s;
}
.mz-zoom-window.mz-p-right,
.mz-zoom-window.mz-p-left,
.mz-zoom-window.mz-p-top,
.mz-zoom-window.mz-p-bottom {
    opacity: 0;
    z-index: -100;
}
.mz-zoom-window.mz-p-right {
    -webkit-transform: translate3d(-20%, 0, 0);
            transform: translate3d(-20%, 0, 0);
}
.mz-zoom-window.mz-p-left {
    -webkit-transform: translate3d(20%, 0, 0);
            transform: translate3d(20%, 0, 0);
}
.mz-zoom-window.mz-p-top {
    -webkit-transform: translate3d(0, 20%, 0);
            transform: translate3d(0, 20%, 0);
}
.mz-zoom-window.mz-p-bottom {
    -webkit-transform: translate3d(0, -20%, 0);
            transform: translate3d(0, -20%, 0);
}
/* Inner mode animation */
.mz-zoom-window > img {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}
.mz-zoom-window.mz-p-inner.mz-deactivating > img,
.mz-zoom-window.mz-p-inner.mz-activating > img {
    -webkit-transition: -webkit-transform .22s cubic-bezier(0.25, 0.5, 0.5, 0.9) !important;
            transition: transform .22s cubic-bezier(0.25, 0.5, 0.5, 0.9) !important;
}
/* Magnifier mode animation */
.mz-zoom-window.mz-p-magnifier {
    -webkit-transform: scale(.1);
            transform: scale(.1);
}
/* Preview mode animation */
.mz-zoom-window.mz-preview.mz-deactivating,
.mz-zoom-window.mz-preview.mz-activating,
.mz-zoom-window.mz-custom.mz-deactivating,
.mz-zoom-window.mz-custom.mz-activating {
    -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
            transition: opacity 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}
.mz-zoom-window.mz-preview.mz-deactivating,
.mz-zoom-window.mz-custom.mz-deactivating {
    -webkit-transition-duration: .2s;
            transition-duration: .2s;
}
.mz-zoom-window.mz-p-preview,
.mz-zoom-window.mz-p-custom {
    opacity: 0;
}


/**
 * Hint & Loading
 */
.mz-hint,
.mz-loading {
    color: #eee;
    background: rgba(49,51,61,0.7);
    font: normal 12px/1.2em 'Lucida Grande', 'Lucida Sans Unicode', Verdana, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    text-decoration: none;
    text-align: center;
    direction: ltr;
    display: inline-block;
    margin: 0;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: opacity 0.25s ease;
            transition: opacity 0.25s ease;
}

.mz-hint {
    bottom: 8px;
    left: 0;
    right: 0;
    padding: 0 10%;
    background-color: transparent;
    -webkit-transition: opacity 0.4s ease, z-index 0.4s ease;
            transition: opacity 0.4s ease, z-index 0.4s ease;
}
.mz-hint-message {
    display: inline-block;
    background: rgba(49,51,61,0.7);
    border-radius: 2em;
    padding: .7em 1.1em;
}
.mz-hint-message:before {
    content: '\02295';
    font-family: serif;
    font-size: 1.5em;
    speak: none;
    text-align: center;
    vertical-align: -2px;
    margin-right: 6px;
}
.mobile-magic .mz-hint-message:before {
    display: none;
}

.mz-hint-hidden {
    opacity: 0;
    z-index: -1;
    -webkit-transition-delay: .6s;
            transition-delay: .6s;
}
/* Hint in the expanded view on mobile */
.mz-expand .mz-hint {
    font-size: 18px;
    line-height: 1.1em;
    top: 50%;
    bottom: auto;
    margin: 0;
    -webkit-transform: translate(0,-50%);
        -ms-transform: translate(0,-50%);
            transform: translate(0,-50%);

}
.mz-expand .mz-hint-message {
    padding: 1.1em;
}
.mz-expand .mz-hint-message:before {
    display: none;
}
.mz-expand .mz-hint-hidden {
    opacity: 0;
    -webkit-transition-delay: 1.5s;
            transition-delay: 1.5s;
}

/* Loading indicator */
.mz-loading {
    font-size: 0;
    border-radius: 100%;
    opacity: 0;
    padding: 0;
    width: 36px;
    height: 36px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.mz-loading:after {
    content: '';
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    width: 24px;
    height: 24px;
    margin: auto;
    text-indent: -9999em;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 1);
    border-top-color: transparent;
    box-sizing: border-box;
}
.mz-loading.shown {
    opacity: 1;
    z-index: 1;
    -webkit-transition: opacity 0s;
    transition: opacity 0s;
}
.mz-loading.shown:after {
    -webkit-animation: spin-loading .9s infinite linear;
            animation: spin-loading .9s infinite linear;
}

@-webkit-keyframes spin-loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes spin-loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.lt-ie10-magic .mz-loading {
    border-radius: 2em;
    font-size: 12px;
    padding: .7em 1.1em;
    width: auto;
    height: auto;
}
.lt-ie10-magic .mz-loading:after {
    content: 'Loading...';
    text-indent: 0;
    border: none;
    position: relative;
}

.lt-ie9-magic .mz-loading {
    filter: alpha(opacity=0);
    right: 0;
    left:  0;
    width: 126px;
    margin: auto;
}
.lt-ie9-magic .mz-hint-message,
.lt-ie9-magic .mz-loading.shown {
    background: transparent !important;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#7c31333D', EndColorStr='#7c31333D');
}
.lt-ie9-magic .mz-hint-hidden {
    filter: alpha(opacity=0);
}



/**
 * Expanded view
 */
.mz-expand,
.mz-expand .mz-expand-bg,
.mz-expand .mz-expand-bg > img,
.mz-expand .mz-expand-bg > svg,
.mz-expand .mz-expand-stage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.mz-expand .mz-expand-bg,
.mz-expand .mz-expand-bg > img,
.mz-expand .mz-expand-bg > svg {
    width: auto !important;
    height: auto !important;

}
.mz-expand .mz-expand-bg,
.mz-expand .mz-expand-bg > svg  {
    min-width: 100% !important;
    min-height: 100% !important;
}

.mz-expand {
    background-color: rgba(0, 0, 0, 1);
    text-align: center;
    vertical-align: middle;
    display: block;
    overflow: hidden;
    z-index: 2100000000;
    position: fixed;
    width: auto;
    height: auto;
    -webkit-perspective: 600px;
            perspective: 600px;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -ms-overflow-style: none;
}

/* Expanded view background */
.mz-expand .mz-expand-bg {
    display: inline-block;
    max-width: none !important;
    max-height: none !important;
    margin: auto;
    vertical-align: middle;
    z-index: -100;
    background-repeat: no-repeat;
    background-size: cover;

    -webkit-perspective: 600px;
            perspective: 600px;

    -webkit-transform: translate3d(0, 0, 0) scale(7) rotate(0.01deg);
            transform: translate3d(0, 0 ,0) scale(7) rotate(0.01deg);
}

.mz-expand .mz-expand-bg > img  {
    margin: auto;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.mz-expand .mz-expand-bg > img  {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'blur\'><feGaussianBlur stdDeviation=\'80\' in=\'SourceGraphic\'></feGaussianBlur></filter></svg>#blur");
    -webkit-filter: blur(20px) brightness(60%);
            filter: blur(20px) brightness(60%);
}

.mz-expand .mz-expand-bg > svg  {
    margin: auto;
    opacity: .6;
}

[data-magic-ua=edge] .mz-expand .mz-expand-bg,
.lt-ie10-magic .mz-expand .mz-expand-bg {
    display: none !important;
}
.lt-ie9-magic .mz-expand {
    background: #1f1f1f;
}
[data-magic-ua=edge] .mz-expand {
    background-color: rgba(31, 31, 31, .96);
}

/** Plain backgrounds: dark-bg, white-bg **/
.mz-expand.plain-bg .mz-expand-bg,
.mz-expand.dark-bg .mz-expand-bg,
.mz-expand.white-bg .mz-expand-bg {
    display: none !important;
}
.mz-expand.dark-bg {
    background-color: #1f1f1f;
    background-color: rgba(31, 31, 31, .96);
}
.mz-expand.white-bg {
    background-color: #fff;
}

/* Expanded view stage */
.mz-expand .mz-expand-stage {
    display: flex;
    flex-direction: column;
    padding: 0;
    z-index: 2100000000;
}

.mz-expand .mz-expand-stage .mz-image-stage {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    position: relative;
    margin: 0;
    padding: 20px 0;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.mz-expand .mz-expand-stage.mz-zoom-in .mz-image-stage {
    padding: 0 !important;
    left: 0;
    right: 0;
}


.mz-expand .mz-image-stage > figure {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    font-size: 0;
    overflow: hidden;
}

.mz-expand .mz-figure {
    max-width: 100% !important;
    overflow: visible;
}
.mz-expand .mz-figure > img {
    max-width: 100%;
    width: auto;
    height: auto;
}
.mz-expand .mz-zoom-in .mz-image-stage > figure,
.mz-expand .mz-zoom-in .mz-image-stage > figure .mz-figure.mz-activating,
.mz-expand .mz-zoom-in .mz-image-stage > figure .mz-figure.mz-active {
    flex: 1 0;
    width: 100%;
    height: 100%;
}
.mz-expand .mz-zoom-in .mz-image-stage > figure .mz-figure.mz-active > img {
    pointer-events: none;
}
.mz-expand .mz-figure {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}
.mz-expand .mz-figure.mz-active {
    cursor: crosshair;
    cursor: -webkit-zoom-out;
    cursor: -moz-zoom-out;
    cursor: zoom-out;
}
/* When inner zoom is always shown */
.mz-expand .mz-expand-stage.mz-zoom-in.mz-always-zoom .mz-image-stage > figure > figure > img {
    z-index: 1;
    position: absolute !important;
    top: -5000px !important;
    bottom: -5000px !important;
    left: -5000px !important;
    right: -5000px !important;
    margin: auto !important;
}

.lt-ie10-magic .mz-zoom-window.mz-expanded img  { filter: alpha(opacity=100); }
.lt-ie10-magic .mz-expand .mz-figure.mz-magnifier-zoom {
    overflow: hidden;
    filter: alpha(opacity=100);
}

/* Caption in expanded view */
.mz-expand .mz-caption {
    flex: 0 0;
    color: #fff;
    text-shadow: 0px 0px 46px #000;
    padding: 10px 30px;
    font: normal 10pt/1.25 'Lucida Grande', 'Lucida Sans Unicode', Verdana, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    text-align: center;
    width: 100%;
    opacity: 0;
}

.mz-expand .mz-caption.mz-show {
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
    opacity: 1;
}

.mz-expand .mz-zoom-in .mz-image-stage .mz-caption {
    display: none;
}

.mz-expand .mz-caption a {
    color: inherit;
    cursor: pointer;
}

.mz-expand.white-bg .mz-caption {
    color: #555;
    text-shadow: none;
}

.lt-ie9-magic .mz-expand .mz-caption {
    top: 100%;
}

.mz-expand .mz-zoom-window {
    box-shadow: none;
    background: transparent;
}

.lt-ie9-magic .mz-expand .mz-zoom-window {
    border: 0;
}

/* Expanded view on mobile */
@media screen and (max-device-width: 767px) {
    .mobile-magic .mz-expand .mz-image-stage .mz-caption {
        display: none !important;
    }
}

/**
 * Buttons & Arrows
 */
.mz-nav-controls,
.mz-expand-controls,
button.mz-button {
    margin: 0 !important;
    padding: 0 !important;
    outline: 0 !important;
}

.mz-nav-controls {
    z-index: 2100000000;
}

button.mz-button {
    color: rgba(180, 180, 180, .6) !important;
    font-size: 0;
    cursor: pointer;
    z-index: 2100000000 !important;
    background-color: transparent !important;
    border: 0 solid transparent !important;
    border-radius: 100% !important;
    box-shadow: none !important;
    position: absolute !important;
    text-transform: none !important;
    text-align: center;
    speak: none;
    width: 60px !important;
    height: 60px !important;
    min-width: 0 !important;
    min-height: 0 !important;
    max-width: none !important;
    max-height: none !important;
    -webkit-transition: color .25s, background-color .25s !important;
            transition: color .25s, background-color .25s !important;
}

button.mz-button:before {
    display: block;
    position: static;
    height: 100%;
    transform: scale(.9);
    -webkit-transition: -webkit-transform .25s !important;
            transition: transform .25s !important;
}

:root:not(.mobile-magic) button.mz-button:hover,
button.mz-button:active {
    color: rgba(180, 180, 180, 1) !important;
}

button.mz-button:active:before {
    -webkit-transform: scale(.7) !important;
            transform: scale(.7) !important;
}

.mobile-magic .mz-expand button.mz-button:active {
    background-color: rgba(49, 51, 61, .7) !important;
}


button.mz-button.mz-button-prev,
button.mz-button.mz-button-next {
    margin: auto 10px !important;
    top: 0 !important;
    bottom: 0 !important;
    overflow: hidden !important;
}

button.mz-button.mz-button-prev {
    left: 0 !important;
    transform: rotateZ(180deg) !important;
}

button.mz-button.mz-button-next {
    right: 0 !important;
}

button.mz-button.mz-button-close {
    margin: 10px !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 95 !important;
}

button.mz-button.mz-button-close:before {
    content: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDggNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yNS4zODQxIDI0LjcyNDFDMjQuOTkzNyAyNC4zMzM0IDI0Ljk5MzkgMjMuNzAwMiAyNS4zODQ3IDIzLjMwOThMMzcgMTEuNzA1MUwzNi4yOTQzIDExTDI0LjY3OTMgMjIuNjA0NEMyNC4yODg2IDIyLjk5NDcgMjMuNjU1NiAyMi45OTQ0IDIzLjI2NTIgMjIuNjAzOEwxMS43MDUyIDExLjAzNjZMMTEgMTEuNzQyMkwyMi41NTkzIDIzLjMwODdDMjIuOTQ5NyAyMy42OTk0IDIyLjk0OTUgMjQuMzMyNiAyMi41NTg4IDI0LjcyM0wxMS4wMzcgMzYuMjM0MkwxMS43NDI3IDM2LjkzOTNMMjMuMjY0MSAyNS40Mjg0QzIzLjY1NDggMjUuMDM4MSAyNC4yODc5IDI1LjAzODMgMjQuNjc4MiAyNS40Mjg5TDM2LjI0MjEgMzdMMzYuOTQ3MiAzNi4yOTQ0TDI1LjM4NDEgMjQuNzI0MVoiIGZpbGw9IiM5OTk5OTkiLz4KPHBhdGggZD0iTTI1LjM4NDEgMjQuNzI0MUMyNC45OTM3IDI0LjMzMzQgMjQuOTkzOSAyMy43MDAyIDI1LjM4NDcgMjMuMzA5OEwzNyAxMS43MDUxTDM2LjI5NDMgMTFMMjQuNjc5MyAyMi42MDQ0QzI0LjI4ODYgMjIuOTk0NyAyMy42NTU2IDIyLjk5NDQgMjMuMjY1MiAyMi42MDM4TDExLjcwNTIgMTEuMDM2NkwxMSAxMS43NDIyTDIyLjU1OTMgMjMuMzA4N0MyMi45NDk3IDIzLjY5OTQgMjIuOTQ5NSAyNC4zMzI2IDIyLjU1ODggMjQuNzIzTDExLjAzNyAzNi4yMzQyTDExLjc0MjcgMzYuOTM5M0wyMy4yNjQxIDI1LjQyODRDMjMuNjU0OCAyNS4wMzgxIDI0LjI4NzkgMjUuMDM4MyAyNC42NzgyIDI1LjQyODlMMzYuMjQyMSAzN0wzNi45NDcyIDM2LjI5NDRMMjUuMzg0MSAyNC43MjQxWiIgc3Ryb2tlPSIjOTk5OTk5Ii8+Cjwvc3ZnPgo=");
}

button.mz-button.mz-button-prev:before,
button.mz-button.mz-button-next:before {
    content: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDggNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xOC43NTkzIDkuMzQ5MjFDMTguMzk5OCA4LjkyOTg4IDE3Ljc2ODUgOC44ODEzMiAxNy4zNDkyIDkuMjQwNzRDMTYuOTI5OSA5LjYwMDE3IDE2Ljg4MTMgMTAuMjMxNSAxNy4yNDA3IDEwLjY1MDhMMTguNzU5MyA5LjM0OTIxWk0zMCAyNEwzMC43NTkzIDI0LjY1MDhDMzEuMDgwMiAyNC4yNzYzIDMxLjA4MDIgMjMuNzIzNyAzMC43NTkzIDIzLjM0OTJMMzAgMjRaTTE3LjI0MDcgMzcuMzQ5MkMxNi44ODEzIDM3Ljc2ODUgMTYuOTI5OSAzOC4zOTk4IDE3LjM0OTIgMzguNzU5M0MxNy43Njg1IDM5LjExODcgMTguMzk5OCAzOS4wNzAxIDE4Ljc1OTMgMzguNjUwOEwxNy4yNDA3IDM3LjM0OTJaTTE3LjI0MDcgMTAuNjUwOEwyOS4yNDA3IDI0LjY1MDhMMzAuNzU5MyAyMy4zNDkyTDE4Ljc1OTMgOS4zNDkyMUwxNy4yNDA3IDEwLjY1MDhaTTI5LjI0MDcgMjMuMzQ5MkwxNy4yNDA3IDM3LjM0OTJMMTguNzU5MyAzOC42NTA4TDMwLjc1OTMgMjQuNjUwOEwyOS4yNDA3IDIzLjM0OTJaIiBmaWxsPSIjOTk5OTk5Ii8+Cjwvc3ZnPgo=");
}

@supports(
        ((-webkit-mask-image:url()) or (mask-image:url())) and
        ((-webkit-mask-repeat:no-repeat) or (mask-repeat:no-repeat)) and
        ((-webkit-mask-position:center) or (mask-position:center)) and
        ((-webkit-mask-size:cover) or (mask-size:cover))
) {
    button.mz-button:before {
        content: "" !important;
        background-color: currentColor;
        background-image: none;
        -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
        -webkit-mask-position: center;
                mask-position: center;
        -webkit-mask-size: cover;
                mask-size: cover;
    }

    button.mz-button.mz-button-close:before {
        -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDggNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yNS4zODQxIDI0LjcyNDFDMjQuOTkzNyAyNC4zMzM0IDI0Ljk5MzkgMjMuNzAwMiAyNS4zODQ3IDIzLjMwOThMMzcgMTEuNzA1MUwzNi4yOTQzIDExTDI0LjY3OTMgMjIuNjA0NEMyNC4yODg2IDIyLjk5NDcgMjMuNjU1NiAyMi45OTQ0IDIzLjI2NTIgMjIuNjAzOEwxMS43MDUyIDExLjAzNjZMMTEgMTEuNzQyMkwyMi41NTkzIDIzLjMwODdDMjIuOTQ5NyAyMy42OTk0IDIyLjk0OTUgMjQuMzMyNiAyMi41NTg4IDI0LjcyM0wxMS4wMzcgMzYuMjM0MkwxMS43NDI3IDM2LjkzOTNMMjMuMjY0MSAyNS40Mjg0QzIzLjY1NDggMjUuMDM4MSAyNC4yODc5IDI1LjAzODMgMjQuNjc4MiAyNS40Mjg5TDM2LjI0MjEgMzdMMzYuOTQ3MiAzNi4yOTQ0TDI1LjM4NDEgMjQuNzI0MVoiIGZpbGw9IiM5OTk5OTkiLz4KPHBhdGggZD0iTTI1LjM4NDEgMjQuNzI0MUMyNC45OTM3IDI0LjMzMzQgMjQuOTkzOSAyMy43MDAyIDI1LjM4NDcgMjMuMzA5OEwzNyAxMS43MDUxTDM2LjI5NDMgMTFMMjQuNjc5MyAyMi42MDQ0QzI0LjI4ODYgMjIuOTk0NyAyMy42NTU2IDIyLjk5NDQgMjMuMjY1MiAyMi42MDM4TDExLjcwNTIgMTEuMDM2NkwxMSAxMS43NDIyTDIyLjU1OTMgMjMuMzA4N0MyMi45NDk3IDIzLjY5OTQgMjIuOTQ5NSAyNC4zMzI2IDIyLjU1ODggMjQuNzIzTDExLjAzNyAzNi4yMzQyTDExLjc0MjcgMzYuOTM5M0wyMy4yNjQxIDI1LjQyODRDMjMuNjU0OCAyNS4wMzgxIDI0LjI4NzkgMjUuMDM4MyAyNC42NzgyIDI1LjQyODlMMzYuMjQyMSAzN0wzNi45NDcyIDM2LjI5NDRMMjUuMzg0MSAyNC43MjQxWiIgc3Ryb2tlPSIjOTk5OTk5Ii8+Cjwvc3ZnPgo=");
                mask-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDggNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yNS4zODQxIDI0LjcyNDFDMjQuOTkzNyAyNC4zMzM0IDI0Ljk5MzkgMjMuNzAwMiAyNS4zODQ3IDIzLjMwOThMMzcgMTEuNzA1MUwzNi4yOTQzIDExTDI0LjY3OTMgMjIuNjA0NEMyNC4yODg2IDIyLjk5NDcgMjMuNjU1NiAyMi45OTQ0IDIzLjI2NTIgMjIuNjAzOEwxMS43MDUyIDExLjAzNjZMMTEgMTEuNzQyMkwyMi41NTkzIDIzLjMwODdDMjIuOTQ5NyAyMy42OTk0IDIyLjk0OTUgMjQuMzMyNiAyMi41NTg4IDI0LjcyM0wxMS4wMzcgMzYuMjM0MkwxMS43NDI3IDM2LjkzOTNMMjMuMjY0MSAyNS40Mjg0QzIzLjY1NDggMjUuMDM4MSAyNC4yODc5IDI1LjAzODMgMjQuNjc4MiAyNS40Mjg5TDM2LjI0MjEgMzdMMzYuOTQ3MiAzNi4yOTQ0TDI1LjM4NDEgMjQuNzI0MVoiIGZpbGw9IiM5OTk5OTkiLz4KPHBhdGggZD0iTTI1LjM4NDEgMjQuNzI0MUMyNC45OTM3IDI0LjMzMzQgMjQuOTkzOSAyMy43MDAyIDI1LjM4NDcgMjMuMzA5OEwzNyAxMS43MDUxTDM2LjI5NDMgMTFMMjQuNjc5MyAyMi42MDQ0QzI0LjI4ODYgMjIuOTk0NyAyMy42NTU2IDIyLjk5NDQgMjMuMjY1MiAyMi42MDM4TDExLjcwNTIgMTEuMDM2NkwxMSAxMS43NDIyTDIyLjU1OTMgMjMuMzA4N0MyMi45NDk3IDIzLjY5OTQgMjIuOTQ5NSAyNC4zMzI2IDIyLjU1ODggMjQuNzIzTDExLjAzNyAzNi4yMzQyTDExLjc0MjcgMzYuOTM5M0wyMy4yNjQxIDI1LjQyODRDMjMuNjU0OCAyNS4wMzgxIDI0LjI4NzkgMjUuMDM4MyAyNC42NzgyIDI1LjQyODlMMzYuMjQyMSAzN0wzNi45NDcyIDM2LjI5NDRMMjUuMzg0MSAyNC43MjQxWiIgc3Ryb2tlPSIjOTk5OTk5Ii8+Cjwvc3ZnPgo=");
    }

    button.mz-button.mz-button-prev:before,
    button.mz-button.mz-button-next:before {
        -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDggNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xOC43NTkzIDkuMzQ5MjFDMTguMzk5OCA4LjkyOTg4IDE3Ljc2ODUgOC44ODEzMiAxNy4zNDkyIDkuMjQwNzRDMTYuOTI5OSA5LjYwMDE3IDE2Ljg4MTMgMTAuMjMxNSAxNy4yNDA3IDEwLjY1MDhMMTguNzU5MyA5LjM0OTIxWk0zMCAyNEwzMC43NTkzIDI0LjY1MDhDMzEuMDgwMiAyNC4yNzYzIDMxLjA4MDIgMjMuNzIzNyAzMC43NTkzIDIzLjM0OTJMMzAgMjRaTTE3LjI0MDcgMzcuMzQ5MkMxNi44ODEzIDM3Ljc2ODUgMTYuOTI5OSAzOC4zOTk4IDE3LjM0OTIgMzguNzU5M0MxNy43Njg1IDM5LjExODcgMTguMzk5OCAzOS4wNzAxIDE4Ljc1OTMgMzguNjUwOEwxNy4yNDA3IDM3LjM0OTJaTTE3LjI0MDcgMTAuNjUwOEwyOS4yNDA3IDI0LjY1MDhMMzAuNzU5MyAyMy4zNDkyTDE4Ljc1OTMgOS4zNDkyMUwxNy4yNDA3IDEwLjY1MDhaTTI5LjI0MDcgMjMuMzQ5MkwxNy4yNDA3IDM3LjM0OTJMMTguNzU5MyAzOC42NTA4TDMwLjc1OTMgMjQuNjUwOEwyOS4yNDA3IDIzLjM0OTJaIiBmaWxsPSIjOTk5OTk5Ii8+Cjwvc3ZnPgo=");
                mask-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDggNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xOC43NTkzIDkuMzQ5MjFDMTguMzk5OCA4LjkyOTg4IDE3Ljc2ODUgOC44ODEzMiAxNy4zNDkyIDkuMjQwNzRDMTYuOTI5OSA5LjYwMDE3IDE2Ljg4MTMgMTAuMjMxNSAxNy4yNDA3IDEwLjY1MDhMMTguNzU5MyA5LjM0OTIxWk0zMCAyNEwzMC43NTkzIDI0LjY1MDhDMzEuMDgwMiAyNC4yNzYzIDMxLjA4MDIgMjMuNzIzNyAzMC43NTkzIDIzLjM0OTJMMzAgMjRaTTE3LjI0MDcgMzcuMzQ5MkMxNi44ODEzIDM3Ljc2ODUgMTYuOTI5OSAzOC4zOTk4IDE3LjM0OTIgMzguNzU5M0MxNy43Njg1IDM5LjExODcgMTguMzk5OCAzOS4wNzAxIDE4Ljc1OTMgMzguNjUwOEwxNy4yNDA3IDM3LjM0OTJaTTE3LjI0MDcgMTAuNjUwOEwyOS4yNDA3IDI0LjY1MDhMMzAuNzU5MyAyMy4zNDkyTDE4Ljc1OTMgOS4zNDkyMUwxNy4yNDA3IDEwLjY1MDhaTTI5LjI0MDcgMjMuMzQ5MkwxNy4yNDA3IDM3LjM0OTJMMTguNzU5MyAzOC42NTA4TDMwLjc1OTMgMjQuNjUwOEwyOS4yNDA3IDIzLjM0OTJaIiBmaWxsPSIjOTk5OTk5Ii8+Cjwvc3ZnPgo=");

    }
}

@media screen and (max-device-width: 767px) {
    button.mz-button-prev,
    button.mz-button-next {
        display: none !important;
    }

    .mobile-magic .mz-expand button.mz-button.mz-button-close {
        width: 50px !important;
        height: 50px !important;
        color: rgb(180, 180, 180) !important;
    }

    .mobile-magic .mz-expand button.mz-button:before {
        transform: scale(.8);
    }
}

.lt-ie9-magic button.mz-button {
    border: 0;
    filter: alpha(opacity=75);
}

.lt-ie9-magic button.mz-button:hover {
    filter: alpha(opacity=100);
}

/* Arrows on the main image on the page */
.mz-figure .mz-nav-controls button.mz-button {
    display: none;
    width: 32px !important;
    height: 56px !important;
    margin: auto 0 !important;
    padding: 0 !important;
    border: 0 !important;
}
.mz-figure.mz-show-arrows .mz-nav-controls button.mz-button {
    display: block;
}



/**
 * Thumbnails
 */

/** Thumbnails on the page **/
a[data-zoom-id],
.mz-thumb, .mz-thumb:focus {
    display: inline-block;
    line-height: 0;
    outline: none;
}

a[data-zoom-id] img,
.mz-thumb img {
    padding-bottom: 4px;
    border: 0;
    border-bottom: 2px solid transparent;
    box-sizing: border-box;
}

.mz-thumb:not(.mz-thumb-selected):hover img {
    border-bottom-color: rgba(180, 180, 180, .3);
}

.mz-thumb.mz-thumb-selected img {
    border-bottom-color: rgb(180, 180, 180);
}

/** Thumbnails in expanded view **/
.mz-expand-thumbnails {
    flex: 0 0 80px;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 10px 0;
    z-index: 50;
    overflow: hidden;
}

.ios-magic[data-magic-engine=webkit][data-magic-engine-ver="603.1"] .mz-expand-thumbnails,
:root[data-magic-ua=firefox] .mz-expand-thumbnails {
    height: 80px;
}

.mz-expand-thumbnails .magic-thumb > img {
    cursor: pointer;
    padding-bottom: 4px;
    border: 0;
    border-bottom: 2px solid transparent;
}

.mz-expand-thumbnails .magic-thumb:not(.magic-thumb-selected):hover img {
    border-bottom-color: rgba(180, 180, 180, .3);
}

.mz-expand-thumbnails .magic-thumb.magic-thumb-selected img {
    border-bottom-color: rgb(180, 180, 180);
}

.mz-expand-thumbnails .magic-thumbs .magic-thumbs-button {
    box-shadow: none;
}

/** Thumbnails on the left in expanded view with .expand-thumbnails-left class **/
@media not screen and (max-device-width: 767px) and (orientation: portrait) {
    .mz-expand.expand-thumbnails-left .mz-expand-stage {
        flex-direction: row-reverse;
    }

    .mz-expand.expand-thumbnails-left .mz-expand-thumbnails {
        padding: 0 10px;
        height: 100%;
        overflow: hidden;
    }

    .ios-magic[data-magic-engine=webkit][data-magic-engine-ver="603.1"] .mz-expand.expand-thumbnails-left .mz-expand-thumbnails,
    :root[data-magic-ua=firefox] .mz-expand.expand-thumbnails-left .mz-expand-thumbnails {
        width: 80px;
        height: 100%;
    }

    @media not screen and (max-device-width: 767px) {
        .mz-expand-thumbnails .magic-thumbs.magic-thumbs-vertical {
            height: calc(100% - 40px);
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    @media screen and (max-device-width: 767px) {
        .mz-expand-thumbnails .magic-thumbs.magic-thumbs-vertical.no-buttons {
            height: calc(100% - 40px);
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

/** Hide thumbnails in expanded view with .no-expand-thumbnails class **/
.mz-expand.no-expand-thumbnails .mz-expand-thumbnails {
    display: none;
}


/** Thumbnails shaded style (improved previous one)  **/
.thumbnails-style-shaded.mz-thumb img {
    border: 0 !important;
    padding-bottom: 0;
    box-shadow: 0 0 1px 0px rgba(0, 0, 0, .3);
}

.thumbnails-style-shaded.mz-thumb:hover:not(.mz-thumb-selected) img {
    -webkit-filter: brightness(.7);
            filter: brightness(.7);

}
.thumbnails-style-shaded.mz-thumb-selected img {
    -webkit-filter: brightness(.7) opacity(.7);
            filter: brightness(.7) opacity(.7);
}

.thumbnails-style-shaded .mz-expand-thumbnails .magic-thumb > img {
    border: 0 ;
    padding-bottom: 0;
    box-shadow:0 0 5px 1px rgba(0, 0, 0, .3);
}

.thumbnails-style-shaded .mz-expand-thumbnails .magic-thumb:not(.magic-thumb-selected) > img:hover {
    -webkit-filter: brightness(.75);
            filter: brightness(.75);
}

.thumbnails-style-shaded .mz-expand-thumbnails .magic-thumb-selected img {
    -webkit-filter: brightness(.7) opacity(.7);
            filter: brightness(.7) opacity(.7);
}

.no-cssfilters-magic .thumbnails-style-shaded.mz-thumb:hover,
.no-cssfilters-magic .thumbnails-style-shaded.mz-thumb.mz-thumb-selected,
.no-cssfilters-magic .thumbnails-style-shaded .magic-thumb:hover,
.no-cssfilters-magic .thumbnails-style-shaded .magic-thumb.magic-thumb-selected {
    background: #000;
}

.no-cssfilters-magic .thumbnails-style-shaded.mz-thumb:hover:not(.mz-thumb-selected) img {
    opacity: .75;
    filter: alpha(opacity=75);
}

.no-cssfilters-magic .thumbnails-style-shaded.mz-thumb-selected img {
    opacity: .7;
    filter: alpha(opacity=70);
}

.no-cssfilters-magic .thumbnails-style-shaded .mz-expand-thumbnails .magic-thumb {
    position: relative;
}

.no-cssfilters-magic .thumbnails-style-shaded .mz-expand-thumbnails .magic-thumb:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    z-index: 100;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0);
}

.no-cssfilters-magic .thumbnails-style-shaded .mz-expand-thumbnails .magic-thumb:hover:not(.magic-thumb-selected):after {
    background-color: rgba(0, 0, 0, 0.2);
}

.no-cssfilters-magic .thumbnails-style-shaded .mz-expand-thumbnails .magic-thumb.magic-thumb-selected:after {
    background-color: rgba(0, 0, 0, 0.3);
}

.no-cssfilters-magic .thumbnails-style-shaded .mz-expand-thumbnails .magic-thumb img {
    position: relative;
    z-index: 1;
}

.ie9-magic .thumbnails-style-shaded.mz-thumb img {
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, .3);
}

.magic-thumbs,
.magic-thumbs .magic-thumbs-wrapper,
.magic-thumbs ul,
.magic-thumbs li,
.magic-thumbs li:before,
.magic-thumbs li:after,
.magic-thumbs li img,
.magic-thumbs .magic-thumbs-button {
    margin: 0;
    padding: 0;
    outline: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.magic-thumbs {
    direction: ltr;
    overflow: visible;
    z-index: 100;
    position: relative;
    left: 0;
    top: 0;
    padding: 0 40px;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -ms-touch-action: none;
        touch-action: none;
}

.magic-thumbs {
    width: 100%;
    height: 100%;
    white-space: nowrap;
}

.magic-thumbs.no-buttons {
    padding: 0 !important;
}

.magic-thumbs-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
}

.magic-thumbs ul {
    list-style: none;
    font-size: 0;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    position: relative;
    white-space: nowrap;
}
.magic-thumbs.no-buttons ul {
    left: 0 !important;
    padding: 0 !important;
    -webkit-transition: none !important;
            transition: none !important;
    -webkit-transform: none !important;
        -ms-transform: none !important;
            transform: none !important;
}


.magic-thumbs ul li {
    margin: 0 4px;
    height: 100%;
    width: auto;
    vertical-align: top;
    line-height: 0;
    display: inline-block;
}
.magic-thumbs ul li:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
}

.magic-thumbs ul li > img {
    width: auto;
    height: auto;
    max-width: none !important;
    max-height: 100% !important;
    vertical-align: middle;
}
.ie8-magic .magic-thumbs ul li > img {
    max-width: none !important;
    max-height: none !important;
}

/* vertical */
.magic-thumbs.magic-thumbs-vertical {
    height: 100%;
}
.magic-thumbs-vertical {
    padding: 40px 0;
}
.magic-thumbs-vertical ul {
    height: auto;
    width: 100%;
    white-space: normal;
}
.magic-thumbs-vertical ul li {
    width: 100%;
    height: auto;
}
.magic-thumbs-vertical ul li {
    margin: 4px 0;
}

.magic-thumbs-vertical ul li > img {
    max-width: 100% !important;
    max-height: none !important;
}
.ie8-magic .magic-thumbs-vertical ul li > img {
    width: 100%;
}

/**
 * Buttons
 */
.magic-thumbs button.magic-thumbs-button {
    color: rgba(180, 180, 180, .6) !important;
    font-size: 0;
    cursor: pointer;
    z-index: 90 !important;
    background-repeat: no-repeat;
    background-color: transparent !important;
    background-image: none;
    border: 0;
    border-radius: 100%;
    box-shadow: none !important;
    overflow: hidden;
    position: absolute;
    text-transform: none !important;
    speak: none;
    text-align: center;
    width: 33px;
    height: 33px;
    top: 0;
    bottom: 0;
    margin: auto 3px;
    min-width: 0 !important;
    min-height: 0 !important;
    max-width: none !important;
    max-height: none !important;
    -webkit-transition: color .25s, background-color .25s !important;
            transition: color .25s, background-color .25s !important;
}

.magic-thumbs button.magic-thumbs-button:before {
    display: block;
    position: static;
    height: 100%;
    transform: scale(1);
    -webkit-transition: -webkit-transform .25s !important;
            transition: transform .25s !important;
}

.mobile-magic .magic-thumbs button.magic-thumbs-button:not(.magic-thumbs-button-disabled),
:root:not(.mobile-magic) .magic-thumbs button.magic-thumbs-button:not(.magic-thumbs-button-disabled):hover,
.magic-thumbs button.magic-thumbs-button:not(.magic-thumbs-button-disabled):active {
    color: rgba(180, 180, 180, 1) !important;
}

.magic-thumbs button.magic-thumbs-button:not(.magic-thumbs-button-disabled):active:before {
    -webkit-transform: scale(.7) !important;
            transform: scale(.7) !important;
}

.mobile-magic .mz-expand .magic-thumbs button.magic-thumbs-button:not(.magic-thumbs-button-disabled):active {
    background-color: rgba(49, 51, 61, .7) !important;
}

.magic-thumbs .magic-thumbs-button-prev {
    left: 0;
    transform: rotateZ(180deg) !important;
}

.magic-thumbs .magic-thumbs-button-next {
    right: 0;
}

.magic-thumbs-vertical button.magic-thumbs-button {
    top: auto;
    right: 0;
    bottom: auto;
    left: 0;
    margin: 3px auto;
}

.magic-thumbs-vertical button.magic-thumbs-button-prev {
    top: 0;
    transform: rotateZ(-90deg) !important;
}

.magic-thumbs-vertical button.magic-thumbs-button-next {
    bottom: 0;
    transform: rotateZ(90deg) !important;
}

.magic-thumbs .magic-thumbs-button-prev:before,
.magic-thumbs .magic-thumbs-button-next:before {
    content: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDggNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xOC43NTkzIDkuMzQ5MjFDMTguMzk5OCA4LjkyOTg4IDE3Ljc2ODUgOC44ODEzMiAxNy4zNDkyIDkuMjQwNzRDMTYuOTI5OSA5LjYwMDE3IDE2Ljg4MTMgMTAuMjMxNSAxNy4yNDA3IDEwLjY1MDhMMTguNzU5MyA5LjM0OTIxWk0zMCAyNEwzMC43NTkzIDI0LjY1MDhDMzEuMDgwMiAyNC4yNzYzIDMxLjA4MDIgMjMuNzIzNyAzMC43NTkzIDIzLjM0OTJMMzAgMjRaTTE3LjI0MDcgMzcuMzQ5MkMxNi44ODEzIDM3Ljc2ODUgMTYuOTI5OSAzOC4zOTk4IDE3LjM0OTIgMzguNzU5M0MxNy43Njg1IDM5LjExODcgMTguMzk5OCAzOS4wNzAxIDE4Ljc1OTMgMzguNjUwOEwxNy4yNDA3IDM3LjM0OTJaTTE3LjI0MDcgMTAuNjUwOEwyOS4yNDA3IDI0LjY1MDhMMzAuNzU5MyAyMy4zNDkyTDE4Ljc1OTMgOS4zNDkyMUwxNy4yNDA3IDEwLjY1MDhaTTI5LjI0MDcgMjMuMzQ5MkwxNy4yNDA3IDM3LjM0OTJMMTguNzU5MyAzOC42NTA4TDMwLjc1OTMgMjQuNjUwOEwyOS4yNDA3IDIzLjM0OTJaIiBmaWxsPSIjOTk5OTk5Ii8+Cjwvc3ZnPgo=");
}

@supports(
        ((-webkit-mask-image:url()) or (mask-image:url())) and
        ((-webkit-mask-repeat:no-repeat) or (mask-repeat:no-repeat)) and
        ((-webkit-mask-position:center) or (mask-position:center)) and
        ((-webkit-mask-size:cover) or (mask-size:cover))
) {
    .magic-thumbs button.magic-thumbs-button:before {
        content: "" !important;
        background-color: currentColor;
        background-image: none;
        -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
        -webkit-mask-position: center;
                mask-position: center;
        -webkit-mask-size: cover;
                mask-size: cover;
    }

    .magic-thumbs .magic-thumbs-button-prev:before,
    .magic-thumbs .magic-thumbs-button-next:before {
        -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDggNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xOC43NTkzIDkuMzQ5MjFDMTguMzk5OCA4LjkyOTg4IDE3Ljc2ODUgOC44ODEzMiAxNy4zNDkyIDkuMjQwNzRDMTYuOTI5OSA5LjYwMDE3IDE2Ljg4MTMgMTAuMjMxNSAxNy4yNDA3IDEwLjY1MDhMMTguNzU5MyA5LjM0OTIxWk0zMCAyNEwzMC43NTkzIDI0LjY1MDhDMzEuMDgwMiAyNC4yNzYzIDMxLjA4MDIgMjMuNzIzNyAzMC43NTkzIDIzLjM0OTJMMzAgMjRaTTE3LjI0MDcgMzcuMzQ5MkMxNi44ODEzIDM3Ljc2ODUgMTYuOTI5OSAzOC4zOTk4IDE3LjM0OTIgMzguNzU5M0MxNy43Njg1IDM5LjExODcgMTguMzk5OCAzOS4wNzAxIDE4Ljc1OTMgMzguNjUwOEwxNy4yNDA3IDM3LjM0OTJaTTE3LjI0MDcgMTAuNjUwOEwyOS4yNDA3IDI0LjY1MDhMMzAuNzU5MyAyMy4zNDkyTDE4Ljc1OTMgOS4zNDkyMUwxNy4yNDA3IDEwLjY1MDhaTTI5LjI0MDcgMjMuMzQ5MkwxNy4yNDA3IDM3LjM0OTJMMTguNzU5MyAzOC42NTA4TDMwLjc1OTMgMjQuNjUwOEwyOS4yNDA3IDIzLjM0OTJaIiBmaWxsPSIjOTk5OTk5Ii8+Cjwvc3ZnPgo=");
                mask-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDggNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xOC43NTkzIDkuMzQ5MjFDMTguMzk5OCA4LjkyOTg4IDE3Ljc2ODUgOC44ODEzMiAxNy4zNDkyIDkuMjQwNzRDMTYuOTI5OSA5LjYwMDE3IDE2Ljg4MTMgMTAuMjMxNSAxNy4yNDA3IDEwLjY1MDhMMTguNzU5MyA5LjM0OTIxWk0zMCAyNEwzMC43NTkzIDI0LjY1MDhDMzEuMDgwMiAyNC4yNzYzIDMxLjA4MDIgMjMuNzIzNyAzMC43NTkzIDIzLjM0OTJMMzAgMjRaTTE3LjI0MDcgMzcuMzQ5MkMxNi44ODEzIDM3Ljc2ODUgMTYuOTI5OSAzOC4zOTk4IDE3LjM0OTIgMzguNzU5M0MxNy43Njg1IDM5LjExODcgMTguMzk5OCAzOS4wNzAxIDE4Ljc1OTMgMzguNjUwOEwxNy4yNDA3IDM3LjM0OTJaTTE3LjI0MDcgMTAuNjUwOEwyOS4yNDA3IDI0LjY1MDhMMzAuNzU5MyAyMy4zNDkyTDE4Ljc1OTMgOS4zNDkyMUwxNy4yNDA3IDEwLjY1MDhaTTI5LjI0MDcgMjMuMzQ5MkwxNy4yNDA3IDM3LjM0OTJMMTguNzU5MyAzOC42NTA4TDMwLjc1OTMgMjQuNjUwOEwyOS4yNDA3IDIzLjM0OTJaIiBmaWxsPSIjOTk5OTk5Ii8+Cjwvc3ZnPgo=");

    }
}

.magic-thumbs.no-buttons .magic-thumbs-button {
    display: none !important;
}
.magic-thumbs-button-disabled {
    opacity: 0.2;
    filter: alpha(opacity = 20);
}
