@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.quantity-holder {
  width: 115px;
  text-align: center;
  border: 1px solid #dbdbdb;
  .v-icon {
    padding-right: 3px;
    padding-left: 3px;

    &:focus::after {
      display: none;
    }
  }

  .mdi-minus {
    border-right: 1px solid #dbdbdb;
  }
  .mdi-plus {
    border-left: 1px solid #dbdbdb;
  }
  input {
    text-align: center;
  }
}
