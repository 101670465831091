@import "app/index.scss";
.modal-wrap {
  background-color: #f1f3f6;
}
.modal-bg {
  padding: 30px;
  margin-top: -170px;
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  @include border-radius(10px);
}
.customer-img {
  padding-bottom: 35px;
}
.modal-head {
  padding: 0 0 42px;
  .org-info{
    background-color: lighten(map-get($colors, grey ), 44);
    @include border-radius(10px);
  }
  h2 {
    padding-bottom: 5px;
    color: #1f2227;
    @include font(false, 24px, 40px, medium);
  }
  h3 {
    padding-bottom: 5px;
    color: #1f2227;
    @include font(false, 15px, 20px, regular);
  }
  span {
    @include font(brand, 15px, 20px, regular);
  }
}
.customers {
  &::v-deep .v-input--radio-group__input {
    justify-content: space-between;

    .v-input--selection-controls__input {
      display: none;
    }
  }
}
.customer-type {
  width: 32%;
  padding: 30px;
  margin: 0 !important;
  transition: all 500ms;
  border: 1px solid #dedede;
  @include border-radius(10px);

  &::v-deep .v-label {
    height: 100%;
    display: block;
  }
  .customer-btn {
    padding: 10px 0 8px;
    text-align: center;
    display: block;
    transition: all 400ms;
    border: 1px solid map-get($colors, silver_tree);
    @include border-radius(5px);
    @include font(primary, 17px, 34px, bold);
  }

  &.v-item--active {
    border-color: map-get($colors, silver_tree);
    box-shadow: 0 0 50px rgba(50, 50, 50, 0.22);
    .customer-btn {
      color: #fff;
      background-color: map-get($colors, silver_tree);
    }
  }
}
.customer-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .subtitle {
    padding-bottom: 7px;
    color: #6b6b6b;
    @include font(false, 16px, 20px, false);
  }

  h3 {
    padding-bottom: 15px;
    @include font(primary, 21px, 30px, false);
  }
  .top-list {
    li {
      padding: 5px 0 5px 30px;
      position: relative;
      @include font(primary, 15px, 22px, medium);
      .v-icon {
        font-size: 22px;
        color: map-get($colors, brand);
        @include absolute(false, false, left, 0, top, 4px);
      }
    }
  }
  .btm-list {
    padding: 25px 0 30px;
    margin: 0;
    li {
      padding: 5px 0 5px 30px;
      position: relative;
      @include font(primary, 15px, 22px, red);
      span {
        width: 20px;
        height: 20px;
        border: 1px solid #b5b5b5;
        border-radius: 50%;
        text-align: center;
        @include font(false, 12px, 20px, medium);
        @include absolute(false, false, left, 0, top, 4px);
      }
    }
  }
}
.bottom-section {
  padding-top: 60px;
  .applyinfo {
    width: 70%;

    ul {
      li {
        padding-right: 20px;
      }
    }
  }
}

@include media(1350px) {
  .modal-bg {
    margin-top: -70px;
  }
}
@include media(lg) {
  .modal-bg {
    margin-top: -50px;
  }
  .customer-type {
    width: 48.5%;
  }
  .customer-type {
    &:last-child {
      margin-top: 30px !important;
      // width: 100%;
    }
  }
  .bottom-section {
    padding-top: 25px;
    .applyinfo,
    .applyinfo-right {
      width: 100%;
    }
    .applyinfo {
      padding-top: 40px;
    }
  }
}
@include media(md) {
  .customer-type {
    padding: 15px;
  }
  .modal-bg {
    padding: 15px;
    margin-top: 0;
  }
}

@include media(767px) {
}

@include media(sm) {
  .customer-type {
    width: 100%;
    margin: 0 0 15px !important;
    &:last-child {
      margin-top: auto !important;
      // width: 100%;
    }
  }

  .modal-head {
    h2 {
      @include font(false, 20px, 30px, bold);
    }
    h3 {
      @include font(false, 16px, 26px, regular);
    }
    span {
      @include font(brand, 16px, 26px, regular);
    }
  }
}
