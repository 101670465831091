@import "app/index.scss";

.watch-list-wrap {
    padding: rem-calc(30px);
    margin: rem-calc(0 0 40px);

    h1 {
        padding: rem-calc(0 0 12px);
        @include font(primary, 35px, 40px, medium);
    }
    .watch-list-count {
        display: block;
        @include font(primary, 16px, 22px, false);
    }
}

.watch-list-related-product {
    padding: rem-calc(0 30px);
}
.watch-list-table {
    padding: rem-calc(44px 0 0);

    .cart-product-wrap {
        padding: rem-calc(10px);
        margin-bottom: rem-calc(15px);
        border: 1px solid lighten(map-get($colors, grey), 35);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
        @include border-radius(5px);

        .product-img {
            width: 100px;

            img {
                width: 100%;
                border: 1px solid lighten(map-get($colors, grey), 35);
            }
        }
        .product-info {
            width: calc(100% - 100px);

            .unit-price {
                @include font(brand, 15px, 20px, semi_bold);
            }
            .stock-status {
                @include font(silver_tree, 14px, 20px, false);
            }
            .not-available {
                @include font(grey, 13px, 16px, light);
            }
            .cart-btn {
                height: 29px;
            }
            .auction-label-text {
                @include font(grey, 14px, 16px, false);
            }
            .auction-label {
                @extend .auction-label-text;
            }
            .v-btn--icon {
                width: auto;
            }
        }

        h4 {
            padding-bottom: rem-calc(5px);
            @include font(false, 15px, 20px, regular);
        }
    }

    table {
        width: 100%;
        padding: 0;
        margin-bottom: rem-calc(13px);
        border: 1px solid #ccc;
        border-collapse: collapse;

        th {
            height: 50px;
            padding: rem-calc(0 20px);
            text-align: left;
            @include font(primary, 16px, 22px, semi_bold);
            background-color: lighten(map-get($colors, grey), 45);
            border-bottom: 1px solid lighten(map-get($colors, grey), 35);

            .wishlist-products {
                width: 520px;
            }
        }

        td {
            padding: rem-calc(20px);
            text-align: left;
            @include font(false, 13px, 20px, false);
            border-bottom: 1px solid lighten(map-get($colors, grey), 30);

            .wishlist-product-title {
                max-width: 300px;
                padding-left: rem-calc(15px);
                h4 {
                    padding-bottom: rem-calc(15px);
                    @include font(primary, 15px, 22px, regular);
                }
            }
            .unit-price {
                @include font(brand, 17px, 22px, medium);
            }
            .auction-label {
                width: 90px;
                height: 30px;
                display: inline-block;
                text-align: center;
                @include font(false, 16px, 30px, light);
            }
            .stock-status,
            .auction-label-text {
                @include font(primary, 16px, 22px, medium);
            }
            .bidding-status,
            .not-available {
                display: block;
                @include font(grey, 13px, 16px, light);
            }
        }
    }
    .more-product {
        width: 250px;
        height: 50px !important;
        @include font(false, 18px, 22px, semi_bold);
    }
}
@include media(1600px) {
    .watch-list-table {
        table {
            th,
            td {
                padding: rem-calc(10px);
            }
        }
    }
}

@include media(lg) {
    .watch-list-wrap {
        padding: rem-calc(15px);
    }
    .watch-list-table {
        // table {
        //     border: none;
        //     thead {
        //         display: none;
        //     }
        //     tbody,
        //     tr,
        //     td {
        //         width: 100%;
        //         display: block;
        //     }
        //     tbody {
        //         tr {
        //             margin-bottom: 15px;

        //             td {
        //                 height: auto;
        //                 text-align: center;
        //                 padding-top: 8px;
        //                 padding-bottom: 8px;
        //                 padding-left: 50%;
        //                 position: relative;
        //                 border-left: thin solid rgba(0, 0, 0, 0.12);
        //                 border-right: thin solid rgba(0, 0, 0, 0.12);
        //                 &:first-child {
        //                     border-top: thin solid rgba(0, 0, 0, 0.12);
        //                 }

        //                 &::before {
        //                     content: attr(data-label);
        //                     position: absolute;
        //                     left: 15px;
        //                     top: 50%;
        //                     transform: translateY(-50%);
        //                     @include font(primary, 15px, 20px, medium);
        //                 }
        //                 .wishlist-products-item {
        //                     flex-wrap: wrap;
        //                     .v-image {
        //                         margin: 0 auto;
        //                     }
        //                     .wishlist-product-title {
        //                         width: 100%;
        //                         padding: rem-calc(10px 0 0);
        //                     }
        //                 }
        //             }
        //             &:nth-child(even) {
        //                 background-color: #f5f5f5;
        //             }
        //             &:last-child {
        //                 td {
        //                     border-bottom: thin solid rgba(0, 0, 0, 0.12);
        //                 }
        //             }
        //         }
        //     }
        // }

        .more-product {
            margin-top: rem-calc(20px);
        }
    }
}

@include media(sm) {
    .watch-list-table {
        .cart-product-wrap {
            .product-info {
                .base-price {
                    width: 100%;
                }
            }
        }
    }
}

@include media(479px) {
    .watch-list-wrap {
        h1 {
            @include font(false, 24px, 18px, semi_bold);
        }
    }
    .watch-list-table {
        .cart-product-wrap {
            .product-img {
                width: 80px;
            }
            .product-info {
                width: calc(100% - 80px);
            }
        }
    }
}
