@import "app/index.scss";

.bid-history-wrap {
    padding: rem-calc(25px);
    margin: 0;
    h2 {
        padding: rem-calc(0 0 25px);
        @include font(primary, 28px, 36px, semi_bold);
    }

    &::v-deep .v-data-table__wrapper {
        @include scroll-y(6px);
    }
}

.bid-history-box {
    border: 1px solid lighten(map-get($colors, grey), 40);
    &::v-deep .v-data-table__wrapper {
        max-height: 950px;
    }
}
.bid-history-time {
    padding: rem-calc(13px);
    margin: rem-calc(15px);
    background-color: map-get($colors, site_bg);

    .time-holder {
        @include font(primary, 15px, 15px, false);

        .time-left {
            width: 75px;
            flex-shrink: 0;
            
        }
        .time-holder-box {
            span {
                padding-right: 10px;
                display: inline-block;
            }
            &::v-deep b {
                display: inline-block;
                @include font(false, 25px, 25px, semi_bold);
            }
        }
    }
}
.bidding-info {
    span {
        @include font(primary, 18px, 30px, false);
        &:not(:last-child) {
            padding-right: rem-calc(13px);
            margin-right: rem-calc(13px);
            border-right: 2px solid lighten(map-get($colors, grey), 20);
        }
        strong {
            @include font(primary, 25px, 30px, semi_bold);
        }
    }
}
.table-top {
    a {
        padding: rem-calc(0 15px 38px);
        @include font(primary, 15px, 22px, false);
        text-decoration: underline;
    }
}

.bid-table {
    table {
        thead {
            tr {
                th {
                    color: map-get($colors, primary) !important;
                    @include font(false, 15px, 22px, semi_bold);
                    border-top: 1px solid rgba(0, 0, 0, 0.1);
                }
            }
        }

        tbody {
            tr {
                td {
                    height: 40px !important;
                    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
                    .bidder-name {
                        @include font(false, 13px, 22px, false);
                    }
                    .bid-time {
                        @include font(false, 13px, 22px, false);
                    }
                    &.bidder-dtls {
                        .bid-time {
                            display: none;
                        }
                    }
                    .bid-amount {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include font(primary, 16px, 22px, semi_bold);
                    }
                    .v-avatar {
                        border: 1px solid #90caf9 !important;
                    }
                    &.own_bid {
                        .bidder-name {
                            color: map-get($colors, brand);
                            &::v-deep b {
                                @include font(grey, 11px, 12px, regular);
                            }
                        }
                    }
                }

                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        .bid-amount {
                            font-size: (26px - $i);
                            background-color: lighten(
                                map-get($colors, silver_tree),
                                $i * 5%
                            );
                        }
                    }
                }
            }
        }
    }
}

.table-bottom-text {
    padding: rem-calc(36px 20px 15px);
    @include font(false, 14px, 22px, false);
}

.item-info-wrap {
    border: 1px solid lighten(map-get($colors, grey), 40);
    .place-bid-wrap {
        padding: rem-calc(15px 30px);
        background-color: map-get($colors, site_bg);
        border: 1px solid lighten(map-get($colors, grey), 38);

        .bid-label {
            padding-bottom: rem-calc(5px);
            @include font(false, 15px, 22px, false);
        }
        .automatic-bid {
            padding-top: rem-calc(6px);
            @include font(primary, 15px, 22px, false);
            a {
                color: map-get($colors, grey);
            }
        }
    }
}

.item-image {
    max-width: 160px;
}
.item-title {
    padding: rem-calc(0 20px);

    h3 {
        @include font(primary, 17px, 26px, medium);
        @include truncate-clamp(2);
    }
    .item-id {
        @include font(primary, 14px, 25px, relative);
        b {
            font-weight: map-get($font-weight, semi_bold);
        }
    }
    a {
        @include font(brand, 14px, 22px, false);
    }
}
.item-info {
    &:not(:first-child) {
        padding: rem-calc(15px 20px);
        border-top: 1px solid lighten(map-get($colors, grey), 40);
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            margin: 0;
            display: flex;
            justify-content: space-between;
            @include font(primary, 14px, 20px, medium);

            .info-text {
                width: calc(100% - 120px);
                display: block;
                @include font(false, 14px, 20px, regular);
                strong {
                    display: block;
                    a {
                        margin-left: rem-calc(15px);
                        text-decoration: underline;
                    }
                }
            }
            &:not(:last-child) {
                padding: rem-calc(0 0 10px);
            }
        }
    }
}

.please-contact {
    @include font(false, 16px, 22px, false);
    a {
        color: map-get($colors, brand);
        font-weight: map-get($font-weight, bold);

        &:hover {
            text-decoration: underline;
        }
    }
}
.autobid-popup {
    border-radius: 0;
    .autobid-wrap {
        padding: rem-calc(40px);

        h3 {
            padding: rem-calc(0 0 16px);
            @include font(primary, 30px, 30px, medium);
        }
        .autobid-text {
            padding: rem-calc(0 0 22px);

            p {
                @include font(primary, 15px, 24px, false);
            }
        }
        .input-wrap {
            padding-bottom: rem-calc(20px);
        }

        .v-btn {
            height: rem-calc(40px);
            width: rem-calc(100px);
            @include font(false, 15px, 30px, semi_bold);
        }
    }
}
@include media(1639px) {
    .item-title {
        padding: rem-calc(0 10px);
        h3 {
            @include font(primary, 15px, 20px, medium);
        }
    }
    .item-info {
        &:not(:first-child) {
            padding: rem-calc(20px 15px);
            border-top: 1px solid lighten(map-get($colors, grey), 40);
        }
    }
}

@include media(lg) {
    .item-image {
        max-width: 80px;
    }
    .item-title {
        h3 {
            @include font(primary, 14px, 18px, medium);
        }
    }
    .bid-history-wrap {
        padding: rem-calc(15px);
        h2 {
            padding: rem-calc(0 0 10px);
        }
    }
}

@include media(md) {
    .bid-history-time {
        .time-holder {
            width: 100%;
            padding-top: rem-calc(5px);
            margin-top: rem-calc(5px);
            border-top: 1px solid lighten(map-get($colors, grey), 30);
        }
    }
    .item-image {
        max-width: 150px;
    }
    .item-info {
        &:last-child {
            display: none;
        }
    }

    .item-info-wrap {
        margin-bottom: rem-calc(30px);

        .place-bid-wrap {
            width: 100%;
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: 3;
            border-top: 1px solid lighten(map-get($colors, grey), 35);
            background-color: lighten(map-get($colors, grey), 40);
            box-shadow: 0 -2px 6px rgba(53, 58, 68, 0.25);
        }
    }
}

@include media(1023px) {
    .bid-table {
        table {
            thead {
                tr {
                    th {
                        padding: 0 8px;
                        &:last-child {
                            display: none;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding: 0 8px;
                        .bidder-name {
                            padding-top: rem-calc(5px);
                            @include font(false, 15px, 16px, semi_bold);
                        }

                        &:last-child {
                            display: none;
                        }
                        &.bidder-dtls {
                            .bid-time {
                                display: block;
                                padding-bottom: rem-calc(4px);
                            }
                        }
                    }
                }
            }
        }

        // table {
        //     thead {
        //         display: none;
        //     }
        //     tbody,
        //     tr,
        //     td {
        //         width: 100%;
        //         display: block;
        //     }
        //     tbody {
        //         tr {
        //             margin-bottom: 15px;

        //             td {
        //                 height: auto;
        //                 text-align: right;
        //                 padding-top: 8px;
        //                 padding-bottom: 8px;
        //                 padding-left: 50%;
        //                 position: relative;
        //                 border-left: thin solid rgba(0, 0, 0, 0.12);
        //                 border-right: thin solid rgba(0, 0, 0, 0.12);
        //                 .bid-amount {
        //                     font-size: 16px !important;
        //                     justify-content: flex-end !important;
        //                     background-color: transparent !important;
        //                 }
        //                 &:first-child {
        //                     border-top: thin solid rgba(0, 0, 0, 0.12);
        //                 }

        //                 &::before {
        //                     content: attr(data-label);
        //                     position: absolute;
        //                     left: 15px;
        //                     top: 50%;
        //                     transform: translateY(-50%);
        //                     @include font(primary, 15px, 20px, medium);
        //                 }
        //             }
        //             &:nth-child(even) {
        //                 background-color: #f5f5f5;
        //             }
        //             &:last-child {
        //                 td {
        //                     border-bottom: thin solid rgba(0, 0, 0, 0.12);
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}

@include media(700px) {
    .bid-history-time {
        margin: rem-calc(5px);
        .time-holder {
            .time-left {
                .time-left-in {
                    padding-top: 2px;
                }
            }

            .time-holder-box {
                span {
                    @include font(false, 13px, 20px, false);
                }
                &::v-deep b {
                    @include font(false, 17px, 20px, false);
                }
            }
        }
    }

    .item-info-wrap {
        .place-bid-wrap {
            padding: rem-calc(10px 12px);
        }
    }
}
@include media(479px) {
    .item-image {
        max-width: 120px;
    }
    .table-top {
        flex-wrap: wrap;
        padding-bottom: rem-calc(10px);
        a {
            padding: rem-calc(0 10px);
            display: block;
        }
    }
    .bid-table {
        &::v-deep .v-data-table__wrapper {
            overflow-x: hidden;
        }
        table {
            thead {
                tr {
                    th {
                        padding: 0 5px;
                        @include font(false, 13px, 13px, false);
                        &:nth-child(2) {
                            text-align: right !important;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding: 0 5px;
                        .v-avatar {
                            display: none;
                        }
                        .bidder-name {
                            width: 150px;
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            @include font(false, 12px, 12px, semi_bold);
                        }

                        .bid-time {
                            font-size: 12px;
                        }

                        .bid-amount {
                            justify-content: flex-end;
                            font-size: 12px !important;
                            background-color: transparent !important;
                        }
                    }
                }
            }
        }
    }
    .bid-history-time {
        .time-holder-box {
            flex-wrap: wrap;
            .time-left {
                width: 100%;
                @include font(primary, 14px, 22px, false);
            }
        }
    }

    .bidding-info {
        span {
            @include font(primary, 14px, 18px, false);
            strong {
                @include font(primary, 18px, 20px, semi_bold);
            }
        }
    }
}
@include media(360px) {
    .item-info {
        flex-wrap: wrap;
    }
    .item-info {
        &:not(:first-child) {
            padding: rem-calc(15px 10px);
        }
    }
    .item-image {
        width: 100%;
        max-width: 100%;
    }
    .item-title {
        width: 100%;
        padding: rem-calc(10px);
    }
    .time-holder {
        strong {
            border-right: none !important;
        }
    }
}
