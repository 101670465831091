@import "app/index.scss";

.cart-summary {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: map-get($colors, light);
    h2 {
        height: 45px;
        padding: rem-calc(0 20px);
        @include font(primary, 16px, 22px, medium);
        background-color: lighten(map-get($colors, grey), 40);
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
    .ship-info {
        padding: rem-calc(20px);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        .address {
            address {
                font-style: normal;
                span {
                    padding: rem-calc(0 0 8px);
                    display: block;
                    @include font(primary, 15px, 20px, regular);
                }
                strong {
                    font-weight: map-get($font-weight, medium);
                }
            }
        }
    }
    .cart-summary-box {
        padding: rem-calc(0 20px 20px);
    }
    .cart-info {
        padding: rem-calc(10px 0);
        align-items: center;
        display: flex;
        &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        strong {
            width: 50%;
            @include font(primary, 15px, 22px, regular);
        }
        span {
            width: 50%;
            @include font(primary, 15px, 22px, medium);

            &.grand-total {
                @include font(false, 18px, 22px, semi_bold);
            }
        }
        .by-cash {
            @include font(primary, 13px, 20px, semi_bold);
        }
    }
}
.v-list-item__title a {
    color: map-get($colors, primary);
}
@include media(md) {
    .cart-summary {
        .ship-info {
            .address {
                width: 70%;
            }
        }
    }
}
@include media(479px) {
    .btn-holder {
        flex-wrap: wrap;
    }

    .cart-summary {
        .ship-info {
            .address {
                width: 100%;
                order: 2;
            }
        }
        .cart-summary-box {
            padding: rem-calc(10px);
        }
    }
    
}
.validation-observer{
    width: 100%;
}


