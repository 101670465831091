@import "app/index.scss";

.filter-btn {
  margin-left: rem-calc(20px);
  display: none;
}
.portlet {
  width: calc(100% - 288px);
  padding: rem-calc(20px 30px);
  position: relative;
}
.shipping-option {
  .v-input {
    padding-right: rem-calc(30px);
  }
}
.product-top-filter {
  margin: 0;

  .short-by {
    order: 2;

    .filter-by-btn {
      display: none;
    }
    .short-by-in {
      display: flex;
    }
  }

  .showing-summery {
    order: 1;
    padding-right: rem-calc(15px);
  }

  .filter-info {
    display: block;
    @include font(grey, 15px, 25px, medium);

    b {
      color: map-get($colors, primary);
    }
    span {
      color: darken(map-get($colors, grey), 25);
    }
  }

  .view-indicator {
    padding-left: rem-calc(46px);
    display: none;

    .active {
      .v-icon {
        color: map-get($colors, brand);
      }
    }
    .v-icon {
      margin-left: rem-calc(7px);
    }
  }
}

.please-contact {
  @include font(false, 16px, 22px, false);
  a {
    color: map-get($colors, brand);
    font-weight: map-get($font-weight, bold);

    &:hover {
      text-decoration: underline;
    }
  }
}
.product-pagination {
  padding: rem-calc(40px 0 37px);
}
.drawer-toggle {
  left: 255px;
  top: 24px;
  z-index: 9;
  position: fixed;
  background: #fff;
}

@include media(1639px) {
  .product-short {
    width: 100%;
  }
  .portlet {
    width: calc(100% - 245px);
    padding: rem-calc(15px);
  }
}

@include media(1280px) {
  .products-wraper {
    .product-items {
      max-width: 33.33%;
      width: 33.33%;
      flex: 0 0 33.33%;
    }
  }

  .product-top-filter {
    .short-by {
      width: 100%;
      position: relative;

      .grid-list {
        @include absolute(false, false, right, 0, top, 0);
      }
    }
    .showing-summery {
      width: 100%;
      padding-bottom: 10px;
    }
  }
}
@include media(1023px) {
  .portlet {
    width: 100%;
  }
  .filter-btn {
    display: inline-block;
  }

  .product-top-filter {
    .view-indicator {
      display: block;
    }
    .short-by-in {
      padding: 10px;
      z-index: 1;
      @include absolute(100%, false, left, 0, top, 32px);
      background-color: map-get($colors, light);
      border: 1px solid lighten(map-get($colors, grey), 35);
    }
    .short-by {
      padding-bottom: 10px;
      order: 1;
      border-bottom: 1px solid lighten(map-get($colors, grey), 35);

      .filter-by-btn {
        display: block;
      }
    }
    .showing-summery {
      width: 100%;
      padding: 10px 0 0;
      order: 2;
    }
  }
}

@include media(699px) {
  // .view-indicator {
  //     display: none;  /*do effect in product-search page filter section*/
  // }
}
@include media(sm) {
  .products-wraper {
    .product-items {
      max-width: 50%;
      width: 50%;
      flex: 0 0 50%;
    }
  }
  .product-top-filter {
    .view-indicator {
      padding-left: 0;
    }
  }
  .filter-btn {
    margin-left: rem-calc(5px);
  }
  .filter-by-btn {
    font-size: 13px;
  }
}
@include media(479px) {
  .portlet {
    padding: rem-calc(15px);
  }
}
